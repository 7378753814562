import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Spinner,
  Form,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import BajaAltaMultiple from "../../BajaAltaMultiple";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import { v4 as uuidv4 } from "uuid";
import qrcode from "qrcode-js";
import moment from "moment";

function ListadoEmbarques() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_EMBARQUES = process.env.REACT_APP_URL_EMBARQUES;
  const URL_ARTICULOS_EMBARQUES = process.env.REACT_APP_URL_ARTICULOS_EMBARQUES;
  const URL_CONCEPTOS_GASTOS_EMBARQUES =
    process.env.REACT_APP_URL_CONCEPTOS_GASTOS_EMBARQUES;
  const URL_GASTOS_EMBARQUES = process.env.REACT_APP_URL_GASTOS_EMBARQUES;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_ARTICULOS_OC = process.env.REACT_APP_URL_ARTICULOS_OC;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;
  const URL_PROVEEDORES = process.env.REACT_APP_URL_PROVEEDORES;
  const URL_NOTAS_CXP = process.env.REACT_APP_URL_NOTAS_CXP;
  const URL_BANCOS = process.env.REACT_APP_URL_BANCOS;
  const URL_GASTOS = process.env.REACT_APP_URL_GASTOS;
  const URL_DEPARTAMENTOS = process.env.REACT_APP_URL_DEPARTAMENTOS;

  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  const [embarques, setEmbarques] = useState([]);
  const [numeroEmbarque, setNumeroEmbarque] = useState([]);

  const [selectedFechaInicio, setSelectedFechaInicio] = useState("");
  const [selectedFechaFin, setSelectedFechaFin] = useState("");

  const [fechaEmbarque, setFechaEmbarque] = useState("");
  const [fechaEstimada, setFechaEstimada] = useState("");
  const [observaciones, setObservaciones] = useState("NA");
  const [contenedor, setContenedor] = useState("");
  const [idEdit, setIdEdit] = useState("");

  const [articulosOC, setArticulosOC] = useState([]);
  const [articulos, setArticulos] = useState([]);
  const [proveedores, setProveedores] = useState([]);

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);
  const [validaBoton, setValidaBoton] = useState(true);
  const [totalCaptura, setTotalCaptura] = useState(1000000);

  const [gastosEmbarque, setGastosEmbarque] = useState([]);
  const [modalEditGastos, setModalEditGastos] = useState(false);
  const toggleEditGastos = () => setModalEditGastos(!modalEditGastos);
  
  const [modalAplicarGastos, setModalAplicarGastos] = useState(false);
  const toggleAplicarGastos = () => setModalAplicarGastos(!modalAplicarGastos);


  
  const [total_cantidadGastos, setTotalCantidadGastos] = useState(0);
  const [conceptosGastos, setConceptosGastos] = useState([]);


  const [bancos, setBancos] = useState([]);
  const [fecha, setFecha] = useState(hoy);
  const [subtotal, setSubtotal] = useState(0);
  const [iva, setIva] = useState(0);
  const [selectedIva, setSelectedIva] = useState("");
  const [total, setTotal] = useState(0);
  const [factura, setFactura] = useState("0");
  const [departamentos, setDepartamentos] = useState([]);
  const [selectedDepartamento, setSelectedDepartamento] = useState("");
  const [numeroContenedor, setNumeroContenedor] = useState("");
  const [totalCantidad, setTotalCantidad] = useState(0);
  const [totalGeneral, setTotalGeneral] = useState(0);
  const [selectedBanco, setSelectedBanco] = useState("");
  const [inputFieldsGastos, setInputFieldsGastos] = useState([
    {
      id: uuidv4(),
      concepto: "",
      estimado: 0,
      real: 0,
    },
  ]);

  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      articulosOrden: "",
      articulos: "",
      cantidad: 0,
      piezasPorCaja: 0,
      cajas: 0,
      cantidadOC: 0,
      costo: 0,
      costoTotal: 0,
      proveedorOrden: "",
    },
  ]);

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);

  const [modalEditArticulos, setModalEditArticulos] = useState(false);
  const toggleEditArticulos = () => setModalEditArticulos(!modalEditArticulos);
  const [contenedorEdit, setContenedorEdit] = useState("");
  const [articulosOrden, setArticulosOrden] = useState([]);
  const [totalCantidadEdit, setTotalCantidadEdit] = useState("");
  const [totalGeneralEdit, setTotalGeneralEdit] = useState("");
  const [agrupado, setAgrupado] = useState([]);
  const [idArtEdit, setIdArtEdit] = useState("");

  const [recibido, setRecibido] = useState("");

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useEffect(() => {
    axios
      .get(URL_EMBARQUES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allEmbarques = response.data;
        // Array para pagination
        let arrayTabla = allEmbarques
          .sort((a, b) => (a.idEmbarques > b.idEmbarques ? 1 : -1))
          .map((a) => {
            return {
              recibido: a.recibido,
              _id: a._id,
              numero: a.idEmbarques,
              fechaEmbarque: a.fechaEmbarque,
              fechaEstimada: a.fechaEstimada,
              observaciones: a.observaciones,
              contenedor: a.contenedor,
              totalCantidad: a.total_cantidad.toFixed(2),
              recibido: a.recibido
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);
        setEmbarques(allEmbarques);
        setComments(dataFinal);
        //
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_ARTICULOS_OC, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allArticulosOC = res.data;
        setArticulosOC(allArticulosOC);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_ARTICULOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allArticulos = res.data;
        setArticulos(allArticulos);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${URL_PROVEEDORES}Tipo/China`, { 
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allProveedores = res.data;
        setProveedores(allProveedores);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_CONCEPTOS_GASTOS_EMBARQUES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allConceptosGastos = res.data;
        setConceptosGastos(allConceptosGastos);
      })
      .catch((err) => {
        console.log(err);
      });
      axios
      .get(URL_BANCOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allBancos = res.data;
        setBancos(allBancos);
      })
      .catch((err) => {
        console.log(err);
      });
      axios
      .get(URL_DEPARTAMENTOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allDepartamentos = res.data;
        setDepartamentos(allDepartamentos);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const ListaEmbarques = comments.sort((a, b) =>
    a.idEmbarques > b.idEmbarques ? 1 : -1
  );

  function PDFTabla() {
    const data = ListaEmbarques.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (recibido == 0 || recibido == a.recibido)
      ) {
        return [
          a.recibido,
          a.numero,
          a.fechaEmbarque,
          a.contenedor,
          a.fechaEstimada,
          a.observaciones,
          new Intl.NumberFormat("en-US").format(a.totalCantidad),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape","mm", "a4", true);
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 15, 25, 25);
    doc.text(`Listado de Embarques`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Recibido",
          "Numero",
          "Fecha Embarque",
          "Contenedor",
          "Fecha Estimada",
          "Observaciones",
          "Cantidad",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
    });
    doc.save(`Embarques.pdf`);
  }

  function excel() {
    const dataExcel = ListaEmbarques.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (recibido == 0 || recibido == a.recibido)
      ) {
        return {
          Recibido: a.recibido,
          Numero: a.numero,
          FechaEmbarque: a.fechaEmbarque,
          Contenedor: a.contenedor,
          FechaEstimada: a.fechaEstimada,
          Observaciones: a.observaciones,
          Cantidad: a.totalCantidad,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoEmbarques";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoEmbarques",
        sheetFilter: [
          "Recibido",
          "Numero",
          "FechaEmbarque",
          "Contenedor",
          "fechaEstimada",
          "Observaciones",
          "Cantidad",
        ],
        sheetHeader: [
          "Recibido",
          "Numero",
          "FechaEmbarque",
          "Contenedor",
          "fechaEstimada",
          "Observaciones",
          "Cantidad",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    const data = ListaEmbarques.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (recibido == 0 || recibido == a.recibido)
      ) {
        return [
          a.recibido,
          a.numero,
          a.fechaEmbarque,
          a.contenedor,
          a.fechaEstimada,
          a.observaciones,
          new Intl.NumberFormat("en-US").format(a.totalCantidad),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape","mm", "a4", true);
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 15, 25, 25);
    doc.text(`Listado de Embarques`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Recibido",
          "Numero",
          "Fecha Embarque",
          "Contenedor",
          "Fecha Estimada",
          "Observaciones",
          "Cantidad",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado de Embarques",
          email: mailTo,
          fileName: "ListadoEmbarques.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Embarques.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  async function PDFOC(
    fechaEmbarque,
    contenedor,
    fechaEstimada,
    idPDFOC,
    numero
  ) {
    let totalCajas= 0
    await axios
      .get(`${URL_ARTICULOS_EMBARQUES}/embarque/${idPDFOC}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosOrden = response.data;
        let arrayTabla = allArticulosOrden
          .map((a) => {
            return {
              id: a._id,
              proveedor: a.proveedores[0].nombre_comercial,
              articulo: a.articulos[0].codigo,
              pack: a.pack,
              OC: a.articulosOC[0].ordenesCompra[0].idOrdenCompra,
              pzasXcaja: a.articulos[0].piezasPorCaja,
              cantidad: a.cantidad,
              cajas: parseFloat(a.cantidad) / parseFloat(a.articulos[0].piezasPorCaja),
              FOB: a.costo,
              total_costo: a.total_costo
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);

        let cant = dataFinal.map((c) => parseFloat(c.cantidad));
        let TC = cant.reduce((t, total, index) => t + total, 0);
        setTotalCantidad(TC);
        let importes = dataFinal.map((c) => parseFloat(c.total_costo));
        let TG = importes.reduce((t, total, index) => t + total, 0);
        setTotalGeneral(TG);

        const data = dataFinal
        .sort((a, b) => (a.articulo > b.articulo ? 1 : -1))
        .map((a) => {
          totalCajas = totalCajas + a.cajas
          return [
            a.proveedor,
            a.articulo,
            a.OC,
            new Intl.NumberFormat("en-US").format(a.pzasXcaja),
            new Intl.NumberFormat("en-US").format(a.cantidad),
            new Intl.NumberFormat("en-US").format(a.cajas),
            "$" + new Intl.NumberFormat("en-US").format(a.FOB),
            "$" + new Intl.NumberFormat("en-US").format(a.total_costo),
          ];
        });
        const dataPDF = Object.values(data);
        const dataPDFLimpia = dataPDF.filter(function (el) {
          return el != null;
        });
        const doc = new jsPDF("landscape","mm", "a4", true);
        var img = new Image();
        img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
        doc.addImage(img, "png", 230, 30, 45, 15);
        var img2 = new Image();
        img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
        doc.addImage(img2, "png", 140, 15, 25, 25);
        doc.text(`Contenedor # ${contenedor}`, 20, 20);
        doc.setFontSize(12);
        doc.text(`Fecha Embarque ${fechaEmbarque}`, 20, 25);
        doc.text(`Fecha Estimada ${fechaEstimada}`, 20, 30);
        doc.autoTable({
          head: [["Proveedor", "Articulo", "OC", "Pzs Caja", "Cantidad", "Cajas", "FOB", "Total"]],
          body: dataPDFLimpia,
          startY: 45,
          foot: [
            [
              "",
              "",
              "",
              "Total",
              new Intl.NumberFormat("en-US").format(TC),
              new Intl.NumberFormat("en-US").format(totalCajas),
              "",
              "$" + new Intl.NumberFormat("en-US").format(TG),
            ],
          ],
          showFoot: "lastPage",
        });
        // doc.save(`Embarque-${numero}.pdf`);
        window.open(doc.output("bloburl"), "_blank");
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function jalaInfo(
    fechaEmbarque,
    fechaEstimada,
    observaciones,
    contenedor,
    numero,
    idEdit
  ) {
    setFechaEmbarque(fechaEmbarque);
    setFechaEstimada(fechaEstimada);
    setObservaciones(observaciones);
    setContenedor(contenedor);
    setNumeroEmbarque(numero);
    setIdEdit(idEdit);
    toggleEdit();
  }

  function editEmbarques(event) {
    event.preventDefault();
    const URL_EMBARQUES_EDIT = `${process.env.REACT_APP_URL_EMBARQUES}/${idEdit}`;
    axios
      .all([
        axios.patch(
          URL_EMBARQUES_EDIT,
          {
            fechaEmbarque,
            fechaEstimada,
            observaciones,
            contenedor,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        ),
      ])
      .then(() => {
        axios.post(
          URL_LOGS,
          {
            tipo: "Editar Embarque",
            detalle: `${numeroEmbarque}`,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        );

        toggleEdit();
        Swal.fire("Good job!", "Actualizado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  const headers = [
    { name: "Recibido", field: "recibido", sortable: true },
    { name: "Numero", field: "numero", sortable: true },
    { name: "Fecha Embarque", field: "fechaEmbarque", sortable: false },
    { name: "Contenedor", field: "contenedor", sortable: true },
    { name: "Fecha Estimada", field: "fechaEstimada", sortable: false },
    { name: "Cantidad", field: "totalCantidad", sortable: true },
    { name: "Acciones", field: "acciones", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.recibido.toLowerCase().includes(search.toLowerCase()) ||
          comment.contenedor.toLowerCase().includes(search.toLowerCase()) ||
          comment.totalCantidad.toString().includes(search) ||
          comment.numero.toString().includes(search)
      );
    }
    if (recibido) {
      computedComments = computedComments.filter((e) =>
        e.recibido.includes(recibido)
      );
    }

    if (selectedFechaInicio) {
      computedComments = computedComments.filter(
        (e) => e.fechaEmbarque >= selectedFechaInicio
      );
    }

    if (selectedFechaFin) {
      computedComments = computedComments.filter(
        (e) => e.fechaEmbarque <= selectedFechaFin
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "totalCantidad" &&
      sorting.field &&
      sorting.field != "numero"
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "totalCantidad" ||
        sorting.field ||
        sorting.field == "numero")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "totalCantidad" ||
        sorting.field ||
        sorting.field == "numero")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    recibido,
    selectedFechaInicio,
    selectedFechaFin,
  ]);

  function EditGastos(id) {
    axios
      .get(`${URL_GASTOS_EMBARQUES}Embarque/${id}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allGastosEmbarque = res.data;
        setGastosEmbarque(allGastosEmbarque);
        console.log(allGastosEmbarque);
      })
      .catch((err) => {
        console.log(err);
      });

    setInputFieldsGastos([
      {
        id: uuidv4(),
        concepto: "",
        estimado: 0,
        real: 0,
      },
    ]);
    setIdEdit(id);

    toggleEditGastos();
  }

  const handleRemoveFieldsGastos = (id) => {
    const values = [...inputFieldsGastos];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFieldsGastos(values);
    let cant = values.map((c) => parseFloat(c.cantidad));
    let TC = cant.reduce((t, total, index) => t + total, 0);
    setTotalCantidadGastos(TC);
  };

  const handleAddFieldsGastos = () => {
    setInputFieldsGastos([
      ...inputFieldsGastos,
      {
        id: uuidv4(),
        concepto: "",
        estimado: 0,
        real: 0,
      },
    ]);
  };

  const handleChangeInputGastos = (id, event) => {
    const newInputFieldsGastos = inputFieldsGastos.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setInputFieldsGastos(newInputFieldsGastos);
  };

  const handleChangeInputGastosExistentes = (id, event) => {
    const newGastosEmbarque = gastosEmbarque.map((i) => {
      if (id === i._id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setGastosEmbarque(newGastosEmbarque);
  };

  function BuscaGasto(id, event) {
    if (
      inputFieldsGastos.filter((e) => e.concepto == event.target.value).length >
      0
    ) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "El gasto ya fue capturado!",
        showConfirmButton: false,
      });
      const newInputFieldsGastos = inputFieldsGastos.map((i) => {
        if (id === i.id) {
          i.concepto = "";
        }
        return i;
      });
      setInputFieldsGastos(newInputFieldsGastos);
    } else {
      conceptosGastos.map((a) => {
        if (a._id == event.target.value) {
          let concepto = a._id;
          let estimado = a.estimado;
          handleChangeInputGasto(id, concepto, estimado);
        }
      });
    }
  }
  const handleChangeInputGasto = (id, concepto, estimado) => {
    const newInputFieldsGasto = inputFieldsGastos.map((i) => {
      if (id === i.id) {
        i.concepto = concepto;
        i.estimado = estimado;
        i.real = estimado;
      }
      return i;
    });
    setInputFieldsGastos(newInputFieldsGasto);
  };

  const saveGastosEmbarque = async (event) => {
    event.preventDefault();
    setValidaBoton(false);
    toggleProgreso();
    let totalEmbarques = inputFieldsGastos.length + gastosEmbarque.length;
    try {
      await inputFieldsGastos.map((a) => {
        if (a.concepto != "") {
          axios
            .post(
              URL_GASTOS_EMBARQUES,
              {
                conceptosGastosEmbarques: a.concepto,
                estimado: a.estimado,
                real: a.real,
                embarques: idEdit,
                aplicado:"No"
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              }
            )
            .then(() => {
              totalEmbarques = totalEmbarques - 1;
              if (totalEmbarques == 0) {
                axios.post(
                  URL_LOGS,
                  {
                    tipo: "Editar Gastos Embarque",
                    detalle: `${idEdit}`,
                    user: user.id,
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                );
                Swal.fire("Good job!", "Creado con exito", `success`);
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
              }
            })
            .catch((error) => {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                footer: `${error.response.data}`,
              });
              console.log(error);
            });
        } else {
          totalEmbarques = totalEmbarques - 1;
          if (totalEmbarques == 0) {
            axios.post(
              URL_LOGS,
              {
                tipo: "Editar Gastos",
                detalle: `${idEdit}`,
                user: user.id,
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              }
            );
            Swal.fire("Good job!", "Creado con exito", `success`);
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }
        }
      });

      gastosEmbarque.map((a) => {
        if (a.concepto != "") {
          axios
            .patch(
              `${URL_GASTOS_EMBARQUES}/${a._id}`,
              {
                estimado: a.estimado,
                real: a.real,
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              }
            )
            .then(() => {
              totalEmbarques = totalEmbarques - 1;
              if (totalEmbarques == 0) {
                axios.post(
                  URL_LOGS,
                  {
                    tipo: "Editar Gastos Embarque",
                    detalle: `${idEdit}`,
                    user: user.id,
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                );
                Swal.fire("Good job!", "Creado con exito", `success`);
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
              }
            })
            .catch((error) => {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                footer: `${error.response.data}`,
              });
              console.log(error);
            });
        } else {
          totalEmbarques = totalEmbarques - 1;
          if (totalEmbarques == 0) {
            axios.post(
              URL_LOGS,
              {
                tipo: "Editar Gastos",
                detalle: `${idEdit}`,
                user: user.id,
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              }
            );
            Swal.fire("Good job!", "Creado con exito", `success`);
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }
        }
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error.response.data}`,
      });
      console.log(error);
    }
  };

  function EditArticulos(idOC, contenedor) {
    setInputFields([
      {
        id: uuidv4(),
        articulosOrden: "",
        articulos: "",
        cantidad: 0,
        cantidadAnterior: 0,
        piezasPorCaja: 0,
        cajas: 0,
        cantidadOC: 0,
        costo: 0,
        costoTotal: 0,
        proveedorOrden: "",
        notasCxP: "",
      },
    ]);
    setIdArtEdit(idOC);
    setContenedorEdit(contenedor);
    axios
      .get(`${URL_ARTICULOS_EMBARQUES}/embarque/${idOC}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosOrden = response.data;
        let arrayTabla = allArticulosOrden
          .map((a) => {
            return {
              id: a._id,
              articulosOrden: a.articulosOC[0]._id,
              articulos: a.articulos[0]._id,
              cantidad: a.cantidad,
              cantidadAnterior: a.cantidad,
              piezasPorCaja: a.articulos[0].piezasPorCaja,
              cajas:
                parseFloat(a.cantidad) /
                parseFloat(a.articulos[0].piezasPorCaja),
              cantidadOC: a.articulosOC[0].cantidad,
              costo: a.costo,
              costoTotal: a.total_costo,
              proveedorOrden: a.proveedores[0]._id,
              notasCxP: a.notasCxP[0]._id,
              costoNotasCxP: a.notasCxP[0].total_costo,
              saldoNotasCxP: a.notasCxP[0].saldo,
            };
          })
          .filter(function (el) {
            return el != null;
          });
        let dataFinal = Object.values(arrayTabla);
        setArticulosOrden(dataFinal);

        let cant = dataFinal.map((c) => parseFloat(c.cantidad));
        let TC = cant.reduce((t, total, index) => t + total, 0);
        setTotalCantidadEdit(TC);
        let importes = dataFinal.map((c) => parseFloat(c.total));
        let TG = importes.reduce((t, total, index) => t + total, 0);
        setTotalGeneralEdit(TG);
      })
      .catch((err) => {
        console.log(err);
      });
    toggleEditArticulos();
  }

  function ActualizaTotales() {
    let cant = articulosOrden.map((c) => parseFloat(c.cantidad));
    let TC = cant.reduce((t, total, index) => t + total, 0);
    setTotalCantidadEdit(TC);

    let agrupado = articulosOrden.reduce(function (groups, item) {
      const val = item["notasCxP"];
      groups[val] = groups[val] || {
        notasCxP: item.notasCxP,
        costoTotal: 0,
        cantidad: 0,
      };
      groups[val].proveedorOrden = item.proveedorOrden;
      groups[val].costoTotal += item.costoTotal;
      groups[val].cantidad += item.cantidad;
      groups[val].notasCxP = item.notasCxP;
      groups[val].saldoNotasCxP = item.saldoNotasCxP;
      groups[val].costoNotasCxP = item.costoNotasCxP;
      return groups;
    }, []);
    let temp = Object.values(agrupado);
    setAgrupado(temp);
  }

  const handleChangeInputCantidad = (id, event) => {
    const newArticulosOrden = articulosOrden.map((i) => {
      if (id === i.id) {
        i[event.target.name] = parseFloat(event.target.value);
        i.cajas = parseFloat(event.target.value) / parseFloat(i.piezasPorCaja);
        i.costoTotal = parseFloat(event.target.value) * parseFloat(i.costo);
      }
      return i;
    });
    setArticulosOrden(newArticulosOrden);
    ActualizaTotales();
  };

  const handleChangeInputCantidadCajas = (id, event) => {
    const newArticulosOrden = articulosOrden.map((i) => {
      if (id === i.id) {
        let cantTemp =
          parseFloat(event.target.value) * parseFloat(i.piezasPorCaja);
        i[event.target.name] = event.target.value;
        i.cantidad =
          parseFloat(event.target.value) * parseFloat(i.piezasPorCaja);
        i.costoTotal =
          parseFloat(event.target.value) *
          parseFloat(i.piezasPorCaja) *
          parseFloat(i.costo);
      }
      return i;
    });
    setArticulosOrden(newArticulosOrden);
    ActualizaTotales();
  };

  const saveEditArt = async (event) => {
    event.preventDefault();

    setValidaBoton(false);
    toggleProgreso();

    let totalArticulos = articulosOrden.length + articulosOrden.length + agrupado.length
    let TCosto = articulosOrden.map((c) => parseFloat(c.costoTotal));
    let TTC = TCosto.reduce((t, total, index) => t + total, 0);
    try {
      await axios
        .patch(
          `${URL_EMBARQUES}/${idArtEdit}`,
          {
            total_cantidad: totalCantidadEdit,
            total_costo: parseFloat(TTC).toFixed(2),
            saldo: parseFloat(TTC).toFixed(2),
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then(() => {
          agrupado.map((b) => {
            axios
              .patch(
                `${URL_NOTAS_CXP}editEmbarque/${b.notasCxP}`,
                {
                  proveedores: b.proveedorOrden,
                  total_cantidad: b.cantidad,
                  total_costo: parseFloat(b.costoTotal).toFixed(2),
                  saldo:
                    parseFloat(b.saldoNotasCxP) -
                    parseFloat(b.costoNotasCxP) +
                    parseFloat(b.costoTotal),
                  difSaldo:
                    parseFloat(b.costoTotal) - parseFloat(b.costoNotasCxP),
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              )
              .then(() => {
                totalArticulos = totalArticulos - 1;
                if (totalArticulos == 0) {
                  axios
                    .post(
                      URL_LOGS,
                      {
                        tipo: "Crear Embarque",
                        detalle: `Fecha Embarque ${fechaEmbarque}`,
                        user: user.id,
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    )
                    .then(() => {
                      Swal.fire(
                        "Good job!",
                        "Creado con exito",
                        "success"
                      );
                      setTimeout(() => {
                        window.location.reload();
                      }, 1000);
                    })
                    .catch((error) => {
                      Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        footer: `${error.response.data}`,
                      });
                      console.log(error);
                      setValidaBoton(true);
                    });
                }
              })
              .catch((error) => {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                  footer: `${error.response.data}`,
                });
                console.log(error);
                setValidaBoton(true);
              });

            // Acaba el primer map
          });

          articulosOrden.map((j) => {
            axios
              .patch(
                `${URL_ARTICULOS_EMBARQUES}EditCant/${j.id}`,
                {
                  cantidad: j.cantidad - j.cantidadAnterior,
                  total_costo: j.costoTotal,
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              )
              .then(() => {
                totalArticulos = totalArticulos - 1;
                if (totalArticulos == 0) {
                  axios
                    .post(
                      URL_LOGS,
                      {
                        tipo: "Crear Embarque",
                        detalle: `Fecha Embarque ${fechaEmbarque}`,
                        user: user.id,
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    )
                    .then(() => {
                      Swal.fire(
                        "Good job!",
                        "Creado con exito",
                        "success"
                      );
                      setTimeout(() => {
                        window.location.reload();
                      }, 1000);
                    })
                    .catch((error) => {
                      Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        footer: `${error.response.data}`,
                      });
                      console.log(error);
                      setValidaBoton(true);
                    });
                }
              })
              .catch((error) => {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                  footer: `${error.response.data}`,
                });
                console.log(error);
                setValidaBoton(true);
              });
          });

          articulosOrden.map((w) => {
            axios
              .patch(
                `${URL_ARTICULOS_OC}editEmbarcadoCant/${w.articulosOrden}`,
                {
                  embarcado: w.cantidad - w.cantidadAnterior,
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              )
              .then(() => {
                totalArticulos = totalArticulos - 1;
                if (totalArticulos == 0) {
                  axios
                    .post(
                      URL_LOGS,
                      {
                        tipo: "Crear Embarque",
                        detalle: `Fecha Embarque ${fechaEmbarque}`,
                        user: user.id,
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    )
                    .then(() => {
                      Swal.fire("Good job!", "Creado con exito", "success");
                      setTimeout(() => {
                        window.location.reload();
                      }, 1000);
                    })
                    .catch((error) => {
                      Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        footer: `${error.response.data}`,
                      });
                      console.log(error);
                      setValidaBoton(true);
                    });
                }
              })
              .catch((error) => {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                  footer: `${error.response.data}`,
                });
                console.log(error);
                setValidaBoton(true);
              });
          });

          // Acaba el segundo map
        })
        // Catch primer then
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `${error.response.data}`,
          });
          console.log(error);
          setValidaBoton(true);
        });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        // footer: `${error.response.data}`,
      });
      console.log(error);
      setValidaBoton(true);
    }
  };


  function aplicarGasto() {
    let pesos = gastosEmbarque.filter((e) => e.select == true);

    let cantidadPesos = pesos.map((c) => parseFloat(c.real));
    let TP = cantidadPesos.reduce((t, total, index) => t + total, 0);

    setSubtotal(TP);

    setModalEditGastos(false);
    toggleAplicarGastos();
  }

  const calculaIVA = (e) => {
    setSelectedIva(e);
    let ivaTemp = ((subtotal * e) / 100).toFixed(2);
    setIva(ivaTemp);
    setTotal(parseFloat(subtotal) + parseFloat(ivaTemp));
  };

  const saveAplicarGasto = (event) => {
    event.preventDefault();
    setValidaBoton(false);
    toggleProgreso();
    let totalGastos = gastosEmbarque.length;
    axios
      .post(
        `${URL_GASTOS}Aplicar`,
        {
          fecha,
          pagado: "Si",
          subtotal,
          iva,
          total,
          descripcion: "Gasto Cont." + " " + numeroContenedor,
          factura,
          departamentos: selectedDepartamento,
          embarques: idEdit,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then((data) => {
        gastosEmbarque.map((a) => {
          if (a.select) {
            axios
              .patch(
                `${URL_GASTOS_EMBARQUES}/${a._id}`,
                {
                  aplicado: "Si",
                  gastos: data.data._id,
                  real: a.real,
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              )
              .then(() => {
                totalGastos = totalGastos - 1;
                if (totalGastos == 0) {
                  // axios.post(
                  //   `${URL_MOVIMIENTOS_BANCOS}`,
                  //   {
                  //     fecha,
                  //     importe: -total,
                  //     tipo: "Egreso",
                  //     bancos: selectedBanco,
                  //     gastos: data.data._id,
                  //   },
                  //   {
                  //     headers: {
                  //       Authorization: `Bearer: ${localStorage.getItem(
                  //         "app_token"
                  //       )}`,
                  //     },
                  //   }
                  // );

                  // axios.patch(
                  //   `${URL_EMBARQUES}/${idEdit}`,
                  //   {
                  //     gastado: "Si",
                  //   },
                  //   {
                  //     headers: {
                  //       Authorization: `Bearer: ${localStorage.getItem(
                  //         "app_token"
                  //       )}`,
                  //     },
                  //   }
                  // );

                  axios.post(
                    URL_LOGS,
                    {
                      tipo: "Crear Gasto desde Embarques",
                      detalle: `Fecha: ${fecha} / Descripcion: GASTOS EMABRQUES / Proveedor: ${selectedDepartamento}`,
                      user: user.id,
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  );
                  Swal.fire("Good job!", "Creado con exito", "success");
                  setTimeout(() => {
                    window.location.reload();
                  }, 1000);
                }
              })

              .catch((error) => {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                });
                console.log(error);
                setValidaBoton(true);
              });
          } else {
            totalGastos = totalGastos - 1;
          }
        });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      });
  };


  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_compras ? (
        <div className="card col-12">
          <Row>
            <Col md={8}>
              {user.menu_compras ? (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  href="/EmbarquesCreate"
                >
                  Nuevo Embarque
                </Button>
              ) : (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  disabled
                >
                  Nuevo Embarque
                </Button>
              )}
              <Button
                size="sm"
                href="/MenuCompras"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>Enviar Listado Ordenes de Embarque</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>
          <h3 align="center">Embarques</h3>
          <div className="row">
            <div className="col-md-8">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
            <div className="col-md-4 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>
          <Row>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Inicio Embarque</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaInicio}
                required
                onChange={(e) => {
                  setSelectedFechaInicio(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Fin Embarque</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaFin}
                required
                onChange={(e) => {
                  setSelectedFechaFin(e.target.value);
                }}
              />
            </Col>

            <Col md={2}>
              <Label>Recibido</Label>
              <Input
                bsSize="sm"
                type="select"
                value={recibido}
                required
                onChange={(e) => {
                  setRecibido(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="">Selecciona</option>
                <option value="Si">Recibidos</option>
                <option value="No">Por Rrecibir</option>
              </Input>
            </Col>
          </Row>
          <br />
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <tbody>
              {commentsData.map((a) => {
                {
                  return (
                    <tr>
                      <td>{a.recibido}</td>
                      <td>{a.numero}</td>
                      <td>{a.fechaEmbarque}</td>
                      <td>{a.contenedor}</td>
                      <td>{a.fechaEstimada}</td>
                      <td>
                        {new Intl.NumberFormat("en-US").format(a.totalCantidad)}
                      </td>
                      <td>
                        {user.menu_compras ? (
                          <div>
                            <Button
                              size="sm"
                              className="btn"
                              color="danger"
                              onClick={(e) =>
                                PDFOC(
                                  a.fechaEmbarque,
                                  a.contenedor,
                                  a.fechaEstimada,
                                  a._id,
                                  a.numero
                                )
                              }
                            >
                              <i class="far fa-file-pdf"></i>
                            </Button>
                            <Button
                              color="info"
                              id="Editar"
                              size="sm"
                              onClick={(e) =>
                                jalaInfo(
                                  a.fechaEmbarque,
                                  a.fechaEstimada,
                                  a.observaciones,
                                  a.contenedor,
                                  a.idEmbarques,
                                  a._id
                                )
                              }
                            >
                              <i class="far fa-edit"></i>
                            </Button>
{/* 
                            <Button
                              color="info"
                              id="Editar"
                              size="sm"
                              onClick={(e) =>
                                EditArticulos(a._id, a.contenedor)
                              }
                            >
                              <i class="fas fa-cart-plus"></i>
                            </Button> */}
                              {a.recibido == "No"?
                            <Button
                              color="primary"
                              id="Editar"
                              size="sm"
                              onClick={(e) => EditGastos(a._id)}
                            >
                              <i class="fas fa-dollar-sign"></i>
                            </Button>:undefined}
                          </div>
                        ) : (
                          <div>
                            <Button color="info" id="Editar" size="sm" disabled>
                              <i class="far fa-edit"></i>
                            </Button>
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </Table>
          <div className="col-md-6">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      ) : undefined}
      <br />

      <Modal size="lg" isOpen={modalEdit} toggle={toggleEdit}>
        <ModalHeader toggle={toggleEdit}>
          <h4>Editar Embarque # {numeroEmbarque}</h4>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md={4}>
              <Label>Fecha Embarque</Label>
              <Input
                type="date"
                placeholder="Fecha"
                value={fechaEmbarque}
                required
                onChange={(e) => {
                  setFechaEmbarque(e.target.value);
                }}
              />
            </Col>
            <Col md={4}>
              <Label># Contenedor</Label>
              <Input
                type="text"
                placeholder="contenedor"
                value={contenedor}
                required
                onChange={(e) => {
                  setContenedor(e.target.value);
                }}
              />
            </Col>
            <Col md={4}>
              <Label>Fecha Estimada de Arribo</Label>
              <Input
                type="date"
                placeholder="Fecha"
                value={fechaEstimada}
                required
                onChange={(e) => {
                  setFechaEstimada(e.target.value);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col md={8}>
              <Label for="exampleEmail" className="mr-sm-2">
                Observaciones
              </Label>
              <Input
                type="text"
                placeholder="Observaciones"
                value={observaciones}
                required
                onChange={(e) => {
                  setObservaciones(e.target.value);
                }}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={editEmbarques}>
            Guardar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        size="xxl"
        isOpen={modalEditArticulos}
        toggle={toggleEditArticulos}
      >
        <ModalHeader toggle={toggleEditArticulos}>
          <h4>Editar Contenedor # {contenedorEdit}</h4>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md={2}>
              <Label className="mr-sm-2">Proveedor</Label>
            </Col>
            <Col md={2}>
              <Label className="mr-sm-2">Articulo</Label>
            </Col>
            <Col md={2}>
              <Label className="mr-sm-2">OC</Label>
            </Col>
            <Col md={1}>
              <Label className="mr-sm-2">Cantidad OC</Label>
            </Col>
            <Col md={1}>
              <Label className="mr-sm-2">FOB</Label>
            </Col>
            <Col md={1}>
              <Label className="mr-sm-2">Cajas</Label>
            </Col>
            <Col md={1}>
              <Label className="mr-sm-2">Pzas por Caja</Label>
            </Col>
            <Col md={1}>
              <Label className="mr-sm-2">Cantidad</Label>
            </Col>
          </Row>

          {articulosOrden.map((inputField) => (
            <div key={inputField.id}>
              <Row>
                <Col md={2}>
                  <Input
                    bsSize="sm"
                    name="proveedor"
                    type="select"
                    value={inputField.proveedorOrden}
                    required
                    disabled
                  >
                    <option value="">Selecciona un Proveedor</option>
                    {proveedores.map((a) => {
                      return (
                        <option value={a._id}>{a.nombre_comercial}</option>
                      );
                    })}
                  </Input>
                </Col>
                <Col md={2}>
                  <Input
                    bsSize="sm"
                    name="articulos"
                    type="select"
                    value={inputField.articulos}
                    required
                    disabled
                  >
                    <option value="">Selecciona un Articulo</option>
                    {articulos
                      .sort((a, b) => (a.codigo > b.codigo ? 1 : -1))
                      .map((a) => {
                        return <option value={a._id}>{a.codigo}</option>;
                      })}
                  </Input>
                </Col>
                <Col md={2}>
                  <Input
                    bsSize="sm"
                    name="orden"
                    type="select"
                    value={inputField.articulosOrden}
                    required
                    disabled
                  >
                    <option value="">Selecciona una OC</option>
                    {articulosOC.map((a) => {
                      if (
                        a.articulos[0]._id == inputField.articulos &&
                        a.ordenesCompra[0].proveedores[0]._id ==
                          inputField.proveedorOrden
                      )
                        return (
                          <option value={a._id}>
                            OC {a.ordenesCompra[0].idOrdenCompra} / Cant{" "}
                            {a.cantidad}
                          </option>
                        );
                    })}
                  </Input>
                </Col>
                <Col md={1}>
                  <Input
                    bsSize="sm"
                    name="cantidadOC"
                    type="number"
                    min="0"
                    step="any"
                    value={inputField.cantidadOC}
                    disabled
                  />
                </Col>
                <Col md={1}>
                  <Input
                    bsSize="sm"
                    name="FOB"
                    type="number"
                    min="0"
                    step="any"
                    value={inputField.costo}
                    disabled
                  />
                </Col>
                <Col md={1}>
                  <Input
                    bsSize="sm"
                    name="cajas"
                    type="number"
                    min="0"
                    step="any"
                    value={inputField.cajas}
                    onChange={(event) =>
                      handleChangeInputCantidadCajas(inputField.id, event)
                    }
                  />
                </Col>

                <Col md={1}>
                  <Input
                    bsSize="sm"
                    name="piezasPorCaja"
                    type="string"
                    value={inputField.piezasPorCaja}
                    disabled
                  />
                </Col>

                <Col md={1}>
                  <Input
                    bsSize="sm"
                    name="cantidad"
                    type="number"
                    min="0"
                    step="any"
                    placeholder="Cantidad"
                    value={inputField.cantidad}
                    onChange={(event) =>
                      handleChangeInputCantidad(inputField.id, event)
                    }
                  />
                </Col>
              </Row>
            </div>
          ))}
          <Row>
            <Col md={12}>
              <h4 id="logoutBoton">
                TOTALES{" "}
                {new Intl.NumberFormat("en-US").format(totalCantidadEdit)} pzas.
              </h4>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={saveEditArt}>
            Guardar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
        <ModalHeader toggle={toggleProgreso}>
          <h4>Progreso</h4>
        </ModalHeader>
        <ModalBody>
          <h4>
            {" "}
            Este proceso puede tardar varios segundos.
            <br />
            Por favor no cierre ni refresque su navegador.
          </h4>
          <br />
          <div className="progreso">
            <Spinner
              style={{
                width: "50px",
                height: "50px",
                color: "#232B41",
              }}
            />
            <Spinner
              style={{
                width: "50px",
                height: "50px",
                color: "#232B41",
              }}
            />
            <Spinner
              style={{
                width: "50px",
                height: "50px",
                color: "#232B41",
              }}
            />
          </div>
        </ModalBody>
      </Modal>

     {/* Modal editar Gastos */}

     <Modal size="xl" isOpen={modalEditGastos} toggle={toggleEditGastos}>
        <ModalHeader toggle={toggleEditGastos}>
          <h4>Editar Gastos Embarques {numeroContenedor}</h4>
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={saveGastosEmbarque}>
            <Row>
              <Col md={4}>
                <Label className="mr-sm-2">Concepto</Label>
              </Col>
              <Col md={2}>
                <Label className="mr-sm-2">Estimado</Label>
              </Col>
              <Col md={2}>
                <Label className="mr-sm-2">Real</Label>
              </Col>
            </Row>
            {gastosEmbarque.map((ae) => (
              <div key={ae._id}>
                <Row>
                  <Col md={4}>
                    <Input
                      bsSize="sm"
                      name="concepto"
                      type="select"
                      value={ae.conceptosGastosEmbarques[0]._id}
                      disabled
                    >
                      <option value="">Selecciona</option>
                      {conceptosGastos.map((a) => {
                        return <option value={a._id}>{a.concepto}</option>;
                      })}
                    </Input>
                  </Col>

                  {ae.aplicado == "No" ? (
                    <>
                      <Col md={2}>
                        <Input
                          bsSize="sm"
                          name="estimado"
                          type="number"
                          min="0"
                          step="any"
                          value={ae.estimado}
                          onChange={(event) =>
                            handleChangeInputGastosExistentes(ae._id, event)
                          }
                        />
                      </Col>

                      <Col md={2}>
                        <Input
                          bsSize="sm"
                          name="real"
                          type="number"
                          min="0"
                          step="any"
                          placeholder="real"
                          value={ae.real}
                          onChange={(event) =>
                            handleChangeInputGastosExistentes(ae._id, event)
                          }
                        />
                      </Col>

                      <Col>
                        <Input
                          type="checkbox"
                          onChange={(e) => {
                            let checked = e.target.checked;
                            setGastosEmbarque(
                              gastosEmbarque.map((data) => {
                                if (ae._id == data._id) {
                                  data.select = checked;
                                }
                                return data;
                              })
                            );
                          }}
                          checked={ae.select}
                        />
                      </Col>
                    </>
                  ) : (
                    <>
                      <Col md={2}>
                        <Input
                          bsSize="sm"
                          name="estimado"
                          type="number"
                          min="0"
                          step="any"
                          value={ae.estimado}
                          disabled
                        />
                      </Col>

                      <Col md={2}>
                        <Input
                          bsSize="sm"
                          name="real"
                          type="number"
                          min="0"
                          step="any"
                          placeholder="real"
                          value={ae.real}
                          disabled
                        />
                      </Col>
                    </>
                  )}
                </Row>
              </div>
            ))}

            {/* Agregar articulos */}
            {inputFieldsGastos.map((inputField) => (
              <div key={inputField.id}>
                <Row>
                  <Col md={4}>
                    <Input
                      bsSize="sm"
                      name="concepto"
                      type="select"
                      value={inputField.concepto}
                      onChange={(event) => BuscaGasto(inputField.id, event)}
                    >
                      <option value="">Selecciona</option>
                      {conceptosGastos.map((a) => {
                        return <option value={a._id}>{a.concepto}</option>;
                      })}
                    </Input>
                  </Col>
                  <Col md={2}>
                    <Input
                      bsSize="sm"
                      name="estimado"
                      type="number"
                      min="0"
                      step="any"
                      value={inputField.estimado}
                      onChange={(event) =>
                        handleChangeInputGastos(inputField.id, event)
                      }
                    />
                  </Col>

                  <Col md={2}>
                    <Input
                      bsSize="sm"
                      name="real"
                      type="number"
                      min="0"
                      step="any"
                      placeholder="real"
                      value={inputField.real}
                      onChange={(event) =>
                        handleChangeInputGastos(inputField.id, event)
                      }
                    />
                  </Col>

                  <Col>
                    <Button
                      size="sm"
                      className="btn"
                      color="danger"
                      disabled={inputFieldsGastos.length === 1}
                      onClick={() => handleRemoveFieldsGastos(inputField.id)}
                      tabindex="-1"
                    >
                      <i class="fas fa-minus"></i>
                    </Button>
                    <Button
                      size="sm"
                      className="btn"
                      color="info"
                      onClick={handleAddFieldsGastos}
                      tabindex="-1"
                    >
                      <i class="fas fa-plus"></i>
                    </Button>
                  </Col>
                </Row>
              </div>
            ))}
            {validaBoton ? (
              <Button type="submit" className="btn btn-success">
                Guardar
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                Guardar
              </Button>
            )}

            <Button
              color="primary"
              // className="btn btn-success"
              id="Editar"
              // size="sm"
              onClick={(e) => aplicarGasto()}
            >
              <i class="fas fa-dollar-sign"></i> Pagar
            </Button>
          </Form>
        </ModalBody>
      </Modal>

      <Modal size="xl" isOpen={modalAplicarGastos} toggle={toggleAplicarGastos}>
        <ModalHeader toggle={toggleAplicarGastos}>
          <h4>Aplicar Gasto</h4>
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={saveAplicarGasto}>
            <Row>
              <Col sm={4}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Fecha
                </Label>
                <Input
                  type="date"
                  placeholder="Fecha"
                  value={fecha}
                  required
                  onChange={(e) => {
                    setFecha(e.target.value);
                  }}
                />
              </Col>
              <Col sm={4}>
                <Label className="mr-sm-2">Departamento</Label>
                <Input
                  type="select"
                  value={selectedDepartamento}
                  required
                  onChange={(e) => {
                    setSelectedDepartamento(e.target.value);
                  }}
                >
                  <option value="0">Selecciona</option>
                  {departamentos
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Label className="mr-sm-2">Banco</Label>
                <Input
                  type="select"
                  value={selectedBanco}
                  onChange={(e) => {
                    setSelectedBanco(e.target.value);
                  }}
                >
                  <option value="">Selecciona</option>
                  {bancos
                    .sort((a, b) => (a.banco > b.banco ? 1 : -1))
                    .map((a) => {
                      return (
                        <option value={a._id}>
                          {a.banco} {a.cuenta} {a.moneda}
                        </option>
                      );
                    })}
                </Input>
              </Col>

              <Col sm={4}>
                <Label for="exampleEmail" className="mr-sm-2">
                  IVA
                </Label>
                <Input
                  type="select"
                  placeholder="IVA"
                  value={selectedIva}
                  required
                  onChange={(e) => {
                    calculaIVA(e.target.value);
                  }}
                >
                  <option value="">Selecciona</option>
                  <option value="0">No</option>
                  <option value="16">Si</option>
                </Input>
              </Col>
            </Row>
            <br />
            <Row>
              <Col>
                <Label>
                  SubTotal Pesos{" "}
                  {"$" + new Intl.NumberFormat("en-US").format(subtotal)}
                </Label>
              </Col>
            </Row>

            <Row>
              <Col sm={4}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Subtotal
                </Label>
                <Input
                  type="number"
                  min="0"
                  step="any"
                  placeholder="Subtotal"
                  value={subtotal}
                  required
                  disabled
                  // onChange={(e) => {
                  //   calcula(e.target.value);
                  // }}
                />
              </Col>
              <Col sm={4}>
                <Label for="exampleEmail" className="mr-sm-2">
                  IVA
                </Label>
                <Input
                  type="number"
                  min="0"
                  step="any"
                  placeholder="IVA"
                  value={iva}
                  disabled
                />
              </Col>
              <Col sm={4}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Total
                </Label>
                <Input
                  type="number"
                  min="0"
                  step="any"
                  placeholder={total}
                  disabled
                />
              </Col>
            </Row>

            {validaBoton ? (
              <Button type="submit" className="btn btn-success">
                Guardar
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                Guardar
              </Button>
            )}
          </Form>
        </ModalBody>
      </Modal>
      {loader}
    </>
  );
}

export default ListadoEmbarques;
