import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { Col, Row, Button, Form, FormGroup, Label, Input } from "reactstrap";
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";
// import Get from "../../Get";

function ClientesCreateTiendas() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_GRUPOS = process.env.REACT_APP_URL_GRUPOS;
  const URL_COLABORADORES = process.env.REACT_APP_URL_COLABORADORES;
  const URL_USERS = process.env.REACT_APP_URL_USERS;
  const URL_AREAS = process.env.REACT_APP_URL_AREAS;
  const URL_DEPARTAMENTOS_CLIENTES = process.env.REACT_APP_URL_DEPARTAMENTOS_CLIENTES;
  const URL_PAQUETERIAS = process.env.REACT_APP_URL_PAQUETERIAS;
  const AREAS_GENERAL = process.env.REACT_APP_AREAS_GENERAL;
  const AREAS_MATRIZ = process.env.REACT_APP_AREAS_MATRIZ;
  const AREAS_PANTACO = process.env.REACT_APP_AREAS_PANTACO;
  // const [text, setText] = useState(false);

  const [nombre_comercial, setNombreComercial] = useState("");
  const [razon_social, setRazonSocial] = useState("NA");
  const [RFC, setRFC] = useState("NA");
  const [calle, setCalle] = useState("NA");
  const [numero_ext, setNumeroExt] = useState("NA");
  const [numero_int, setNumeroInt] = useState("NA");
  const [delegacion, setDelegacion] = useState("NA");
  const [estado, setEstado] = useState("NA");
  const [pais, setPais] = useState("Mexico");
  const [cp, setCP] = useState(0);
  const [colonia, setColonia] = useState("NA");
  // const [codigo, setCodigo] = useState("");
  const [ciudad, setCiudad] = useState("NA");
  const [plazo, setPlazo] = useState(0);
  const [regFiscal, setRegFiscal] = useState("NA");
  const [telefono, setTelefono] = useState("");
  const [email, setEmail] = useState("a@a.a");
  const [grupos, setGrupos] = useState([]);
  const [selectedGrupo, setSelectedGrupo] = useState("");
  const [departamentos, setDepartamentos] = useState([]);
  const [selectedDepartamento, setSelectedDepartamento] = useState("");
  const [paqueterias, setPaqueterias] = useState([]);
  const [selectedPaqueteria, setSelectedPaqueteria] = useState("");
  const [colaboradores, setColaboradores] = useState([]);
  const [areas, setAreas] = useState([]);
  const [selectedArea, setSelectedArea] = useState("");
  const [selectedVendedor, setSelectedVendedor] = useState("");
  const [observaciones, setObservaciones] = useState("NA");
  const [forma_pago, setFormaPago] = useState("");
  const [descuento, setDescuento] = useState(0);
  const [validaBoton, setValidaBoton] = useState(true);

  useEffect(() => {
    axios
      .get(URL_COLABORADORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allColaboradores = res.data;
        setColaboradores(allColaboradores);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_GRUPOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allGrupos = res.data;
        setGrupos(allGrupos);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_DEPARTAMENTOS_CLIENTES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allDepartamentos = res.data;
        setDepartamentos(allDepartamentos);
      })
      .catch((err) => {
        console.log(err);
      });
      axios
        .get(URL_PAQUETERIAS, {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((res) => {
          const allPaqueterias = res.data;
          setPaqueterias(allPaqueterias);
        })
        .catch((err) => {
          console.log(err);
        });

    axios
      .get(URL_AREAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allAreas = res.data;
        setAreas(allAreas);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const saveClientes = async (event) => {
    event.preventDefault();
    setValidaBoton(false);
    try {
      await axios
        .post(
          URL_CLIENTES,
          {
            nombre_comercial,
            razon_social,
            RFC,
            calle,
            numero_ext,
            numero_int,
            delegacion,
            estado,
            pais,
            cp,
            colonia,
            ciudad,
            telefono,
            email,
            grupos: selectedGrupo,
            departamentosClientes: selectedDepartamento,
            vendedor: selectedVendedor,
            observaciones,
            ultimo_pago: "2000-01-01",
            forma_pago,
            // codigo,
            areas: selectedArea,
            plazo,
            regFiscal,
            cargos:0,
            abonos:0,
            saldo:0,
            descuento,
            paqueterias: selectedPaqueteria,
            alertas:"Si"
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then((response) => {
          Swal.fire("Good job!", "Creado con exito", `success`);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `${error.response.data.message}`,
          });
          console.log(error);
          setValidaBoton(true);
        });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error.response.data.message}`,
      });
      console.log(error);
      setValidaBoton(true);
    }
  };

  return (
    <>
      <Header />
      <br />
      <br />
      {user.clientes_create ? (
        <div className="card container">
          <h3 align="center">Nuevo Cliente</h3>
           {/* <Get/> */}
          <Form onSubmit={saveClientes}>
            <Row>
              {/* <Col md={3}>
                <Label>Nombre</Label>
                <Input
                  type="text"
                  placeholder="Nombre"
                  value={nombre}
                  required
                  onChange={(e) => {
                    setNombre(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Apellido</Label>
                <Input
                  type="text"
                  placeholder="Apellido"
                  value={apellido}
                  required
                  onChange={(e) => {
                    setApellido(e.target.value);
                  }}
                />
              </Col> */}
              {/* <Col md={3}>
                <Label>Codigo</Label>
                <Input
                  type="text"
                  placeholder="Codigo"
                  value={codigo}
                  required
                  onChange={(e) => {
                    setCodigo(e.target.value);
                  }}
                />
              </Col> */}
              <Col md={3}>
                <Label>Nombre Comercial</Label>
                <Input
                  type="text"
                  placeholder="Nombre Comercial"
                  value={nombre_comercial}
                  required
                  onChange={(e) => {
                    setNombreComercial(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Razon Social</Label>
                <Input
                  type="text"
                  placeholder="Razon Social"
                  value={razon_social}
                  required
                  onChange={(e) => {
                    setRazonSocial(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>RFC</Label>
                <Input
                  type="text"
                  placeholder="RFC"
                  value={RFC}
                  required
                  onChange={(e) => {
                    setRFC(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label className="mr-sm-2">Paqueteria</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedPaqueteria}
                  required
                  onChange={(e) => {
                    setSelectedPaqueteria(e.target.value);
                  }}
                >
                  <option value="0">Selecciona </option>
                  {paqueterias
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Label>Calle</Label>
                <Input
                  type="text"
                  placeholder="Calle"
                  value={calle}
                  required
                  onChange={(e) => {
                    setCalle(e.target.value);
                  }}
                />
              </Col>
              <Col md={2}>
                <Label>No. Ext</Label>
                <Input
                  type="text"
                  placeholder="No. Ext"
                  value={numero_ext}
                  required
                  onChange={(e) => {
                    setNumeroExt(e.target.value);
                  }}
                />
              </Col>
              <Col md={2}>
                <Label>No. Int</Label>
                <Input
                  type="text"
                  placeholder="No. Int"
                  value={numero_int}
                  required
                  onChange={(e) => {
                    setNumeroInt(e.target.value);
                  }}
                />
              </Col>
              <Col md={2}>
                <Label>Pais</Label>
                <Input
                  type="text"
                  placeholder="Pais"
                  value={pais}
                  required
                  onChange={(e) => {
                    setPais(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <Label>Estado</Label>
                <Input
                  type="text"
                  placeholder="Estado"
                  value={estado}
                  required
                  onChange={(e) => {
                    setEstado(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Ciudad</Label>
                <Input
                  type="text"
                  placeholder="Ciudad"
                  value={ciudad}
                  required
                  onChange={(e) => {
                    setCiudad(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Delegacion</Label>
                <Input
                  type="text"
                  placeholder="Delegacion o Municipio"
                  value={delegacion}
                  required
                  onChange={(e) => {
                    setDelegacion(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Colonia</Label>
                <Input
                  type="text"
                  placeholder="Colonia"
                  value={colonia}
                  required
                  onChange={(e) => {
                    setColonia(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <Label>CP</Label>
                <Input
                  type="text"
                  placeholder="CP"
                  value={cp}
                  required
                  onChange={(e) => {
                    setCP(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Telefono</Label>
                <Input
                  type="text"
                  placeholder="Telefono"
                  value={telefono}
                  required
                  onChange={(e) => {
                    setTelefono(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Email</Label>
                <Input
                  type="email"
                  placeholder="Email"
                  value={email}
                  required
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label className="mr-sm-2">Vendedor</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedVendedor}
                  required
                  onChange={(e) => {
                    setSelectedVendedor(e.target.value);
                  }}
                >
                  <option value="0">Selecciona un Vendedor</option>
                  {colaboradores
                    .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                    .map((a) => {
                      return (
                        <option value={a._id}>
                          {a.nombre} {a.apellido}
                        </option>
                      );
                    })}
                </Input>
              </Col>
            </Row>

            <Row>

              <Col md={3}>
                <Label>Forma de Pago</Label>
                <Input
                  type="select"
                  placeholder="Forma de Pago"
                  value={forma_pago}
                  required
                  onChange={(e) => {
                    setFormaPago(e.target.value);
                  }}
                >
                  <option value="0">Forma de Pago</option>
                  <option value="Contado">Contado</option>
                  <option value="Credito">Credito</option>
                </Input>
              </Col>

              <Col md={3}>
                <Label>Plazo</Label>
                <Input
                  type="select"
                  placeholder="Plazo"
                  value={plazo}
                  required
                  onChange={(e) => {
                    setPlazo(e.target.value);
                  }}
                >
                  <option value="">Plazo</option>
                  <option value="0">0</option>
                  <option value="30">30</option>
                  <option value="60">60</option>
                  <option value="90">90</option>
                  <option value="120">120</option>
                  <option value="150">150</option>
                  <option value="180">180</option>
                </Input>
              </Col>

              <Col md={3}>
                <Label>Reg Fiscal</Label>
                <Input
                  type="select"
                  placeholder="RegFiscal"
                  value={regFiscal}
                  required
                  onChange={(e) => {
                    setRegFiscal(e.target.value);
                  }}
                >
                  <option value="">Reg Fiscal</option>
                  <option value="0">0</option>
                  <option value="601">601 General de Ley Personas Morales</option>
                  <option value="603">603 Personas Morales con Fines no Lucrativos</option>
                  <option value="605">605 Sueldos y Salarios e Ingresos Asimilados al Salario</option>
                  <option value="608">608 Demas Ingresos</option>
                  <option value="612">612 Personas Fisicas con Actividad Empresarial</option>
                  <option value="616">616 Sin Obligacion Fiscal</option>
                  <option value="621">621 Incorporacion Fiscal</option>
                  <option value="625">625 Reg. de las act. emp. cpn ingresos a traves de plat. tec.</option>
                  <option value="626">626 Regimen simplificado de Confianza</option>
                </Input>
              </Col>
              <Col md={3}>
                <Label className="mr-sm-2">Grupo</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedGrupo}
                  required
                  onChange={(e) => {
                    setSelectedGrupo(e.target.value);
                  }}
                >
                  <option value="0">Selecciona una Grupo</option>
                  {grupos
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <Label className="mr-sm-2">Departamento</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedDepartamento}
                  required
                  onChange={(e) => {
                    setSelectedDepartamento(e.target.value);
                  }}
                >
                  <option value="0">Selecciona </option>
                  {departamentos
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
              </Col>

              <Col md={3}>
                <Label className="mr-sm-2">Almacen</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedArea}
                  required
                  onChange={(e) => {
                    setSelectedArea(e.target.value);
                  }}
                >
                  <option value="0">Selecciona</option>
                  {areas
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      if (a._id != AREAS_GENERAL) {
                        if (user.areas == AREAS_GENERAL) {
                          return <option value={a._id}>{a.name}</option>;
                        } else if (user.areas == AREAS_PANTACO) {
                          if (
                            a._id == AREAS_PANTACO ||
                            a._id == AREAS_MATRIZ
                          ) {
                            return <option value={a._id}>{a.name}</option>;
                          }
                        } else if (user.areas == a._id) {
                          return <option value={a._id}>{a.name}</option>;
                        }
                      }
                    })}
                </Input>
              </Col>

              <Col md={3}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Observaciones
                </Label>
                <Input
                  type="text"
                  placeholder="Observaciones"
                  value={observaciones}
                  required
                  onChange={(e) => {
                    setObservaciones(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Descuento</Label>
                <Input
                  type="number"
                  placeholder="Descuento"
                  value={descuento}
                  required
                  onChange={(e) => {
                    setDescuento(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <br />
            {validaBoton ? (
              <Button type="submit" className="btn btn-success">
                Guardar
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                Guardar
              </Button>
            )}
            <Button
              href="/ListadoClientesTiendas"
              className="btn btn-danger"
              id="botonListado"
            >
              Regresar
            </Button>
          </Form>
          <br />
        </div>
      ) : undefined}
      
    </>
  );
}

export default ClientesCreateTiendas;
