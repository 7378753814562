import React, { useState, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import Baja from "../../Baja";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import moment from "moment";

function MovimientosRicardo() {
  const { user } = useContext(AuthContext);
  const URL_SEGUIMIENTOS = `${process.env.REACT_APP_URL_SEGUIMIENTOS}`;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;

  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  // let hoy = new Date()
  // let inicio = new Date()
  // inicio.setDate(inicio.getDate()-30);
  // let endDate   = moment(hoy).format("YYYY-MM-DD"); 
  // let startDate   = moment(inicio).format("YYYY-MM-DD");

  const [movimientos, setMovimientos] = useState([]);
  const [unicosMovimientos, setUnicosMovimientos] = useState([]);
  const [selectedMovimientos, setSelectedMovimientos] = useState("");

  const [articulos, setArticulos] = useState([]);
  const [unicosArticulos, setUnicosArticulos] = useState([]);
  const [selectedArticulos, setSelectedArticulos] = useState("");

  const [destino, setDestino] = useState([]);
  const [unicosDestino, setUnicosDestino] = useState([]);
  const [selectedDestino, setSelectedDestino] = useState("");

  const [selectedFechaInicio, setSelectedFechaInicio] = useState(hoy);
  const [selectedFechaFin, setSelectedFechaFin] = useState(hoy);

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useMemo(() => {
    axios
      .get(`${URL_SEGUIMIENTOS}MovimientosRicardo/${selectedFechaInicio}/${selectedFechaFin}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allMovimientos = response.data;
        setMovimientos(allMovimientos);
        setComments(allMovimientos);

        let unicosMovimientos = allMovimientos.filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.movimiento === value.movimiento)
        );
        setUnicosMovimientos(unicosMovimientos);

        let unicosArticulos = allMovimientos.filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.articulo === value.articulo)
        );
        setUnicosArticulos(unicosArticulos);

        let unicosDestino = allMovimientos.filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.destino === value.destino)
        );
        setUnicosDestino(unicosDestino);

      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectedFechaInicio, selectedFechaFin]);

  function PDFTabla() {
    const data = comments
      .sort((a, b) => (a.codigo > b.codigo ? 1 : -1))
      .map((a) => {
        if (
        (selectedMovimientos == "" || selectedMovimientos == a.movimiento) &&
        (selectedArticulos == "" || selectedArticulos == a.articulo) &&
        (selectedDestino == "" || selectedDestino == a.destino) 
      ) {
          return [
            a.fecha,
            a.movimiento,
            a.numero,
            a.articulo,
            a.destino,
            new Intl.NumberFormat("en-US").format(a.cantidad),
          ];
        }
      });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape","mm", "a4", true);
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 15, 25, 25);
    doc.text(`Listado Disponible`, 20, 40);
    doc.autoTable({
      head: [["Fecha", "Movimiento", "Numero", "Articulo", "Destino", "Cantidad"]],
      body: dataPDFLimpia,
      startY: 45,
    });
    
    doc.save(`MovimientosRicardo.pdf`);
  }

  function excel() {
    const dataExcel = comments.map((a) => {
      if (
        (selectedMovimientos == "" || selectedMovimientos == a.movimiento) &&
        (selectedArticulos == "" || selectedArticulos == a.articulo) &&
        (selectedDestino == "" || selectedDestino == a.destino) 
      ) {
        return {
          Fecha: a.fecha,
          Movimiento: a.movimiento,
          Numero: a.numero,
          Articulo: a.articulo,
          Destino: a.destino,
          Cantidad: a.cantidad,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "MovimientosRicardo";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "MovimientosRicardo",
        sheetFilter: ["Fecha", "Movimiento", "Numero", "Destino", "Articulo", "Cantidad"],
        sheetHeader: ["Fecha", "Movimiento", "Numero", "Destino", "Articulo", "Cantidad"],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    const data = comments
    .sort((a, b) => (a.codigo > b.codigo ? 1 : -1))
    .map((a) => {
      if (
        (selectedMovimientos == "" || selectedMovimientos == a.movimiento) &&
        (selectedArticulos == "" || selectedArticulos == a.articulo) &&
        (selectedDestino == "" || selectedDestino == a.destino) 
      ) {
        return [
          a.fecha,
          a.movimiento,
          a.numero,
          a.articulo,
          a.destino,
          new Intl.NumberFormat("en-US").format(a.cantidad),
        ];
      }
    });
  const dataPDF = Object.values(data);
  const dataPDFLimpia = dataPDF.filter(function (el) {
    return el != null;
  });
  const doc = new jsPDF("landscape","mm", "a4", true);
  var img = new Image();
  img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
  doc.addImage(img, "png", 230, 30, 45, 15);
  var img2 = new Image();
  img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
  doc.addImage(img2, "png", 140, 15, 25, 25);
  doc.text(`Listado Disponible`, 20, 40);
  doc.autoTable({
    head: [["Fecha", "Movimiento", "Numero", "Articulo", "Destino", "Cantidad"]],
    body: dataPDFLimpia,
    startY: 45,
  });

    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado Movimientos Ricardo",
          email: mailTo,
          fileName: "MovimientosRicardo.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el seguimineto al articulo.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  const headers = [
    { name: "Fecha", field: "fecha", sortable: true },
    { name: "Movimiento", field: "movimiento", sortable: true },
    { name: "Numero", field: "numero", sortable: true },
    { name: "Articulo", field: "articulo", sortable: true },
    { name: "Destino", field: "destino", sortable: true },
    { name: "Cantidad", field: "cantidad", sortable: true },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.articulo.toLowerCase().includes(search.toLowerCase()) ||
          comment.movimiento.toLowerCase().includes(search.toLowerCase()) ||
          comment.destino.toLowerCase().includes(search.toLowerCase()) ||
          comment.cantidad.toString().includes(search) ||
          comment.numero.toString().includes(search)
      );
    }

    if (selectedMovimientos) {
      computedComments = computedComments.filter((e) =>
        e.movimiento.includes(selectedMovimientos)
      );
    }

    if (selectedArticulos) {
      computedComments = computedComments.filter((e) =>
        e.articulo.includes(selectedArticulos)
      );
    }

    if (selectedDestino) {
      computedComments = computedComments.filter((e) =>
        e.destino.includes(selectedDestino)
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "cantidad" &&
      sorting.field != "numero"
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "cantidad" || sorting.field == "numero")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "cantidad" || sorting.field == "numero")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [comments, currentPage, search, sorting, selectedMovimientos, selectedArticulos, selectedDestino]);
  
  let totalCantidad = 0
  
  return (
    <>
      <Header />
      <br />
      <br />
      {user.seguimiento_inventarios ? (
        <div className="card col-12">
          <Row>
            <Col md={8}>
              <Button
                size="sm"
                href="/MenuInventarios"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>Enviar Listado Disponible</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" 
              onClick={enviaMail}
              >
                Enviar
              </Button>
            </ModalFooter>
          </Modal>

          <h3 align="center">Movimientos Ricardo</h3>
          <Row>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Inicio</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaInicio}
                required
                onChange={(e) => {
                  setSelectedFechaInicio(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Fin</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaFin}
                required
                onChange={(e) => {
                  setSelectedFechaFin(e.target.value);
                }}
              />
            </Col>

          </Row>
          <br />

          {/* Tabla Movimientos */}
          <Pagination
            total={totalItems}
            itemsPerPage={ITEMS_PER_PAGE}
            currentPage={currentPage}
            onPageChange={(page) => setCurrentPage(page)}
          />

          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <tr>
              <td></td>
            <td style={{ paddingTop: "0px" }}>
                    <Input
                      bsSize="sm"
                      type="select"
                      value={selectedMovimientos}
                      onChange={(e) => {
                        setSelectedMovimientos(e.target.value);
                        setCurrentPage(1);
                      }}
                    >
                      <option value="">Selecciona</option>
                      {unicosMovimientos
                        .sort((a, b) =>
                          a.movimiento > b.movimiento ? 1 : -1
                        )
                        .map((a) => {
                          return (
                            <option value={a.movimiento}>{a.movimiento}</option>
                          );
                        })}
                    </Input>
                  </td>
              <td></td>
            <td style={{ paddingTop: "0px" }}>
                    <Input
                      bsSize="sm"
                      type="select"
                      value={selectedArticulos}
                      onChange={(e) => {
                        setSelectedArticulos(e.target.value);
                        setCurrentPage(1);
                      }}
                    >
                      <option value="">Selecciona</option>
                      {unicosArticulos
                        .sort((a, b) =>
                          a.articulo > b.articulo ? 1 : -1
                        )
                        .map((a) => {
                          return (
                            <option value={a.articulo}>{a.articulo}</option>
                          );
                        })}
                    </Input>
                  </td>

            <td style={{ paddingTop: "0px" }}>
                    <Input
                      bsSize="sm"
                      type="select"
                      value={selectedDestino}
                      onChange={(e) => {
                        setSelectedDestino(e.target.value);
                        setCurrentPage(1);
                      }}
                    >
                      <option value="">Selecciona</option>
                      {unicosDestino
                        .sort((a, b) =>
                          a.destino > b.destino ? 1 : -1
                        )
                        .map((a) => {
                          return (
                            <option value={a.destino}>{a.destino}</option>
                          );
                        })}
                    </Input>
                  </td>
            </tr>

            <tbody>
              {commentsData.map((a) => {
                totalCantidad = totalCantidad + parseFloat(a.cantidad);
                return (
                  <tr>
                    <td>{a.fecha}</td>
                    <td>{a.movimiento}</td>
                    <td>{a.numero}</td>
                    <td>{a.articulo}</td>
                    <td>{a.destino}</td>
                    <td>{new Intl.NumberFormat("en-US").format(a.cantidad)}</td>
                  </tr>
                );
              })}
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className="negrita">TOTAL</td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US").format(totalCantidad)}
                </td>


              </tr>
            </tbody>
          </Table>
          <div className="col-md-6">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      ) : undefined}

      {loader}
    </>
  );
}

export default MovimientosRicardo;
