import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useLayoutEffect,
  useMemo,
} from "react";
import { AuthContext } from "../../contexts/AuthContext";
import axios from "axios";
import Header from "../../layout/Header/Header";

import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Form,
  Card,
  CardHeader,
  CardText,
  CardBody,
} from "reactstrap";
import moment from "moment";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { TableHeader, Pagination, Search } from "../../DataTable";
import useFullPageLoader from "../../hooks/useFullPageLoader";

function TablaVentasArticulos() {
  const { user } = useContext(AuthContext);
  const URL_DASHBOARDS = process.env.REACT_APP_URL_DASHBOARDS;


  let hoy = new Date();
  let inicio = new Date();
  inicio.setDate(inicio.getDate() - 90);

  let endDate = moment(hoy).format("YYYY-MM-DD");
  let startDate = moment(inicio).format("YYYY-MM-DD");

  const [valorVenta, setValorVenta] = useState(0);
  const [cantidadVenta, setCantidadVenta] = useState(0);

  const [articulos, setArticulos] = useState([]);
  const [selectedArticulo, setSelectedArticulo] = useState("");

  const [selectedFechaInicio, setSelectedFechaInicio] = useState(startDate);
  const [selectedFechaFin, setSelectedFechaFin] = useState(endDate);

  const [cargando, setCargando] = useState(true);

    //--- Pagination
    const [comments, setComments] = useState([]);
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });
  
    const ITEMS_PER_PAGE = 50;

  const Toast = Swal.mixin({
    toast: true,
    position: "center",
    showConfirmButton: false,
    // timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  if (cargando) {
    Toast.fire({
      icon: "success",
      title: "Danos unos segundos....",
    });
  }

//   useMemo(() => {
//     let temp = info
//         if (selectedArticulo) {
//           temp = temp.filter((e) =>
//             e.idArticulo.includes(selectedArticulo)
//           );
//         }


//       let TVal = temp.map((c) => parseFloat(c.Importe));
//       let TV = TVal.reduce((t, total, index) => t + total, 0);
//       setValorVenta(TV);

//       let TCan = temp.map((c) => parseFloat(c.Cantidad));
//       let TC = TCan.reduce((t, total, index) => t + total, 0);
//       setCantidadVenta(TC);

// }, [info, selectedArticulo]);

useEffect(()=>{
  axios
  .get(`${URL_DASHBOARDS}VentasArticulos/${selectedFechaInicio}/${selectedFechaFin}`, {
    headers: {
      Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
    },
  })
  .then((response) => {
    let allDisponible = response.data;
    let array = allDisponible.reduce(function (groups, item) {
      const val = item["idArticulo"] + item["Mes"] + item["Tipo"]
      groups[val] = groups[val] || {
        idArticulo: item.idArticulo,
        Mes: item.Mes,
        Tipo: item.Tipo,
        Importe: 0,
        Cantidad: 0,
      };
      groups[val].Importe += item.Importe;
      groups[val].Cantidad += item.Cantidad;
      groups[val].Articulo = item.Articulo;
      groups[val].idArticulo = item.idArticulo;
      groups[val].Mes = item.Mes;
      groups[val].Tipo = item.Tipo;
      groups[val].Familia = item.Familia;
      return groups;
    }, []);

    let data = Object.values(array).sort((a, b) =>
      a.Importe < b.Importe ? 1 : -1
    );

      setComments(data)

      let TVal = data.map((c) => parseFloat(c.Importe));
      let TV = TVal.reduce((t, total, index) => t + total, 0);
      setValorVenta(TV);

      let TCan = data.map((c) => parseFloat(c.Cantidad));
      let TC = TCan.reduce((t, total, index) => t + total, 0);
      setCantidadVenta(TC);
      setCargando(false);
      Toast.close();
    })
    .catch((err) => {
      console.log(err);
    });

  
},[])

function BuscaInfo(){
  setComments([])
  setSelectedArticulo("")
  setCargando(true)
  axios
  .get(`${URL_DASHBOARDS}VentasArticulos/${selectedFechaInicio}/${selectedFechaFin}`, {
    headers: {
      Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
    },
  })
  .then((response) => {
    let allDisponible = response.data;
    let array = allDisponible.reduce(function (groups, item) {
      const val = item["idArticulo"] + item["Mes"] + item["Tipo"]
      groups[val] = groups[val] || {
        idArticulo: item.idArticulo,
        Mes: item.Mes,
        Tipo: item.Tipo,
        Importe: 0,
        Cantidad: 0,
      };
      groups[val].Importe += item.Importe;
      groups[val].Cantidad += item.Cantidad;
      groups[val].Articulo = item.Articulo;
      groups[val].idArticulo = item.idArticulo;
      groups[val].Mes = item.Mes;
      groups[val].Tipo = item.Tipo;
      groups[val].Familia = item.Familia;
      return groups;
    }, []);

    let data = Object.values(array).sort((a, b) =>
      a.Importe < b.Importe ? 1 : -1
    );

      setComments(data)

      setCargando(false);
      Toast.close();
    })
    .catch((err) => {
      console.log(err);
    });
}


const headers = [
  { name: "Articulo", field: "Articulo", sortable: true },
  { name: "Familia", field: "Familia", sortable: true },
  { name: "Mes", field: "Mes", sortable: true },
  { name: "Tipo", field: "Tipo", sortable: true },
  { name: "Cantidad", field: "Cantidad", sortable: true },
 { name: "Importe", field: "Importe", sortable: true },
];

const commentsData = useMemo(() => {
  let computedComments = comments;

  if (search) {
    computedComments = computedComments.filter(
      (comment) =>
      comment.Articulo.toLowerCase().includes(search.toLowerCase()) ||
      comment.Familia.toLowerCase().includes(search.toLowerCase()) ||
      comment.Mes.toLowerCase().includes(search.toLowerCase()) ||
      comment.Tipo.toLowerCase().includes(search.toLowerCase()) ||
      comment.Importe.toString().includes(search) ||
      comment.Cantidad.toString().includes(search) 
    );
  }

  if (selectedArticulo) {
    computedComments = computedComments.filter((e) =>
      e.idArticulo.includes(selectedArticulo)
    );
  }

  let TVal = computedComments.map((c) => parseFloat(c.Importe));
  let TV = TVal.reduce((t, total, index) => t + total, 0);
  setValorVenta(TV);

  let TCan = computedComments.map((c) => parseFloat(c.Cantidad));
  let TC = TCan.reduce((t, total, index) => t + total, 0);
  setCantidadVenta(TC);

  setTotalItems(computedComments.length);

  //Sorting comments
  if (
    sorting.field &&
    sorting.field != "Importe" &&
    sorting.field != "Cantidad"
  ) {
    const reversed = sorting.order === "asc" ? 1 : -1;
    computedComments = computedComments.sort(
      (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
    );
  }

  if (
    sorting.field &&
    sorting.order === "asc" &&
    (sorting.field == "Importe" ||
      sorting.field == "Cantidad")
  ) {
    computedComments = computedComments.sort((a, b) =>
      parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
    );
  }

  if (
    sorting.field &&
    sorting.order === "desc" &&
    (sorting.field == "Importe" ||
      sorting.field == "Cantidad")
  ) {
    computedComments = computedComments.sort((a, b) =>
      parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
    );
  }

  //Current Page slice
  return computedComments.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
  );
}, [
  comments,
  currentPage,
  search,
  sorting,
  selectedFechaInicio,
  selectedFechaFin,
  selectedArticulo
]);


  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_reportes ? (
      <div className="card container col-12">
        <Row>
          <Col md={8}>
            <Button
              size="sm"
              href="/Dashboards"
              className="btn btn-danger"
              id="botonListado"
            >
              Regresar
            </Button>
          </Col>
        </Row>
        <h3 align="center">Reporte Articulos</h3>
        <div className="row">
            <div className="col-md-8">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
            <div className="col-md-4 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>
        <Row>
        <Col md={2}>
            <Label className="mr-sm-2">Fecha Inicio</Label>
            <Input
              bsSize="sm"
              type="date"
              value={selectedFechaInicio}
              required
              onChange={(e) => {
                setSelectedFechaInicio(e.target.value)
              }}
            />
          </Col>
          <Col md={2}>
            <Label className="mr-sm-2">Fecha Fin</Label>
            <Input
              bsSize="sm"
              type="date"
              value={selectedFechaFin}
              required
              onChange={(e) => {
                setSelectedFechaFin(e.target.value)
              }}
            />
          </Col>
          <Col md={2}>
            <h4></h4>
            <Button
              size="sm"
              className="btn"
              id="botonListado"
              onClick={BuscaInfo}
              color="info"
            >
              Actualizar
            </Button>
          </Col>
        </Row>
        <br />
          {/* <Row>
                <Col md={2}>
              <Label className="mr-sm-2">Articulos</Label>
              <Input
                
                bsSize="sm"
                type="select"
                value={selectedArticulo}
                required
                onChange={(e) => {
                  setSelectedArticulo(e.target.value);
                }}
              >
                <option value="">Selecciona</option>
                {articulos
                  .sort((a, b) => (a.Articulo > b.Articulo ? 1 : -1))
                  .map((a) => {
                    return <option value={a.idArticulo}>{a.Articulo}</option>;
                  })}
              </Input>
            </Col>

        </Row>  */}

        <div
        className="container"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <Col md={3}>
          <Card>
            <CardHeader tag="h3" align="center">
              Piezas 
            </CardHeader>
            <CardBody>
              <CardText tag="h4" align="center">
                {new Intl.NumberFormat("en-US", {}).format(cantidadVenta)}
              </CardText>
            </CardBody>
          </Card>
        </Col>

        <Col md={3}>
          <Card>
            <CardHeader tag="h3" align="center">
              Venta $
            </CardHeader>
            <CardBody>
              <CardText tag="h4" align="center">
                {new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                  maximumFractionDigits: 2,
                }).format(valorVenta)}
              </CardText>
            </CardBody>
          </Card>
        </Col>
      </div>

      <Table>
      <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
        <tbody>
          {commentsData.map((a) => (
            <tr>
              <td>{a.Articulo}</td>
              <td>{a.Familia}</td>
              <td>{a.Mes}</td>
              <td>{a.Tipo}</td>
              <td>{new Intl.NumberFormat("en-US").format(a.Cantidad)}</td>
               <td>{new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
              }).format(a.Importe)}</td>
            </tr>
          ))}
        </tbody>
      </Table>


      </div>


       ) : undefined}  
    </>
  );
}
export default TablaVentasArticulos;
