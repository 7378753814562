import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from '../../contexts/AuthContext'
import axios from "axios";
import { Table, Col, Input, Label } from "reactstrap";
import Header from '../../layout/Header/Header'
import moment from "moment";

function ListadoLogs() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_USERS = process.env.REACT_APP_URL_USERS;
  const [logs, setLogs] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedFechaInicio, setSelectedFechaInicio] = useState("0");
  const [selectedFechaFin, setSelectedFechaFin] = useState("0");
  const [selectedUser, setSelectedUser] = useState("");

  useMemo(() => {
    axios
      .get(URL_LOGS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allLogs = response.data;
        let arrayLogs = allLogs
        .map((a)=>{
          return{
            createdAt:a.createdAt,
            fecha: moment(a.createdAt).utcOffset('-0600').format("YYYY-MM-DD"),
            hora: moment(a.createdAt).utcOffset('-0600').format("HH:mm:ss"),
            tipo: a.tipo,
            detalle:a.detalle,
            usuario: a.user[0].nombre + " " + a.user[0].apellido,
            idUser: a.user[0]._id,
            emailUser: a.user[0].email
          }
        })
        setLogs(arrayLogs);
      })
      .catch((err) => {
        console.log(err);
      });
      axios
      .get(URL_USERS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allUsers = response.data;
        setUsers(allUsers);
      })
      .catch((err) => {
        console.log(err);
      });
  },[]);

  
  return (
    <>
      <Header />
      <br />
      <br />
      <div className="container">
      {user.menu_logs ?(
        <div className="row">
          {
            <div className="col-md-12 col-sm-12">
              <div className="card">
                <div className="card-body">
                  <h3 align="center">Logs</h3>
                  <Col md={4}>
                  <h5>*Se presenta un mes de información</h5>
                  <Label>Usuario</Label>
                  <Input
                          bsSize="sm"
                          type="select"
                          value={selectedUser}
                          required
                          onChange={(e) => setSelectedUser(e.target.value)}
                        >
                          <option value="0">Selecciona</option>
                          {users
                            .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                            .map((a) => {
                              if (!a.menu_edoCta_Fam && !a.menu_edoCta_Don && a.email != "admin@sistemify.com") {
                              return (
                                  <option value={a._id}>
                                    {a.nombre} {a.apellido}
                                  </option>)
                              }
                            })}
                        </Input>
                        </Col>
                  <Table size="sm" striped borderless className="table-responsive-xl">
                    <thead>
                      <tr>
                        <th className="tituloTabla" style={{ paddingBottom: "0px" }}>Fecha</th>
                        <th className="tituloTabla" style={{ paddingBottom: "0px" }}>Tipo</th>
                        <th className="tituloTabla" style={{ paddingBottom: "0px" }}>Detalle</th>
                        <th className="tituloTabla" style={{ paddingBottom: "0px" }}>Usuario</th>
                      </tr>
                    </thead>
                    <tbody>
                      {logs
                      .sort((a, b) =>a.createdAt < b.createdAt ? 1 : -1)
                      .map((a) => {
                        if (
                          (selectedFechaInicio == 0 ||
                            selectedFechaInicio <= a.fecha) &&
                          (selectedFechaFin == 0 ||
                            selectedFechaFin >= a.fecha) &&
                            (selectedUser == 0 ||
                              selectedUser == a.idUser) &&
                              (a.emailUser != "admin@sistemify.com")
                        ){
                        return (
                          <tr>
                            <td>{`${a.fecha} Hora: ${a.hora}`}</td>
                            <td>{a.tipo}</td>
                            <td>{a.detalle}</td>
                            <td>{a.usuario}</td>
                          </tr>
                        )}
                      })}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          }
        </div>
        ): undefined } 
      </div>
    </>
  );
}

export default ListadoLogs;
