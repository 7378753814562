import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Spinner,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import Baja from "../../Baja";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import { v4 as uuidv4 } from "uuid";

function EntregarNota() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_ARTICULOS_VENTAS_TIENDAS = process.env.REACT_APP_URL_ARTICULOS_VENTAS_TIENDAS;
  const URL_VENTAS_TIENDAS = process.env.REACT_APP_URL_VENTAS_TIENDAS;
  const AREAS_GENERAL = process.env.REACT_APP_AREAS_GENERAL;

  const [notas, setNotas] = useState([]);
  const [articulos, setArticulos] = useState([]);
  const [selectedNota, setSelectedNota] = useState("");
  const [selectedCliente, setSelectedCliente] = useState("");
  const [total_cantidad_nueva, setTotalCantidadNueva] = useState(0);
  const [totalDinero, setTotalDinero] = useState(0);

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  const [validaBoton, setValidaBoton] = useState(true);
  const [botonGuardar, setBotonGuardar] = useState(false);


  useMemo(() => {

    if(user.areas){
      if(user.areas != AREAS_GENERAL){
    axios
    .get(`${URL_VENTAS_TIENDAS}Apartados/${user.areas}`, {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((res) => {
          const notas = res.data;
            setNotas(notas)
        })
        .catch((err) => {
          console.log(err);
        });
      } else {

        axios
        .get(`${URL_VENTAS_TIENDAS}ApartadosGeneral`, {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            })
            .then((res) => {
              const notas = res.data;
                setNotas(notas)
            })   
            .catch((err) => {
              console.log(err);
            })
          }
          }

    if (selectedNota) {
    //   axios
    //     .get(`${URL_VENTAS_TIENDAS}/${selectedNota}`, {
    //       headers: {
    //         Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
    //       },
    //     })
    //     .then((res) => {
    //       const nota = res.data;
    //       if (nota.is_active == "Si" ) {
            axios
              .get(`${URL_ARTICULOS_VENTAS_TIENDAS}Nota/${selectedNota}`, {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              })
              .then((response) => {
                let allArticulos = response.data;
                if (
                  user.areas == allArticulos[0].ventasTiendas[0].areas[0]._id
                ) {
                  if (allArticulos[0].ventasTiendas[0].entregado == "No") {
                    let arrayArticulos = allArticulos.map((a) => {
                      return {
                        codigo: a.articulos[0].codigo,
                        nombre: a.articulos[0].nombre,
                        piezasPorCaja: a.articulos[0].piezasPorCaja,
                        cantidad: a.cantidad,
                        cajas: (
                          parseFloat(a.cantidad) /
                          parseFloat(a.articulos[0].piezasPorCaja)
                        ).toFixed(2),
                        _id: a._id,
                        inventarios: a.inventarios[0]._id,
                      };
                    });
                    setArticulos(arrayArticulos);

                    let cant = allArticulos.map((c) => parseFloat(c.cantidad));
                    let TC = cant.reduce((t, total, index) => t + total, 0);
                    setTotalCantidadNueva(TC);
                    setBotonGuardar(true);
                  } else {
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: "La Nota ya fue entregada",
                    });
                    setSelectedNota("");
                  }
                } else {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "La nota No pertenece a tu tienda",
                  });
                  setSelectedNota("");
                }
              })
              .catch((err) => {
                console.log(err);
              });
    //       } else {
    //         Swal.fire({
    //           icon: "error",
    //           title: "Oops...",
    //           text: "El Apartado fue cancelado",
    //         });
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    }
  }, [user, selectedNota]);

  function guardaEntregado() {
    toggleProgreso();
    axios
      .patch(
        `${URL_VENTAS_TIENDAS}EntregarApartado/${selectedNota}`,
        {
          entregado: "Si",
          clientes: selectedCliente,
          total_general: totalDinero
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        let totalPedido = articulos.length;

        articulos.map((a) => {
          axios
            .patch(
              `${URL_ARTICULOS_VENTAS_TIENDAS}Entregar/${a._id}`,
              {
                cantidad: a.cantidad,
                inventarios: a.inventarios,
                entregado: "Si",
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              }
            )
            .then(() => {
              totalPedido = totalPedido - 1;
              if (totalPedido == 0) {
                axios
                  .post(
                    URL_LOGS,
                    {
                      tipo: "Entregar Venta de Tienda",
                      detalle: `${a._id}`,
                      user: user.id,
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  )
                  .then(() => {
                    Swal.fire("Good job!", "Creado con exito", `success`);
                    setTimeout(() => {
                      window.location.reload();
                    }, 1000);
                  })
                  .catch((error) => {
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: "Something went wrong!",
                    });
                    console.log(error);
                  });
              }
            });
        });

        Swal.fire({
          position: "center",
          icon: "success",
          title: "Se Entrego",
          showConfirmButton: false,
          timer: 2000,
        });
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
        console.log(error);
      });
  }

  const headers = [
    { name: "Codigo", field: "codigo", sortable: true },
    { name: "Nombre", field: "nombre", sortable: true },
    { name: "Piezas por Caja", field: "piezasPorCaja", sortable: true },
    { name: "Cajas", field: "cajas", sortable: true },
    { name: "Cantidad", field: "cantidad", sortable: true },
  ];

  function jalaNota(id){
    setSelectedNota(id)
    notas.map((a)=>{
      if(a._id == id){
        setSelectedCliente(a.clientes[0]._id)
        setTotalDinero(a.total_general)
      }
    })
  }

  let totalCajas = 0;
  return (
    <>
      <Header />
      <br />
      <br />
      {user.entregarNota ? (
        <div className="card col-12">
          <Row>
            <Col md={8}>
              <Button
                size="sm"
                href="/MenuTiendas"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
          </Row>

          <h3 align="center">Entrega de Mercancia</h3>
          <Col md={3}>
                    <Label className="mr-sm-2">Apartados</Label>
                    <Input
                      className="col-sm-12"
                      type="select"
                      value={selectedNota}
                      required
                      onChange={(e) => {
                        jalaNota(e.target.value);
                      }}
                    >
                      <option value="0">Selecciona </option>
                      {notas
                        .sort((a, b) => (a.idVentaTienda < b.idVentaTienda ? 1 : -1))
                        .map((a) => {
                          return <option value={a._id}>{a.prefijo} {a.consecutivo} {a.clientes[0].nombre_comercial}</option>;
                        })}
                    </Input>
                  </Col>
          <br />

          <Table size="sm" striped borderless className="table-responsive-xl">
            <tr>
              <th>Codigo</th>
              <th>Nombre</th>
              <th>Pzas Caja</th>
              <th>Cajas</th>
              <th>Cantidad</th>
            </tr>

            <tbody>
              {articulos.map((ao) => {
                totalCajas = totalCajas + parseFloat(ao.cajas);
                return (
                  <tr key={ao._id}>
                    <td>{ao.codigo}</td>
                    <td>{ao.nombre}</td>
                    <td>{ao.piezasPorCaja}</td>
                    <td>{ao.cajas}</td>
                    <td>{ao.cantidad}</td>
                  </tr>
                );
              })}
              <tr>
                <td></td>
                <td></td>
                <td td className="negrita" align="center">
                  Total
                </td>
                <td td className="negrita">
                  {totalCajas.toFixed(2)}
                </td>
                <td td className="negrita">
                  {total_cantidad_nueva}
                </td>
              </tr>
            </tbody>
            <br />
            {validaBoton && botonGuardar ? (
              <Button
                type="submit"
                className="btn btn-success"
                tabindex="-1"
                onClick={(e) => {
                  guardaEntregado();
                }}
              >
                Entregar
              </Button>
            ) : (
              <Button
                type="submit"
                className="btn btn-success"
                tabindex="-1"
                disabled
              >
                Entregar
              </Button>
            )}
          </Table>

          <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
              <h4>
                {" "}
                Este proceso puede tardar varios segundos.
                <br />
                Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
              </div>
            </ModalBody>
          </Modal>
        </div>
      ) : undefined}
    </>
  );
}

export default EntregarNota;
