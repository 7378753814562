import React, { useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { Col, Row, Button } from "reactstrap";
import Header from "../../layout/Header/Header";

function BotonesReportesV2() {
  const { user } = useContext(AuthContext);
  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_reportes ? (
      <div className="container">
          <Row>
            </Row>
            <br />
            <Row>
            <Col md={4} align="center">
            {user.menu_reportes ?(
              <Button
                href="/TablaVentasMensualComparativa"
                className="botonesMenu"
                color="success"
              >
                <i class="fas fa-chart-line fa-7x"></i>
                <br />
                <br />
                Venta Comparativa
              </Button>
            ): <Button
            disabled
            className="botonesMenu"
            color="success"
          >
            <i class="fas fa-chart-line fa-7x"></i>
            <br />
            <br />
            Venta Comparativa
          </Button> }
            </Col>

            <Col md={4} align="center">
            {user.menu_reportes ?(
              <Button
                href="/TablaAbonosMensualComparativa"
                className="botonesMenu"
                color="success"
              >
                <i class="fas fa-chart-line fa-7x"></i>
                <br />
                <br />
                Abonos Comparativa
              </Button>
            ): <Button
            disabled
            className="botonesMenu"
            color="success"
          >
            <i class="fas fa-chart-line fa-7x"></i>
            <br />
            <br />
            Abonos Comparativa
          </Button> }
            </Col>

            <Col md={4} align="center">
            {user.menu_reportes ?(
              <Button
                href="/TablaAbonosMensualComparativaAlmacen"
                className="botonesMenu"
                color="success"
              >
                <i class="fas fa-chart-line fa-7x"></i>
                <br />
                <br />
                Abonos Tiendas
              </Button>
            ): <Button
            disabled
            className="botonesMenu"
            color="success"
          >
            <i class="fas fa-chart-line fa-7x"></i>
            <br />
            <br />
            Abonos Tiendas
          </Button> }
            </Col>

            </Row>
            <br />
            <Row>
              
            <Col md={4} align="center">
            {user.menu_reportes ?(
              <Button
                href="/TablaVentasMensualComparativaCliente"
                className="botonesMenu"
                color="success"
              >
                <i class="fas fa-chart-line fa-7x"></i>
                <br />
                <br />
                Venta Comparativa
                <br />
                Cliente
              </Button>
            ): <Button
            disabled
            className="botonesMenu"
            color="success"
          >
            <i class="fas fa-chart-line fa-7x"></i>
            <br />
            <br />
            Venta Comparativa
            <br />
            Cliente
          </Button> }
            </Col>

              
            <Col md={4} align="center">
            {user.menu_reportes ?(
              <Button
                href="/TablaVentasMensualComparativaVendedor"
                className="botonesMenu"
                color="success"
              >
                <i class="fas fa-chart-line fa-7x"></i>
                <br />
                <br />
                Venta Comparativa
                <br />
                Vendedor
              </Button>
            ): <Button
            disabled
            className="botonesMenu"
            color="success"
          >
            <i class="fas fa-chart-line fa-7x"></i>
            <br />
            <br />
            Venta Comparativa
            <br />
            Vendedor
          </Button> }
            </Col>

            <Col md={4} align="center">
            {user.menu_reportes ?(
              <Button
                href="/TablaVentasArticulos"
                className="botonesMenu"
                color="success"
              >
                <i class="fas fa-chart-line fa-7x"></i>
                <br />
                <br />
                Reporte Articulos
              </Button>
            ): <Button
            disabled
            className="botonesMenu"
            color="success"
          >
            <i class="fas fa-chart-line fa-7x"></i>
            <br />
            <br />
            Reporte Articulos
          </Button> }
            </Col>
            </Row>
      </div>
      ) : undefined}
    </>
  );
}

export default BotonesReportesV2;
