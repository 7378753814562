import React, { useState, useEffect, useContext, useRef, useLayoutEffect, useMemo } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import axios from "axios";
import Header from "../../layout/Header/Header";

import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Form,
} from "reactstrap";
import moment from 'moment';
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";



function TablaAbonosMensualComparativa() {
  let inicio = new Date()
  inicio.setDate(1);
  let fechaEspecifica = new Date('2024-01-01');
  const year = fechaEspecifica.getFullYear();
  const years = Array.from(new Array(5), (val, index) => index + year);
 

  const { user } = useContext(AuthContext);
  const URL_GRAFICAS = process.env.REACT_APP_URL_GRAFICAS;

  const [graficas, setGraficas] = useState([]);


  const [selectedCliente, setSelectedCliente] = useState("");
  const [totalAnoAnterior, setTotalAnoAnterior] = useState(0);
  const [totalAnoActual, setTotalAnoActual] = useState(0);

  const [cargando, setCargando] = useState(false);
  const [ano, setAno] = useState("");

  const Toast = Swal.mixin({
    toast: true,
    position: 'center',
    showConfirmButton: false,
    // timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })


  useMemo(()=>{

    axios
    .get(`${URL_GRAFICAS}/abonosMensualComparativaGeneral/${ano}`, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((response) => {
      let allGraficas = response.data;
      
      const monthOrder = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

      // Sort the data by the custom order
      let sortedData = allGraficas.sort((a, b) => monthOrder.indexOf(a.Mes) - monthOrder.indexOf(b.Mes));

      let TAnt = allGraficas.map((c) => parseFloat(c.ImpAnterior));
      let TA = TAnt.reduce((t, total, index) => t + total, 0);
      setTotalAnoAnterior(TA);

      let TAct = allGraficas.map((c) => parseFloat(c.ImpActual));
      let TA2 = TAct.reduce((t, total, index) => t + total, 0);
      setTotalAnoActual(TA2);

      setGraficas(sortedData);
      setCargando(false)
      Toast.close()
    })
    .catch((err) => {
      console.log(err);
    });

    if(cargando){
      Toast.fire({
        icon: 'success',
        title: 'Danos unos segundos....'
      })
    }


  },[ano, cargando, selectedCliente])




  return (
    <>
    <Header />
    <br />
    <br />
    {/* {user.menu_reportes ? ( */}
      <div className="card container col-12">
        <h3 align="center">Venta Abonos Mensual</h3>
    <div className="container col-12">
    <Row>
          <Col md={2}>
                <Label>Año</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={ano}
                  required
                  onChange={(e) => {
                    setAno(e.target.value);
                    setCargando(true)
                  }}
                >
                  <option value="0">Selecciona un Año</option>
                  {years.map((a) => {
                    return <option value={a}>{a}</option>;
                  })}
                </Input>
              </Col>
        </Row>
        </div>
        <br />
        <Table size="sm" striped borderless className="table-responsive-xl">
          <thead>
          <tr>
            <th>Mes</th>
            <th>Año {ano ? (ano-1): (ano)} </th>
            <th>Año {ano}</th>
            <th>Diferencia</th>
            <th>Porcentaje</th>
          </tr>
          </thead>
          <tbody>
          {graficas.map((a)=>{
            let porcentaje = a.ImpAnterior > 0 ? ((a.ImpActual - a.ImpAnterior)/a.ImpAnterior)*100 : 100;
            return(
              <tr>
                <td>{a.Mes}</td>
                <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.ImpAnterior)}</td>
                <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.ImpActual)}</td>
                <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.ImpActual - a.ImpAnterior)}</td>
                <td>{(porcentaje).toFixed(2)}%</td>
              </tr>
            )
          })}
          <tr className="negrita">
            <td>Total</td>
            <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalAnoAnterior)}</td>
            <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalAnoActual)}</td>
            <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalAnoActual - totalAnoAnterior)}</td>
            <td>{((totalAnoActual - totalAnoAnterior)/totalAnoAnterior*100).toFixed(2)}%</td>
          </tr>
          </tbody>
        </Table>
            </div>
    {/*  ) : undefined} */}
  </>
  );
}
export default TablaAbonosMensualComparativa;
