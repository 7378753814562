import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { Button, Form, FormGroup, Label, Input, Row, Col } from "reactstrap";
import axios from "axios";
import moment from "moment";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CurrencyInput from "react-currency-input-field";
import { formatValue } from "react-currency-input-field";

function AnticiposCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_NOTAS_CARGO = process.env.REACT_APP_URL_NOTAS_CARGO;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;

  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  const [clientes, setClientes] = useState([]);

  const [fecha, setFecha] = useState(hoy);

  const [importe, setImporte] = useState(0);
  const [observaciones, setObservaciones] = useState("NA");

  const [value, setValue] = useState("");
  const [text, setText] = useState(false);
  const [validaBoton, setValidaBoton] = useState(true);



  const saveAnticipo = (event) => {
    event.preventDefault();
    setValidaBoton(false);
        axios.post(
          URL_NOTAS_CARGO,
          {
            fecha,
            importe,
            observaciones,
            clientes: value._id,
            saldo: importe,
            comisionTDC:0
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
      .then(() => {
        axios.post(
          URL_LOGS,
          {
            tipo: "Crear Anticipo",
            detalle: `Fecha: ${fecha} / Cliente: ${value.nombre_comercial} / Importe: ${importe} `,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        );
        Swal.fire("Good job!", "Creado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
        console.log(error);
        setValidaBoton(true);
      });
  };

  const options = clientes.map((option) => {
    const junta = option.nombre_comercial + " " + option.codigo;
    const firstLetter = option.nombre_comercial[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
      junta,
    };
  });

  const handleSearchClientes = async (searchText) => {
    try {
      const response = await axios.get(`${URL_CLIENTES}Search/${searchText}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("app_token")}`, // Agregar el token al header
        },
      });
      // Verificar que response.data sea un arreglo
      setClientes(Array.isArray(response.data) ? response.data : []);
    } catch (error) {
      console.error("Error buscando clientes", error);
    }
  };

  return (
    <>
      <Header />
      <br />
      <br />
      {user.notasCargoCreate ? (
        <div className="container">
          <div className="card container col-sm-9">
            <h3 align="center">Nueva Nota de Cargo</h3>
            <Form onSubmit={saveAnticipo}>
              <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                <Row>
                <Col md={6}>
                    <Label className="mr-sm-2">Fecha</Label>
                    <Input
                      type="date"
                      value={fecha}
                      onChange={(e) => {
                        setFecha(e.target.value);
                      }}
                    />
                  </Col>
                  </Row>
                  <Row>

                  <Col md={6}>
                    <Label className="mr-sm-2">Cliente</Label>
                    <Autocomplete
                      size="small"
                      value={value}
                      onInputChange={(event, newInputValue) => {
                        handleSearchClientes(newInputValue);  // Hacer una nueva búsqueda en cada cambio
                      }}
                      onChange={(event, selectedCliente) => {
                        if (selectedCliente) {
                          setValue(selectedCliente);
                        }
                      }}
                      options={options.sort(
                        (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                      )}
                      groupBy={(option) => option.firstLetter}
                      getOptionLabel={(option) => option.junta}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Escribe las primeras letras"
                          variant="outlined"
                        />
                      )}
                      renderOption={(option) => (
                        <React.Fragment>
                          {option.codigo} {option.nombre_comercial}
                        </React.Fragment>
                      )}
                    />
                  </Col>
                
                </Row>

                <Row>
                  <Col md={6}>
                    <Label className="mr-sm-2">Observaciones</Label>
                    <Input
                      type="text"
                      value={observaciones}
                      onChange={(e) => {
                        setObservaciones(e.target.value);
                      }}
                    />
                  </Col>
                  <Col sm={6}>
                    <Label className="mr-sm-2">Importe</Label>

                    <CurrencyInput
                      intlConfig={{ locale: "en-US", currency: "USD" }}
                      prefix="$"
                      defaultValue={0}
                      decimalsLimit={2}
                      value={importe}
                      className={`form-control`}
                      onValueChange={(value, name) => setImporte(value).toFixed(2)}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <br />
              <Col>
                <Row>
                  {validaBoton ? (
                    <Button type="submit" className="btn btn-success">
                      Guardar
                    </Button>
                  ) : (
                    <Button type="submit" className="btn btn-success" disabled>
                      Guardar
                    </Button>
                  )}
                  <div>
                    <SweetAlert
                      show={text}
                      title="Creado con Exito"
                      onConfirm={() => {
                        setText(false);
                      }}
                    />
                  </div>
                  <Button
                    href="/ListadoNotasCargo"
                    className="btn btn-danger"
                    id="botonListado"
                  >
                    Regresar
                  </Button>
                </Row>
              </Col>
            </Form>
          </div>
        </div>
      ) : undefined}
    </>
  );
}

export default AnticiposCreate;
