import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Spinner,
  Form,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import Baja from "../../Baja";
import BajaMultiple from "../../BajaMultiple";
import StatusMultiple from "../../StatusMultiple";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import qrcode from "qrcode-js";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import AutorizadoMultiple from "../../AutorizadoMultiple";

function CorteTiendas() {
  const { user } = useContext(AuthContext);
  const URL_ARTICULOS_VENTAS_TIENDAS =
    process.env.REACT_APP_URL_ARTICULOS_VENTAS_TIENDAS;
  const URL_VENTAS_TIENDAS = process.env.REACT_APP_URL_VENTAS_TIENDAS;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_CONFIGURACIONES = process.env.REACT_APP_URL_CONFIGURACIONES;
  const URL_AREAS = process.env.REACT_APP_URL_AREAS;
  const AREAS_GENERAL = process.env.REACT_APP_AREAS_GENERAL;
  const AREAS_MATRIZ = process.env.REACT_APP_AREAS_MATRIZ;
  const AREAS_BODEGA = process.env.REACT_APP_AREAS_BODEGA;
  const AREAS_ELI = process.env.REACT_APP_AREAS_ELI;
  const AREAS_ROLANDO = process.env.REACT_APP_AREAS_ROLANDO;
  const AREAS_MAGALI = process.env.REACT_APP_AREAS_MAGALI;

  const URL_ABONOS_TIENDAS = process.env.REACT_APP_URL_ABONOS_TIENDAS;
  const URL_DEVOLUCIONES_TIENDAS =
    process.env.REACT_APP_URL_DEVOLUCIONES_TIENDAS;
  const URL_GASTOS_TIENDAS = process.env.REACT_APP_URL_GASTOS_TIENDAS;
  const URL_DINEROS_TIENDAS = process.env.REACT_APP_URL_DINEROS_TIENDAS;

  const URL_WHATSAPP = process.env.REACT_APP_URL_WHATSAPP;
  const TELEFONO_SUPER_ADMIN = process.env.REACT_APP_TELEFONO_SUPER_ADMIN;

  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  const [areas, setAreas] = useState([]);
  const [ventasTiendas, setVentasTiendas] = useState([]);
  const [abonosTiendas, setAbonosTiendas] = useState([]);
  const [abonosTDCTiendas, setAbonosTDCTiendas] = useState([]);
  const [devolucionesTiendas, setDevolucionesTiendas] = useState([]);
  const [gastos, setGastos] = useState([]);
  const [dineros, setDineros] = useState([]);

  const [selectedArea, setSelectedArea] = useState("");
  const [selectedAreaName, setSelectedAreaName] = useState("");
  const [selectedFechaInicio, setSelectedFechaInicio] = useState("");

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);
  const [validaBoton, setValidaBoton] = useState(false);

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  const [notas, setNotas] = useState([]);
  const [modalNotas, setModalNotas] = useState(false);
  const toggleNotas = () => setModalNotas(!modalNotas);

  const [contador, setContador] = useState(0);
  const [totalCredito, setTotalCredito] = useState(0);
  const [totalContado, setTotalContado] = useState(0);
  const [totalAbonosContado, setTotalAbonosContado] = useState(-1);
  

  const [emailCorteDiario, setEmailCorteDiario] = useState("");

  useEffect(() => {
    axios
      .get(URL_CONFIGURACIONES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allConfig = response.data;
        setEmailCorteDiario(response.data[0].emailCorteDiario);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_AREAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allAreas = res.data;
        setAreas(allAreas);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useMemo(() => {

    if (contador == 6) {
      setModalProgreso(false);
      setValidaBoton(true);
    }
  }, [contador]);

  async function jalaInfo(e) {
    if (selectedArea != "") {
      toggleProgreso();
      setValidaBoton(false);

      let x = 0;

      axios
        .get(`${URL_ARTICULOS_VENTAS_TIENDAS}Analisis/${selectedArea}/${e}`, {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((res) => {
          let array = res.data;

          let a = array.filter((e) => e.diferencia != 0);
          setNotas(a);

          if (a.length == 0) {
            x = x + 1;
          } else {
            setModalProgreso(false);
            toggleNotas();
          }
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `${error.response}`,
          });
          console.log(error);
        });

      //  Jalamos Info

      // Notas
      await axios
        .get(`${URL_VENTAS_TIENDAS}Corte/${selectedArea}/${e}/${e}`, {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((response) => {
          x = x + 1;
          setContador(x);
          let allVentas = response.data;

          // Array para pagination
          let arrayTabla = allVentas
            .sort((a, b) => (a.idVentaTienda > b.idVentaTienda ? 1 : -1))
            .map((a) => {
              return {
                _id: a._id,
                numero: a.idVentaTienda,
                consecutivo: a.prefijo + a.consecutivo,
                activo: a.is_active,
                fecha: a.fecha,
                // colaboradores:
                //   a.colaboradores[0].nombre + " " + a.colaboradores[0].apellido,
                // idColaboradores: a.colaboradores[0]._id,
                observaciones: a.observaciones,
                subTotal: a.subTotal,
                iva: a.iva,
                total_general: a.total_general,
                clientes: a.clientes[0].nombre_comercial,
                idClientes: a.clientes[0]._id,
                total_cantidad: a.total_cantidad,
                comisionTDC: a.comisionTDC,
                comisionFlete: a.comisionFlete,
                impuestos: a.impuestos,
                area: a.areas[0].name,
                movimiento: "Nota",
                fiscal: a.fiscal,
                condPago: a.cond_pago,
              };
            })
            .filter(function (el) {
              return el != null;
            });

          let data = Object.values(arrayTabla);
          setVentasTiendas(data);
          setSelectedAreaName(data[0].area);

          let totalCredito = 0;
          let totalContado = 0;

          data.forEach((venta) => {
            if (venta.condPago === "Credito") {
              totalCredito += venta.total_general;
            } else if (venta.condPago === "Contado") {
              totalContado += venta.total_general;
            }
          });

          setTotalCredito(totalCredito);
          setTotalContado(totalContado);

          //
        })
        .catch((err) => {
          console.log(err);
        });

      // Abonos
      await axios
        .get(
          `${URL_ABONOS_TIENDAS}AbonosVentasDiario/${selectedArea}/${e}/${e}`,
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then((response) => {
          x = x + 1;
          setContador(x);
          let allVentas = response.data;
          // Array para pagination
          let arrayTabla = allVentas
            .sort((a, b) => (a.idAbonoTiendas > b.idAbonoTiendas ? 1 : -1))
            .map((a) => {
              if (a.concepto == "Abono") {
                if (a.ventasTiendas != "") {
                  if (a.ventasTiendas[0].is_active == "Si") {
                    return {
                      _id: a._id,
                      numero: a.idAbonoTiendas,
                      fecha: a.fecha,
                      // colaboradores: a.ventasTiendas[0].colaboradores[0].nombre + " " + a.ventasTiendas[0].colaboradores[0].apellido,
                      // idColaboradores: a.ventasTiendas[0].colaboradores[0]._id,
                      observaciones: a.ventasTiendas[0].observaciones,
                      clientes: a.clientes[0].nombre_comercial,
                      idClientes: a.clientes[0]._id,
                      condPago: a.ventasTiendas[0].cond_pago,
                      total_general: a.total,
                      efectivo: a.efectivo,
                      tdc: a.tdc,
                      transfer: a.transfer,
                      fechaTransfer: a.fechaTransfer,
                      consecutivo:
                        a.ventasTiendas[0].prefijo +
                        a.ventasTiendas[0].consecutivo,
                      movimiento: "Abono",
                      numeroNota: a.ventasTiendas[0].idVentaTienda,
                      destino: a.destino,
                      terminal: a.terminal,
                      codigoAgrupado: a.codigoAgrupado,
                      createdAt:
                        moment(a.createdAt)
                          .utcOffset("-0600")
                          .format("DD-MM-YYYY") +
                        " " +
                        moment(a.createdAt).utcOffset("-0600").format("HH:mm"),
                    };
                  }
                } else {
                  return {
                    _id: a._id,
                    numero: a.idAbonoTiendas,
                    fecha: a.fecha,
                    // colaboradores: a.clientes[0].vendedor[0].nombre + " " + a.clientes[0].vendedor[0].apellido,
                    // idColaboradores: a.clientes[0].vendedor[0]._id,
                    // observaciones: a.notasCargo[0].observaciones,
                    clientes: a.clientes[0].nombre_comercial,
                    idClientes: a.clientes[0]._id,
                    total_general: a.total,
                    efectivo: a.efectivo,
                    tdc: a.tdc,
                    transfer: a.transfer,
                    fechaTransfer: a.fechaTransfer,
                    consecutivo: "NC" + " " + a.notasCargo[0].idNotaCargo,
                    movimiento: "Abono",
                    condPago: "Credito",
                    numeroNota: "NC" + " " + a.notasCargo[0].idNotaCargo,
                    destino: a.destino,
                    terminal: a.terminal,
                    codigoAgrupado: a.codigoAgrupado,
                    createdAt:
                      moment(a.createdAt)
                        .utcOffset("-0600")
                        .format("DD-MM-YYYY") +
                      " " +
                      moment(a.createdAt).utcOffset("-0600").format("HH:mm"),
                  };
                }
              }
            })
            .filter(function (el) {
              return el != null;
            });

          let data = Object.values(arrayTabla);

          let abonosContado = 0;
            data.forEach(venta => {
              if (venta.condPago === 'Contado') {
                abonosContado += venta.total_general;
              }
            });
            setTotalAbonosContado(abonosContado);

          let agrupado = data.reduce(function (groups, item) {
            const val = item["codigoAgrupado"];
            groups[val] = groups[val] || {
              codigoAgrupado: item.codigoAgrupado,
              createdAt: item.createdAt,
              efectivo: 0,
              tdc: 0,
              transfer: 0,
              total_general: 0,
            };
            groups[val].tdc += item.tdc;
            groups[val].transfer += item.transfer;
            groups[val].efectivo += item.efectivo;
            groups[val].consecutivo = item.consecutivo;
            groups[val].clientes = item.clientes;
            groups[val].total_general += item.total_general;
            groups[val].condPago = item.condPago;
            groups[val].terminal = item.terminal;
            groups[val].destino = item.destino;
            groups[val].fechaTransfer = item.fechaTransfer;
            return groups;
          }, []);

          let agrupadoValues = Object.values(agrupado);

          setAbonosTiendas(agrupadoValues);
          //
        })
        .catch((err) => {
          console.log(err);
        });

      // Devoluciones
      await axios
        .get(
          `${URL_DEVOLUCIONES_TIENDAS}DevolucionesDiario/${selectedArea}/${e}/${e}`,
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then((response) => {
          x = x + 1;
          setContador(x);
          let allDevoluciones = response.data;
          // Array para pagination
          let arrayTabla = allDevoluciones
            .sort((a, b) =>
              a.idDevolucionTienda > b.idDevolucionTienda ? 1 : -1
            )
            .map((a) => {
              return {
                _id: a._id,
                numero: a.idDevolucionTienda,
                activo: a.is_active,
                fecha: a.fecha,
                // colaboradores:
                //   a.colaboradores[0].nombre + " " + a.colaboradores[0].apellido,
                // idColaboradores: a.colaboradores[0]._id,
                observaciones: a.observaciones,
                subTotal: a.subTotal,
                iva: a.iva,
                total_general: a.total_general,
                clientes: a.clientes[0].nombre_comercial,
                idClientes: a.clientes[0]._id,
                total_cantidad: a.total_cantidad,
                impuestos: a.impuestos,
                area: a.areas[0].name,
                movimiento: "Devolucion",
                fiscal: a.fiscal,
              };
            })
            .filter(function (el) {
              return el != null;
            });

          let data = Object.values(arrayTabla);
          setDevolucionesTiendas(data);
          //
        })
        .catch((err) => {
          console.log(err);
        });

      // Gastos
      await axios
        .get(`${URL_GASTOS_TIENDAS}GastosDiario/${selectedArea}/${e}/${e}`, {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((response) => {
          x = x + 1;
          setContador(x);
          let allGastos = response.data;
          let arrayTabla = allGastos
            .sort((a, b) => (a.fecha < b.fecha ? 1 : -1))
            .map((a) => {
              return {
                _id: a._id,
                fecha: a.fecha,
                descripcion: a.descripcion,
                subtotal: a.subtotal,
                iva: a.iva,
                total_general: a.total,
                concepto: a.concepto,
                movimiento: "Gasto",
                numero: a.idGastoTiendas,
              };
            })
            .filter(function (el) {
              return el != null;
            });

          let data = Object.values(arrayTabla);
          setGastos(data);
        })
        .catch((err) => {
          console.log(err);
        });

      // Dineros
      await axios
        .get(`${URL_DINEROS_TIENDAS}DinerosDiario/${selectedArea}/${e}/${e}`, {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((response) => {
          x = x + 1;
          setContador(x);
          let allDineros = response.data;
          let arrayTabla = allDineros
            .sort((a, b) => (a.fecha < b.fecha ? 1 : -1))
            .map((a) => {
              return {
                _id: a._id,
                fecha: a.fecha,
                fechaTransfer: a.fechaTransfer,
                total_general: a.total,
                efectivo: a.efectivo,
                tdc: a.tdc,
                transfer: a.transfer,
                terminal: a.terminal,
                destino: a.destino,
                observaciones: a.observaciones,
                movimiento: "Entrada Dinero",
                numero: a.idDineroTiendas,
              };
            })
            .filter(function (el) {
              return el != null;
            });

          let data = Object.values(arrayTabla);
          setDineros(data);
        })
        .catch((err) => {
          console.log(err);
        });
      // Terminamos de Jalar Info
    }
  }

  async function jalaInfoArea(e) {
    if (selectedFechaInicio != "") {
      toggleProgreso();
      setValidaBoton(false);

      let x = 0;

      axios
        .get(
          `${URL_ARTICULOS_VENTAS_TIENDAS}Analisis/${e}/${selectedFechaInicio}`,
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then((res) => {
          let array = res.data;

          let a = array.filter((e) => e.diferencia != 0);
          setNotas(a);

          if (a.length == 0) {
            x = x + 1;
          } else {
            setModalProgreso(false);
            toggleNotas();
          }
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `${error.response}`,
          });
          console.log(error);
        });

      //  Jalamos Info

      // Notas
      await axios
        .get(
          `${URL_VENTAS_TIENDAS}Corte/${e}/${selectedFechaInicio}/${selectedFechaInicio}`,
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then((response) => {
          x = x + 1;
          setContador(x);
          let allVentas = response.data;

          // Array para pagination
          let arrayTabla = allVentas
            .sort((a, b) => (a.idVentaTienda > b.idVentaTienda ? 1 : -1))
            .map((a) => {
              return {
                _id: a._id,
                numero: a.idVentaTienda,
                consecutivo: a.prefijo + a.consecutivo,
                activo: a.is_active,
                fecha: a.fecha,
                // colaboradores:
                //   a.colaboradores[0].nombre + " " + a.colaboradores[0].apellido,
                // idColaboradores: a.colaboradores[0]._id,
                observaciones: a.observaciones,
                subTotal: a.subTotal,
                iva: a.iva,
                total_general: a.total_general,
                clientes: a.clientes[0].nombre_comercial,
                idClientes: a.clientes[0]._id,
                total_cantidad: a.total_cantidad,
                comisionTDC: a.comisionTDC,
                comisionFlete: a.comisionFlete,
                impuestos: a.impuestos,
                area: a.areas[0].name,
                movimiento: "Nota",
                fiscal: a.fiscal,
                condPago: a.cond_pago,
              };
            })
            .filter(function (el) {
              return el != null;
            });

          let data = Object.values(arrayTabla);
          setVentasTiendas(data);
          setSelectedAreaName(data[0].area);

          let totalCredito = 0;
          let totalContado = 0;

          data.forEach((venta) => {
            if (venta.condPago === "Credito") {
              totalCredito += venta.total_general;
            } else if (venta.condPago === "Contado") {
              totalContado += venta.total_general;
            }
          });

          setTotalCredito(totalCredito);
          setTotalContado(totalContado);

          //
        })
        .catch((err) => {
          console.log(err);
        });

      // Abonos
      await axios
        .get(
          `${URL_ABONOS_TIENDAS}AbonosVentasDiario/${e}/${selectedFechaInicio}/${selectedFechaInicio}`,
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then((response) => {
          x = x + 1;
          setContador(x);
          let allVentas = response.data;
          // Array para pagination
          let arrayTabla = allVentas
            .sort((a, b) => (a.idAbonoTiendas > b.idAbonoTiendas ? 1 : -1))
            .map((a) => {
              if (a.concepto == "Abono") {
                if (a.ventasTiendas != "") {
                  if (a.ventasTiendas[0].is_active == "Si") {
                    return {
                      _id: a._id,
                      numero: a.idAbonoTiendas,
                      fecha: a.fecha,
                      fechaTransfer: a.fechaTransfer,
                      // colaboradores: a.ventasTiendas[0].colaboradores[0].nombre + " " + a.ventasTiendas[0].colaboradores[0].apellido,
                      // idColaboradores: a.ventasTiendas[0].colaboradores[0]._id,
                      observaciones: a.ventasTiendas[0].observaciones,
                      clientes: a.clientes[0].nombre_comercial,
                      idClientes: a.clientes[0]._id,
                      condPago: a.ventasTiendas[0].cond_pago,
                      total_general: a.total,
                      efectivo: a.efectivo,
                      tdc: a.tdc,
                      transfer: a.transfer,
                      consecutivo:
                        a.ventasTiendas[0].prefijo +
                        a.ventasTiendas[0].consecutivo,
                      movimiento: "Abono",
                      condPago: a.ventasTiendas[0].cond_pago,
                      numeroNota: a.ventasTiendas[0].idVentaTienda,
                      destino: a.destino,
                      terminal: a.terminal,
                      codigoAgrupado: a.codigoAgrupado,
                      createdAt:
                        moment(a.createdAt)
                          .utcOffset("-0600")
                          .format("DD-MM-YYYY") +
                        " " +
                        moment(a.createdAt).utcOffset("-0600").format("HH:mm"),
                    };
                  }
                } else {
                  return {
                    _id: a._id,
                    numero: a.idAbonoTiendas,
                    fecha: a.fecha,
                    fechaTransfer: a.fechaTransfer,
                    // colaboradores: a.clientes[0].vendedor[0].nombre + " " + a.clientes[0].vendedor[0].apellido,
                    // idColaboradores: a.clientes[0].vendedor[0]._id,
                    // observaciones: a.notasCargo[0].observaciones,
                    clientes: a.clientes[0].nombre_comercial,
                    idClientes: a.clientes[0]._id,
                    total_general: a.total,
                    efectivo: a.efectivo,
                    tdc: a.tdc,
                    transfer: a.transfer,
                    consecutivo: "NC" + " " + a.notasCargo[0].idNotaCargo,
                    movimiento: "Abono",
                    condPago: "Credito",
                    numeroNota: "NC" + " " + a.notasCargo[0].idNotaCargo,
                    destino: a.destino,
                    terminal: a.terminal,
                    codigoAgrupado: a.codigoAgrupado,
                    createdAt:
                      moment(a.createdAt)
                        .utcOffset("-0600")
                        .format("DD-MM-YYYY") +
                      " " +
                      moment(a.createdAt).utcOffset("-0600").format("HH:mm"),
                  };
                }
              }
            })
            .filter(function (el) {
              return el != null;
            });

          let data = Object.values(arrayTabla);

          let abonosContado = 0;
          data.forEach(venta => {
            if (venta.condPago === 'Contado') {
              abonosContado += venta.total_general;
            }
          });
          setTotalAbonosContado(abonosContado);

          let agrupado = data.reduce(function (groups, item) {
            const val = item["codigoAgrupado"];
            groups[val] = groups[val] || {
              codigoAgrupado: item.codigoAgrupado,
              createdAt: item.createdAt,
              efectivo: 0,
              tdc: 0,
              transfer: 0,
              total_general: 0,
            };
            groups[val].tdc += item.tdc;
            groups[val].transfer += item.transfer;
            groups[val].efectivo += item.efectivo;
            groups[val].consecutivo = item.consecutivo;
            groups[val].clientes = item.clientes;
            groups[val].total_general += item.total_general;
            groups[val].condPago = item.condPago;
            groups[val].terminal = item.terminal;
            groups[val].destino = item.destino;
            groups[val].fechaTransfer = item.fechaTransfer;
            return groups;
          }, []);

          let agrupadoValues = Object.values(data);

          setAbonosTiendas(agrupadoValues);
          //
        })
        .catch((err) => {
          console.log(err);
        });

      // Devoluciones
      await axios
        .get(
          `${URL_DEVOLUCIONES_TIENDAS}DevolucionesDiario/${e}/${selectedFechaInicio}/${selectedFechaInicio}`,
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then((response) => {
          x = x + 1;
          setContador(x);
          let allDevoluciones = response.data;
          // Array para pagination
          let arrayTabla = allDevoluciones
            .sort((a, b) =>
              a.idDevolucionTienda > b.idDevolucionTienda ? 1 : -1
            )
            .map((a) => {
              return {
                _id: a._id,
                numero: a.idDevolucionTienda,
                activo: a.is_active,
                fecha: a.fecha,
                // colaboradores:
                //   a.colaboradores[0].nombre + " " + a.colaboradores[0].apellido,
                // idColaboradores: a.colaboradores[0]._id,
                observaciones: a.observaciones,
                subTotal: a.subTotal,
                iva: a.iva,
                total_general: a.total_general,
                clientes: a.clientes[0].nombre_comercial,
                idClientes: a.clientes[0]._id,
                total_cantidad: a.total_cantidad,
                impuestos: a.impuestos,
                area: a.areas[0].name,
                movimiento: "Devolucion",
                fiscal: a.fiscal,
              };
            })
            .filter(function (el) {
              return el != null;
            });

          let data = Object.values(arrayTabla);
          setDevolucionesTiendas(data);
          //
        })
        .catch((err) => {
          console.log(err);
        });

      // Gastos
      await axios
        .get(
          `${URL_GASTOS_TIENDAS}GastosDiario/${e}/${selectedFechaInicio}/${selectedFechaInicio}`,
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then((response) => {
          x = x + 1;
          setContador(x);
          let allGastos = response.data;
          let arrayTabla = allGastos
            .sort((a, b) => (a.fecha < b.fecha ? 1 : -1))
            .map((a) => {
              return {
                _id: a._id,
                fecha: a.fecha,
                descripcion: a.descripcion,
                subtotal: a.subtotal,
                iva: a.iva,
                total_general: a.total,
                concepto: a.concepto,
                movimiento: "Gasto",
                numero: a.idGastoTiendas,
              };
            })
            .filter(function (el) {
              return el != null;
            });

          let data = Object.values(arrayTabla);
          setGastos(data);
        })
        .catch((err) => {
          console.log(err);
        });

      // Dineros
      await axios
        .get(
          `${URL_DINEROS_TIENDAS}DinerosDiario/${e}/${selectedFechaInicio}/${selectedFechaInicio}`,
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then((response) => {
          x = x + 1;
          setContador(x);
          let allDineros = response.data;
          let arrayTabla = allDineros
            .sort((a, b) => (a.fecha < b.fecha ? 1 : -1))
            .map((a) => {
              return {
                _id: a._id,
                fecha: a.fecha,
                fechaTransfer: a.fechaTransfer,
                total_general: a.total,
                efectivo: a.efectivo,
                tdc: a.tdc,
                transfer: a.transfer,
                observaciones: a.observaciones,
                movimiento: "Entrada Dinero",
                numero: a.idDineroTiendas,
              };
            })
            .filter(function (el) {
              return el != null;
            });

          let data = Object.values(arrayTabla);
          setDineros(data);
        })
        .catch((err) => {
          console.log(err);
        });
      // Terminamos de Jalar Info
    }
  }

  function PDFCorte() {
    // Empezamos PDF

    let temp = totalContado - totalAbonosContado;
    if(temp != 0){
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Existen Notas de Contado sin pagar",
      })  
      return
    }

    // Ventas Credito
    let TotalTablaVentasCredito = 0;
    let TotalPiezasVentasCredito = 0;
    const ventasCredito = ventasTiendas.map((a) => {
      if (a.condPago == "Credito") {
        TotalTablaVentasCredito = TotalTablaVentasCredito + a.total_general;
        TotalPiezasVentasCredito = TotalPiezasVentasCredito + a.total_cantidad;
        return [
          a.consecutivo,
          a.clientes,
          a.total_cantidad,
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(a.total_general),
        ];
      }
    });
    const ventasPDFCredito = Object.values(ventasCredito);
    const ventasPDFCreditoLimpia = ventasPDFCredito.filter(function (el) {
      return el != null;
    });

    // Devoluciones Contado
    let TotalTablaDevoluciones = 0;
    let TotalPiezasDevoluciones = 0;
    const devoluciones = devolucionesTiendas.map((a) => {
      // if (a. == "Si") {
      TotalTablaDevoluciones = TotalTablaDevoluciones + a.total_general;
      TotalPiezasDevoluciones = TotalPiezasDevoluciones + a.total_cantidad;
      return [
        a.numero,
        a.total_cantidad,
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          maximumFractionDigits: 2,
        }).format(a.total_general),
      ];
      // }
    });
    const devolucionesPDF = Object.values(devoluciones);
    const devolucionesPDFLimpia = devolucionesPDF.filter(function (el) {
      return el != null;
    });

    // Abonos Credito
    let TotalTablaAbonosCreditoEfectivo = 0;
    let TotalTablaAbonosCreditoTDC = 0;
    let TotalTablaAbonosCreditoTransfer = 0;
    let TotalTablaAbonosCreditoTotal = 0;

    const abonosCredito = abonosTiendas.map((a) => {
      if (a.condPago == "Credito") {
        TotalTablaAbonosCreditoEfectivo =
          TotalTablaAbonosCreditoEfectivo + a.efectivo;
        TotalTablaAbonosCreditoTDC = TotalTablaAbonosCreditoTDC + a.tdc;
        TotalTablaAbonosCreditoTransfer =
          TotalTablaAbonosCreditoTransfer + a.transfer;
        TotalTablaAbonosCreditoTotal =
          TotalTablaAbonosCreditoTotal + a.total_general;

        return [
          a.consecutivo,
          a.clientes,
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(a.efectivo),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(a.tdc),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(a.transfer),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(a.total_general),
        ];
      }
    });
    const abonosPDFCredito = Object.values(abonosCredito);
    const abonosPDFCreditoLimpia = abonosPDFCredito.filter(function (el) {
      return el != null;
    });

    // Dinero
    let TotalTablaDineroEfectivo = 0;
    let TotalTablaDineroTDC = 0;
    let TotalTablaDineroTransfer = 0;
    let TotalTablaDineroTotal = 0;

    const dinero = dineros.map((a) => {
      TotalTablaDineroEfectivo = TotalTablaDineroEfectivo + a.efectivo;
      TotalTablaDineroTDC = TotalTablaDineroTDC + a.tdc;
      TotalTablaDineroTransfer = TotalTablaDineroTransfer + a.transfer;
      TotalTablaDineroTotal = TotalTablaDineroTotal + a.total_general;

      return [
        a.numero,
        a.observaciones,
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          maximumFractionDigits: 2,
        }).format(a.efectivo),
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          maximumFractionDigits: 2,
        }).format(a.tdc),
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          maximumFractionDigits: 2,
        }).format(a.transfer),
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          maximumFractionDigits: 2,
        }).format(a.total_general),
      ];
    });
    const dinerosPDF = Object.values(dinero);
    const dinerosPDFLimpia = dinerosPDF.filter(function (el) {
      return el != null;
    });

    // Abonos Contado
    let TotalTablaAbonosContadoEfectivo = 0;
    let TotalTablaAbonosContadoTDC = 0;
    let TotalTablaAbonosContadoTransfer = 0;
    let TotalTablaAbonosContadoTotal = 0;

    const abonosContado = abonosTiendas.map((a) => {
      if (a.condPago == "Contado") {
        TotalTablaAbonosContadoEfectivo =
          TotalTablaAbonosContadoEfectivo + a.efectivo;
        TotalTablaAbonosContadoTDC = TotalTablaAbonosContadoTDC + a.tdc;
        TotalTablaAbonosContadoTransfer =
          TotalTablaAbonosContadoTransfer + a.transfer;
        TotalTablaAbonosContadoTotal =
          TotalTablaAbonosContadoTotal + a.total_general;

        return [
          a.consecutivo,
          a.clientes,
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(a.efectivo),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(a.tdc),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(a.transfer),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(a.total_general),
        ];
      }
    });
    const abonosPDFContado = Object.values(abonosContado);

    const abonosPDFContadoLimpia = abonosPDFContado.filter(function (el) {
      return el != null;
    });

    // Abonos TDC
    const abonosTDC = abonosTiendas
      .map((a) => {
        if (a.tdc > 0) {
          return {
            importe: a.tdc,
            terminal: a.terminal,
          };
        }
      })
      .filter(function (el) {
        return el != null;
      });

    const dataAbonosTDC = Object.values(abonosTDC);

    const dinerosTDC = dineros
      .map((a) => {
        if (a.tdc > 0) {
          return {
            importe: a.tdc,
            terminal: a.terminal,
          };
        }
      })
      .filter(function (el) {
        return el != null;
      });

    const dataDinerosTDC = Object.values(dinerosTDC);

    const totalTDC = dataAbonosTDC.concat(dataDinerosTDC);

    setAbonosTDCTiendas(totalTDC);

    let abonosTDCAgrupado = totalTDC.reduce(function (groups, item) {
      const val = item["terminal"];
      groups[val] = groups[val] || {
        terminal: item.terminal,
        importe: 0,
      };
      groups[val].importe += item.importe;
      groups[val].terminal = item.terminal;
      return groups;
    }, []);

    let abonosTDCAgrupado2 = Object.values(abonosTDCAgrupado);

    const abnTDCPDF = abonosTDCAgrupado2.map((a) => {
      return [
        a.terminal,
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          maximumFractionDigits: 2,
        }).format(a.importe),
      ];
    });
    const abonosTDCPDF = Object.values(abnTDCPDF);
    const abonosTDCPDFLimpia = abonosTDCPDF.filter(function (el) {
      return el != null;
    });

    // Abonos Transfer
    const abonosTransfer = abonosTiendas
      .map((a) => {
        if (a.transfer > 0) {
          return {
            importe: a.transfer,
            destino: a.destino,
          };
        }
      })
      .filter(function (el) {
        return el != null;
      });

    let dataAbonosTransfer = Object.values(abonosTransfer);

    const dinerosTransfer = dineros
      .map((a) => {
        if (a.transfer > 0) {
          return {
            importe: a.transfer,
            destino: a.destino,
          };
        }
      })
      .filter(function (el) {
        return el != null;
      });

    let dataDinerosTransfer = Object.values(dinerosTransfer);

    const totalTransfer = dataAbonosTransfer.concat(dataDinerosTransfer);
    // Agrupado Transfer
    let abonosTransferAgrupado = totalTransfer.reduce(function (groups, item) {
      const val = item["destino"];
      groups[val] = groups[val] || {
        destino: item.destino,
        importe: 0,
      };
      groups[val].importe += item.importe;
      groups[val].destino = item.destino;
      return groups;
    }, []);

    let abonosTransferAgrupado2 = Object.values(abonosTransferAgrupado);

    // setAbonosTransferTiendas(abonosTransferAgrupado2);

    const abnTransferPDF = abonosTransferAgrupado2.map((a) => {
      return [
        a.destino,
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          maximumFractionDigits: 2,
        }).format(a.importe),
      ];
    });
    const abonosTransferPDF = Object.values(abnTransferPDF);
    const abonosTransferPDFLimpia = abonosTransferPDF.filter(function (el) {
      return el != null;
    });

    // Desgloce TDC
    let TotalTablaDesgloceTDC = 0;
    let TotalTablaDesgloceTDCAbonos = 0;
    let TotalTablaDesgloceTDCDin = 0;
    const desgloceTDCAbonos = abonosTiendas
      .map((a) => {
        if (a.tdc > 0) {
          TotalTablaDesgloceTDCAbonos = TotalTablaDesgloceTDCAbonos + a.tdc;
          return [
            a.consecutivo,
            a.clientes,
            a.terminal,
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 2,
            }).format(a.tdc),
          ];
        }
      })
      .filter(function (el) {
        return el != null;
      });
    const desgloceTDCPDF = Object.values(desgloceTDCAbonos);

    const desgloceTDCDin = dineros
      .map((a) => {
        if (a.tdc > 0) {
          TotalTablaDesgloceTDCDin = TotalTablaDesgloceTDCDin + a.tdc;
          return [
            a.numero,
            a.observaciones,
            a.terminal,
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 2,
            }).format(a.tdc),
          ];
        }
      })
      .filter(function (el) {
        return el != null;
      });
    const desgloceTDCDinPDF = Object.values(desgloceTDCDin);

    const totalDesgloceTDC = desgloceTDCPDF.concat(desgloceTDCDinPDF);

    const desgloceTDCPDFLimpia = totalDesgloceTDC.filter(function (el) {
      return el != null;
    });

    // Desgloce Transfer
    let TotalTablaDesgloceTransfer = 0;
    let TotalTablaDesgloceTransferAbonos = 0;
    let TotalTablaDesgloceTransferDin = 0;
    const desgloceTransferAbonos = abonosTiendas
      .map((a) => {
        if (a.transfer > 0) {
          TotalTablaDesgloceTransferAbonos =
            TotalTablaDesgloceTransferAbonos + a.transfer;
          return [
            a.consecutivo,
            a.clientes,
            a.destino,
            a.fechaTransfer,
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 2,
            }).format(a.transfer),
          ];
        }
      })
      .filter(function (el) {
        return el != null;
      });
    const desgloceTransferPDF = Object.values(desgloceTransferAbonos);

    const desgloceTransferDin = dineros
      .map((a) => {
        if (a.transfer > 0) {
          TotalTablaDesgloceTransferDin =
            TotalTablaDesgloceTransferDin + a.transfer;
          return [
            a.numero,
            a.observaciones,
            a.destino,
            a.fechaTransfer,
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 2,
            }).format(a.transfer),
          ];
        }
      })
      .filter(function (el) {
        return el != null;
      });
    const desgloceTransferDinPDF = Object.values(desgloceTransferDin);

    const totalDesgloceTransfer = desgloceTransferPDF.concat(
      desgloceTransferDinPDF
    );

    const desgloceTransferPDFLimpia = totalDesgloceTransfer.filter(function (
      el
    ) {
      return el != null;
    });

    // Gastos
    let TotalTablaGastos = 0;

    const gastosTiendas = gastos.map((a) => {
      TotalTablaGastos = TotalTablaGastos + a.total_general;

      return [
        a.numero,
        a.concepto,
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          maximumFractionDigits: 2,
        }).format(a.total_general),
      ];
    });
    const gastosPDFContado = Object.values(gastosTiendas);
    const gastosPDFContadoLimpia = gastosPDFContado.filter(function (el) {
      return el != null;
    });

    // Totales
    let TotalEfectivo =
      TotalTablaAbonosCreditoEfectivo +
      TotalTablaAbonosContadoEfectivo +
      TotalTablaDineroEfectivo -
      TotalTablaGastos;
    let TotalTDC =
      TotalTablaAbonosCreditoTDC +
      TotalTablaAbonosContadoTDC +
      TotalTablaDineroTDC;
    let TotalTransfer =
      TotalTablaAbonosCreditoTransfer +
      TotalTablaAbonosContadoTransfer +
      TotalTablaDineroTransfer;
    let TotalTotal =
      TotalTablaAbonosCreditoTotal +
      TotalTablaAbonosContadoTotal +
      TotalTablaDineroTotal;

    // let TotalQuedaEfectivo = TotalEfectivo  - TotalTablaGastos;

    const doc = new jsPDF({ compressPdf: true });
    var img = new Image();
    // img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    // doc.addImage(img, "png", 230, 30, 45, 15);
    // var img2 = new Image();
    // img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    // doc.addImage(img2, "png", 140, 15, 25, 25);
    doc.text(
      `Corte de Tienda ${selectedAreaName} - ${selectedFechaInicio}`,
      15,
      10
    );

    // Abonos Contado
    doc.text(`Ventas Contado`, 15, 18);
    doc.autoTable({
      head: [["Numero", "Cliente", "Efectivo", "TDC", "Transfer", "Total"]],
      body: abonosPDFContadoLimpia,
      startY: 20,
      foot: [
        [
          "",
          "Totales",
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(TotalTablaAbonosContadoEfectivo),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(TotalTablaAbonosContadoTDC),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(TotalTablaAbonosContadoTransfer),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(TotalTablaAbonosContadoTotal),
        ],
      ],
    });

    // Ventas Credito
    doc.text(`Ventas Credito`, 15, doc.autoTable.previous.finalY + 10);
    doc.autoTable({
      head: [["Numero", "Cliente", "Cantidad", "Total"]],
      body: ventasPDFCreditoLimpia,
      startY: doc.autoTable.previous.finalY + 13,
      foot: [
        [
          "",
          "Totales",
          new Intl.NumberFormat("en-US").format(TotalPiezasVentasCredito),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(TotalTablaVentasCredito),
        ],
      ],
    });

    // Abonos Credito
    doc.text(`Pagos Credito`, 15, doc.autoTable.previous.finalY + 10);
    doc.autoTable({
      head: [["Numero", "Cliente", "Efectivo", "TDC", "Transfer", "Total"]],
      body: abonosPDFCreditoLimpia,
      startY: doc.autoTable.previous.finalY + 13,
      foot: [
        [
          "",
          "Totales",
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(TotalTablaAbonosCreditoEfectivo),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(TotalTablaAbonosCreditoTDC),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(TotalTablaAbonosCreditoTransfer),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(TotalTablaAbonosCreditoTotal),
        ],
      ],
    });

    // Dinero
    doc.text(`Entrada Dinero`, 15, doc.autoTable.previous.finalY + 10);
    doc.autoTable({
      head: [
        ["Numero", "Observaciones", "Efectivo", "TDC", "Transfer", "Total"],
      ],
      body: dinerosPDFLimpia,
      startY: doc.autoTable.previous.finalY + 13,
      foot: [
        [
          "",
          "Totales",
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(TotalTablaDineroEfectivo),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(TotalTablaDineroTDC),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(TotalTablaDineroTransfer),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(TotalTablaDineroTotal),
        ],
      ],
    });

    // Devoluciones Contado
    doc.text(`Devoluciones`, 15, doc.autoTable.previous.finalY + 10);
    doc.autoTable({
      head: [["Numero", "Cantidad", "Total"]],
      body: devolucionesPDFLimpia,
      startY: doc.autoTable.previous.finalY + 13,
      foot: [
        [
          "Totales",
          new Intl.NumberFormat("en-US").format(TotalPiezasDevoluciones),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(TotalTablaDevoluciones),
        ],
      ],
    });

    // Gastos
    doc.text(`Gastos`, 15, doc.autoTable.previous.finalY + 10);
    doc.autoTable({
      head: [["Numero", "Concepto", "Total"]],
      body: gastosPDFContadoLimpia,
      startY: doc.autoTable.previous.finalY + 13,
      foot: [
        [
          "",
          "Totales",
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(TotalTablaGastos),
        ],
      ],
    });

    // Resumen
    doc.text(`Resumen`, 15, doc.autoTable.previous.finalY + 10);
    doc.autoTable({
      head: [
        [
          "Efectivo",
          "TDC",
          "Transfer",
          "Total Total",
          // "Efectivo - Gastos",
        ],
      ],
      body: [
        [
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(TotalEfectivo),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(TotalTDC),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(TotalTransfer),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(TotalEfectivo + TotalTDC + TotalTransfer),
          // new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalQuedaEfectivo),
        ],
      ],
      startY: doc.autoTable.previous.finalY + 13,
    });

    // Desgloce TDC
    doc.text(`Desgloce TDC`, 15, doc.autoTable.previous.finalY + 10);
    doc.autoTable({
      head: [["Numero", "Cliente", "Terminal", "Importe"]],
      body: desgloceTDCPDFLimpia,
      startY: doc.autoTable.previous.finalY + 13,
    });

    // Resumen TDC
    doc.text(`Resumen TDC`, 15, doc.autoTable.previous.finalY + 10);
    doc.autoTable({
      head: [["Terminal", "Importe"]],
      body: abonosTDCPDFLimpia,
      startY: doc.autoTable.previous.finalY + 13,
    });

    // Desgloce Transfer
    doc.text(`Desgloce Transfer`, 15, doc.autoTable.previous.finalY + 10);
    doc.autoTable({
      head: [["Numero", "Cliente", "Destino", "FechaTransfer", "Importe"]],
      body: desgloceTransferPDFLimpia,
      startY: doc.autoTable.previous.finalY + 13,
    });

    // Resumen Transfer
    doc.text(`Resumen Transfer`, 15, doc.autoTable.previous.finalY + 10);
    doc.autoTable({
      head: [["Destino", "Importe"]],
      body: abonosTransferPDFLimpia,
      startY: doc.autoTable.previous.finalY + 13,
    });

    doc.save(`CorteTienda-${selectedAreaName}-${selectedFechaInicio}.pdf`);
  }

  function PDFTicketCorte() {
    // Empezamos PDF

    let temp = totalContado - totalAbonosContado;
    if(temp != 0){
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Existen Notas de Contado sin pagar",
      })  
      return
    }

    // Ventas Credito
    let TotalTablaVentasCredito = 0;
    let TotalPiezasVentasCredito = 0;
    const ventasCredito = ventasTiendas.map((a) => {
      if (a.condPago == "Credito") {
        TotalTablaVentasCredito = TotalTablaVentasCredito + a.total_general;
        TotalPiezasVentasCredito = TotalPiezasVentasCredito + a.total_cantidad;
        return [
          a.consecutivo,
          a.clientes,
          a.total_cantidad,
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(a.total_general),
        ];
      }
    });
    const ventasPDFCredito = Object.values(ventasCredito);
    const ventasPDFCreditoLimpia = ventasPDFCredito.filter(function (el) {
      return el != null;
    });

    // Ventas Contado
    let TotalTablaVentasContado = 0;
    let TotalPiezasVentasContado = 0;
    const ventasContado = ventasTiendas.map((a) => {
      if (a.condPago == "Contado") {
        TotalTablaVentasContado = TotalTablaVentasContado + a.total_general;
        TotalPiezasVentasContado = TotalPiezasVentasContado + a.total_cantidad;
        return [
          a.consecutivo,
          a.clientes,
          a.total_cantidad,
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(a.total_general),
        ];
      }
    });
    const ventasPDFContado = Object.values(ventasContado);
    const ventasPDFContadoLimpia = ventasPDFContado.filter(function (el) {
      return el != null;
    });

    // Devoluciones Contado
    let TotalTablaDevoluciones = 0;
    let TotalPiezasDevoluciones = 0;
    const devoluciones = devolucionesTiendas.map((a) => {
      // if (a. == "Si") {
      TotalTablaDevoluciones = TotalTablaDevoluciones + a.total_general;
      TotalPiezasDevoluciones = TotalPiezasDevoluciones + a.total_cantidad;
      return [
        a.numero,
        a.total_cantidad,
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          maximumFractionDigits: 2,
        }).format(a.total_general),
      ];
      // }
    });
    const devolucionesPDF = Object.values(devoluciones);
    const devolucionesPDFLimpia = devolucionesPDF.filter(function (el) {
      return el != null;
    });

    // Abonos Credito
    let TotalTablaAbonosCreditoEfectivo = 0;
    let TotalTablaAbonosCreditoTDC = 0;
    let TotalTablaAbonosCreditoTransfer = 0;
    let TotalTablaAbonosCreditoTotal = 0;

    const abonosCredito = abonosTiendas.map((a) => {
      if (a.condPago == "Credito") {
        TotalTablaAbonosCreditoEfectivo =
          TotalTablaAbonosCreditoEfectivo + a.efectivo;
        TotalTablaAbonosCreditoTDC = TotalTablaAbonosCreditoTDC + a.tdc;
        TotalTablaAbonosCreditoTransfer =
          TotalTablaAbonosCreditoTransfer + a.transfer;
        TotalTablaAbonosCreditoTotal =
          TotalTablaAbonosCreditoTotal + a.total_general;

        return [
          a.clientes,
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(a.efectivo),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(a.tdc),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(a.transfer),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(a.total_general),
        ];
      }
    });
    const abonosPDFCredito = Object.values(abonosCredito);
    const abonosPDFCreditoLimpia = abonosPDFCredito.filter(function (el) {
      return el != null;
    });

    // Dinero
    let TotalTablaDineroEfectivo = 0;
    let TotalTablaDineroTDC = 0;
    let TotalTablaDineroTransfer = 0;
    let TotalTablaDineroTotal = 0;

    const dinero = dineros.map((a) => {
      TotalTablaDineroEfectivo = TotalTablaDineroEfectivo + a.efectivo;
      TotalTablaDineroTDC = TotalTablaDineroTDC + a.tdc;
      TotalTablaDineroTransfer = TotalTablaDineroTransfer + a.transfer;
      TotalTablaDineroTotal = TotalTablaDineroTotal + a.total_general;

      return [
        a.numero,
        a.observaciones,
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          maximumFractionDigits: 2,
        }).format(a.efectivo),
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          maximumFractionDigits: 2,
        }).format(a.tdc),
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          maximumFractionDigits: 2,
        }).format(a.transfer),
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          maximumFractionDigits: 2,
        }).format(a.total_general),
      ];
    });
    const dinerosPDF = Object.values(dinero);
    const dinerosPDFLimpia = dinerosPDF.filter(function (el) {
      return el != null;
    });

    // Abonos Contado
    let TotalTablaAbonosContadoEfectivo = 0;
    let TotalTablaAbonosContadoTDC = 0;
    let TotalTablaAbonosContadoTransfer = 0;
    let TotalTablaAbonosContadoTotal = 0;

    const abonosContado = abonosTiendas.map((a) => {
      if (a.condPago == "Contado") {
        TotalTablaAbonosContadoEfectivo =
          TotalTablaAbonosContadoEfectivo + a.efectivo;
        TotalTablaAbonosContadoTDC = TotalTablaAbonosContadoTDC + a.tdc;
        TotalTablaAbonosContadoTransfer =
          TotalTablaAbonosContadoTransfer + a.transfer;
        TotalTablaAbonosContadoTotal =
          TotalTablaAbonosContadoTotal + a.total_general;

        return [
          a.consecutivo,
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(a.efectivo),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(a.tdc),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(a.transfer),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(a.total_general),
        ];
      }
    });
    const abonosPDFContado = Object.values(abonosContado);
    const abonosPDFContadoLimpia = abonosPDFContado.filter(function (el) {
      return el != null;
    });

    // Abonos TDC
    const abonosTDC = abonosTiendas
      .map((a) => {
        if (a.tdc > 0) {
          return {
            importe: a.tdc,
            terminal: a.terminal,
          };
        }
      })
      .filter(function (el) {
        return el != null;
      });

    const dataAbonosTDC = Object.values(abonosTDC);

    const dinerosTDC = dineros
      .map((a) => {
        if (a.tdc > 0) {
          return {
            importe: a.tdc,
            terminal: a.terminal,
          };
        }
      })
      .filter(function (el) {
        return el != null;
      });

    const dataDinerosTDC = Object.values(dinerosTDC);

    const totalTDC = dataAbonosTDC.concat(dataDinerosTDC);

    setAbonosTDCTiendas(totalTDC);

    let abonosTDCAgrupado = totalTDC.reduce(function (groups, item) {
      const val = item["terminal"];
      groups[val] = groups[val] || {
        terminal: item.terminal,
        importe: 0,
      };
      groups[val].importe += item.importe;
      groups[val].terminal = item.terminal;
      return groups;
    }, []);

    let abonosTDCAgrupado2 = Object.values(abonosTDCAgrupado);

    const abnTDCPDF = abonosTDCAgrupado2.map((a) => {
      return [
        a.terminal,
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          maximumFractionDigits: 2,
        }).format(a.importe),
      ];
    });
    const abonosTDCPDF = Object.values(abnTDCPDF);
    const abonosTDCPDFLimpia = abonosTDCPDF.filter(function (el) {
      return el != null;
    });

    // Abonos Transfer
    const abonosTransfer = abonosTiendas
      .map((a) => {
        if (a.transfer > 0) {
          return {
            importe: a.transfer,
            destino: a.destino,
          };
        }
      })
      .filter(function (el) {
        return el != null;
      });

    let dataAbonosTransfer = Object.values(abonosTransfer);

    const dinerosTransfer = dineros
      .map((a) => {
        if (a.transfer > 0) {
          return {
            importe: a.transfer,
            destino: a.destino,
          };
        }
      })
      .filter(function (el) {
        return el != null;
      });

    let dataDinerosTransfer = Object.values(dinerosTransfer);

    const totalTransfer = dataAbonosTransfer.concat(dataDinerosTransfer);
    // Agrupado Transfer
    let abonosTransferAgrupado = totalTransfer.reduce(function (groups, item) {
      const val = item["destino"];
      groups[val] = groups[val] || {
        destino: item.destino,
        importe: 0,
      };
      groups[val].importe += item.importe;
      groups[val].destino = item.destino;
      return groups;
    }, []);

    let abonosTransferAgrupado2 = Object.values(abonosTransferAgrupado);

    // setAbonosTransferTiendas(abonosTransferAgrupado2);

    const abnTransferPDF = abonosTransferAgrupado2.map((a) => {
      return [
        a.destino,
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          maximumFractionDigits: 2,
        }).format(a.importe),
      ];
    });
    const abonosTransferPDF = Object.values(abnTransferPDF);
    const abonosTransferPDFLimpia = abonosTransferPDF.filter(function (el) {
      return el != null;
    });

    // Desgloce TDC
    let TotalTablaDesgloceTDC = 0;
    let TotalTablaDesgloceTDCAbonos = 0;
    let TotalTablaDesgloceTDCDin = 0;
    const desgloceTDCAbonos = abonosTiendas
      .map((a) => {
        if (a.tdc > 0) {
          TotalTablaDesgloceTDCAbonos = TotalTablaDesgloceTDCAbonos + a.tdc;
          return [
            a.consecutivo,
            a.clientes,
            a.terminal,
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 2,
            }).format(a.tdc),
          ];
        }
      })
      .filter(function (el) {
        return el != null;
      });
    const desgloceTDCPDF = Object.values(desgloceTDCAbonos);

    const desgloceTDCDin = dineros
      .map((a) => {
        if (a.tdc > 0) {
          TotalTablaDesgloceTDCDin = TotalTablaDesgloceTDCDin + a.tdc;
          return [
            a.numero,
            a.observaciones,
            a.terminal,
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 2,
            }).format(a.tdc),
          ];
        }
      })
      .filter(function (el) {
        return el != null;
      });
    const desgloceTDCDinPDF = Object.values(desgloceTDCDin);

    const totalDesgloceTDC = desgloceTDCPDF.concat(desgloceTDCDinPDF);

    const desgloceTDCPDFLimpia = totalDesgloceTDC.filter(function (el) {
      return el != null;
    });

    // Desgloce Transfer
    let TotalTablaDesgloceTransfer = 0;
    let TotalTablaDesgloceTransferAbonos = 0;
    let TotalTablaDesgloceTransferDin = 0;
    const desgloceTransferAbonos = abonosTiendas
      .map((a) => {
        if (a.transfer > 0) {
          TotalTablaDesgloceTransferAbonos =
            TotalTablaDesgloceTransferAbonos + a.transfer;
          return [
            a.consecutivo,
            a.clientes,
            a.destino,
            a.fechaTransfer,
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 2,
            }).format(a.transfer),
          ];
        }
      })
      .filter(function (el) {
        return el != null;
      });
    const desgloceTransferPDF = Object.values(desgloceTransferAbonos);

    const desgloceTransferDin = dineros
      .map((a) => {
        if (a.transfer > 0) {
          TotalTablaDesgloceTransferDin =
            TotalTablaDesgloceTransferDin + a.transfer;
          return [
            a.numero,
            a.observaciones,
            a.destino,
            a.fechaTransfer,
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 2,
            }).format(a.transfer),
          ];
        }
      })
      .filter(function (el) {
        return el != null;
      });
    const desgloceTransferDinPDF = Object.values(desgloceTransferDin);

    const totalDesgloceTransfer = desgloceTransferPDF.concat(
      desgloceTransferDinPDF
    );

    const desgloceTransferPDFLimpia = totalDesgloceTransfer.filter(function (
      el
    ) {
      return el != null;
    });

    // Gastos
    let TotalTablaGastos = 0;

    const gastosTiendas = gastos.map((a) => {
      TotalTablaGastos = TotalTablaGastos + a.total_general;

      return [
        a.numero,
        a.concepto,
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          maximumFractionDigits: 2,
        }).format(a.total_general),
      ];
    });
    const gastosPDFContado = Object.values(gastosTiendas);
    const gastosPDFContadoLimpia = gastosPDFContado.filter(function (el) {
      return el != null;
    });

    let TotalEfectivo =
      TotalTablaAbonosCreditoEfectivo +
      TotalTablaAbonosContadoEfectivo +
      TotalTablaDineroEfectivo -
      TotalTablaGastos;
    let TotalTDC =
      TotalTablaAbonosCreditoTDC +
      TotalTablaAbonosContadoTDC +
      TotalTablaDineroTDC;
    let TotalTransfer =
      TotalTablaAbonosCreditoTransfer +
      TotalTablaAbonosContadoTransfer +
      TotalTablaDineroTransfer;
    let TotalTotal =
      TotalTablaAbonosCreditoTotal +
      TotalTablaAbonosContadoTotal +
      TotalTablaDineroTotal;

    // let TotalQuedaEfectivo = TotalEfectivo  - TotalTablaGastos;

    let LA =
      (abonosPDFContadoLimpia.length +
        ventasPDFCreditoLimpia.length +
        abonosPDFCreditoLimpia.length +
        dinerosPDFLimpia.length +
        devolucionesPDFLimpia.length +
        gastosPDFContadoLimpia.length +
        desgloceTDCPDFLimpia.length +
        abonosTDCPDFLimpia.length +
        desgloceTransferPDFLimpia.length +
        abonosTransferPDFLimpia.length) *
      3;
    let largoTotal = LA + 20;

    const doc = new jsPDF("p", "cm", [8, largoTotal], true);
    var img = new Image();
    // img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    // doc.addImage(img, "png", 230, 30, 45, 15);
    // var img2 = new Image();
    // img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    // doc.addImage(img2, "png", 140, 15, 25, 25);
    doc.setFontSize(7).setFont(undefined, "bold");
    doc.text(
      `Corte de Tienda ${selectedAreaName} - ${selectedFechaInicio}`,
      0.3,
      1
    );

    // Ventas Contado
    doc.text(`Ventas Contado`, 0.3, 1.6);
    doc.autoTable({
      head: [["Numero", "Efectivo", "TDC", "Transfer", "Total"]],
      body: abonosPDFContadoLimpia,
      font: "times",
      styles: {
        fontSize: 6,
        fontStyle: "bold",
      },
      theme: "plain",
      margin: { left: 0.3, right: 0.7 },
      startY: 1.7,
      foot: [
        [
          "Total",
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(TotalTablaAbonosContadoEfectivo),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(TotalTablaAbonosContadoTDC),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(TotalTablaAbonosContadoTransfer),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(TotalTablaAbonosContadoTotal),
        ],
      ],
    });

    // Ventas Credito
    doc.text(`Ventas Credito`, 0.3, doc.autoTable.previous.finalY + 1);
    doc.autoTable({
      head: [["Numero", "Cliente", "Cantidad", "Total"]],
      body: ventasPDFCreditoLimpia,
      font: "times",
      styles: {
        fontSize: 6,
        fontStyle: "bold",
      },
      theme: "plain",
      margin: { left: 0.3, right: 0.7 },
      startY: doc.autoTable.previous.finalY + 1,
      foot: [
        [
          "",
          "Totales",
          new Intl.NumberFormat("en-US").format(TotalPiezasVentasCredito),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(TotalTablaVentasCredito),
        ],
      ],
    });

    // Abonos Credito
    doc.text(`Pagos Credito`, 0.3, doc.autoTable.previous.finalY + 1);
    doc.autoTable({
      head: [["Cliente", "Efectivo", "TDC", "Transfer", "Total"]],
      body: abonosPDFCreditoLimpia,
      font: "times",
      styles: {
        fontSize: 6,
        fontStyle: "bold",
      },
      theme: "plain",
      margin: { left: 0.3, right: 0.7 },
      startY: doc.autoTable.previous.finalY + 1,
      foot: [
        [
          "Total",
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(TotalTablaAbonosCreditoEfectivo),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(TotalTablaAbonosCreditoTDC),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(TotalTablaAbonosCreditoTransfer),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(TotalTablaAbonosCreditoTotal),
        ],
      ],
    });

    // Dinero
    doc.text(`Entrada Dinero`, 0.3, doc.autoTable.previous.finalY + 1);
    doc.autoTable({
      head: [["#", "Observaciones", "Efectivo", "TDC", "Transfer", "Total"]],
      body: dinerosPDFLimpia,
      font: "times",
      styles: {
        fontSize: 6,
        fontStyle: "bold",
      },
      theme: "plain",
      margin: { left: 0.3, right: 0.7 },
      startY: doc.autoTable.previous.finalY + 1,
      foot: [
        [
          "",
          "Totales",
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(TotalTablaDineroEfectivo),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(TotalTablaDineroTDC),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(TotalTablaDineroTransfer),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(TotalTablaDineroTotal),
        ],
      ],
    });

    // Devoluciones Contado
    doc.text(`Devoluciones`, 0.3, doc.autoTable.previous.finalY + 1);
    doc.autoTable({
      head: [["Numero", "Cantidad", "Total"]],
      body: devolucionesPDFLimpia,
      font: "times",
      styles: {
        fontSize: 6,
        fontStyle: "bold",
      },
      theme: "plain",
      margin: { left: 0.3, right: 0.7 },
      startY: doc.autoTable.previous.finalY + 1,
      foot: [
        [
          "Totales",
          new Intl.NumberFormat("en-US").format(TotalPiezasDevoluciones),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(TotalTablaDevoluciones),
        ],
      ],
    });

    // Gastos
    doc.text(`Gastos`, 0.3, doc.autoTable.previous.finalY + 1);
    doc.autoTable({
      head: [["Numero", "Concepto", "Total"]],
      body: gastosPDFContadoLimpia,
      font: "times",
      styles: {
        fontSize: 6,
        fontStyle: "bold",
      },
      theme: "plain",
      margin: { left: 0.3, right: 0.7 },
      startY: doc.autoTable.previous.finalY + 1,
      foot: [
        [
          "",
          "Totales",
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(TotalTablaGastos),
        ],
      ],
    });

    // Desgloce TDC
    doc.text(`Desgloce TDC`, 0.3, doc.autoTable.previous.finalY + 1);
    doc.autoTable({
      head: [["Numero", "Cliente", "Terminal", "Importe"]],
      body: desgloceTDCPDFLimpia,
      styles: {
        fontSize: 6,
      },
      theme: "plain",
      margin: { left: 0.3, right: 0.7 },
      startY: doc.autoTable.previous.finalY + 1,
    });

    // Resumen TDC
    doc.text(`Resumen TDC`, 0.3, doc.autoTable.previous.finalY + 1);
    doc.autoTable({
      head: [["Terminal", "Importe"]],
      body: abonosTDCPDFLimpia,
      font: "times",
      styles: {
        fontSize: 6,
        fontStyle: "bold",
      },
      theme: "plain",
      margin: { left: 0.3, right: 0.7 },
      startY: doc.autoTable.previous.finalY + 1,
    });

    // Desgloce Transfer
    doc.text(`Desgloce Transfer`, 0.3, doc.autoTable.previous.finalY + 1);
    doc.autoTable({
      head: [["Numero", "Cliente", "Destino", "FechaTransfer", "Importe"]],
      body: desgloceTransferPDFLimpia,
      styles: {
        fontSize: 6,
      },
      theme: "plain",
      margin: { left: 0.3, right: 0.7 },
      startY: doc.autoTable.previous.finalY + 1,
    });

    // Resumen Transfer
    doc.text(`Resumen Transfer`, 0.3, doc.autoTable.previous.finalY + 1);
    doc.autoTable({
      head: [["Destino", "Importe"]],
      body: abonosTransferPDFLimpia,
      styles: {
        fontSize: 6,
      },
      theme: "plain",
      margin: { left: 0.3, right: 0.7 },
      startY: doc.autoTable.previous.finalY + 1,
    });

    // Resumen
    doc.text(`Resumen`, 0.3, doc.autoTable.previous.finalY + 1);
    doc.autoTable({
      head: [
        [
          "Efectivo",
          "TDC",
          "Transfer",
          "Total Total",
          // "Efectivo - Gastos",
        ],
      ],
      body: [
        [
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(TotalEfectivo),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(TotalTDC),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(TotalTransfer),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(TotalEfectivo + TotalTDC + TotalTransfer),
          // new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalQuedaEfectivo),
        ],
      ],
      font: "times",
      styles: {
        fontSize: 6,
        fontStyle: "bold",
      },
      theme: "plain",
      margin: { left: 0.3, right: 0.7 },
      startY: doc.autoTable.previous.finalY + 1,
    });

    doc.save(`CorteTienda-${selectedAreaName}-${selectedFechaInicio}.pdf`);
  }

  function EmailCorte() {
    // Empezamos PDF

    let temp = totalContado - totalAbonosContado;
    if(temp != 0){
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Existen Notas de Contado sin pagar",
      })  
      return
    }

    // Ventas Credito
    let TotalTablaVentasCredito = 0;
    let TotalPiezasVentasCredito = 0;
    const ventasCredito = ventasTiendas.map((a) => {
      if (a.condPago == "Credito") {
        TotalTablaVentasCredito = TotalTablaVentasCredito + a.total_general;
        TotalPiezasVentasCredito = TotalPiezasVentasCredito + a.total_cantidad;
        return [
          a.consecutivo,
          a.clientes,
          a.total_cantidad,
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(a.total_general),
        ];
      }
    });
    const ventasPDFCredito = Object.values(ventasCredito);
    const ventasPDFCreditoLimpia = ventasPDFCredito.filter(function (el) {
      return el != null;
    });

    // Devoluciones Contado
    let TotalTablaDevoluciones = 0;
    let TotalPiezasDevoluciones = 0;
    const devoluciones = devolucionesTiendas.map((a) => {
      // if (a. == "Si") {
      TotalTablaDevoluciones = TotalTablaDevoluciones + a.total_general;
      TotalPiezasDevoluciones = TotalPiezasDevoluciones + a.total_cantidad;
      return [
        a.numero,
        a.total_cantidad,
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          maximumFractionDigits: 2,
        }).format(a.total_general),
      ];
      // }
    });
    const devolucionesPDF = Object.values(devoluciones);
    const devolucionesPDFLimpia = devolucionesPDF.filter(function (el) {
      return el != null;
    });

    // Abonos Credito
    let TotalTablaAbonosCreditoEfectivo = 0;
    let TotalTablaAbonosCreditoTDC = 0;
    let TotalTablaAbonosCreditoTransfer = 0;
    let TotalTablaAbonosCreditoTotal = 0;

    const abonosCredito = abonosTiendas.map((a) => {
      if (a.condPago == "Credito") {
        TotalTablaAbonosCreditoEfectivo =
          TotalTablaAbonosCreditoEfectivo + a.efectivo;
        TotalTablaAbonosCreditoTDC = TotalTablaAbonosCreditoTDC + a.tdc;
        TotalTablaAbonosCreditoTransfer =
          TotalTablaAbonosCreditoTransfer + a.transfer;
        TotalTablaAbonosCreditoTotal =
          TotalTablaAbonosCreditoTotal + a.total_general;

        return [
          a.consecutivo,
          a.clientes,
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(a.efectivo),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(a.tdc),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(a.transfer),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(a.total_general),
        ];
      }
    });
    const abonosPDFCredito = Object.values(abonosCredito);
    const abonosPDFCreditoLimpia = abonosPDFCredito.filter(function (el) {
      return el != null;
    });

    // Dinero
    let TotalTablaDineroEfectivo = 0;
    let TotalTablaDineroTDC = 0;
    let TotalTablaDineroTransfer = 0;
    let TotalTablaDineroTotal = 0;

    const dinero = dineros.map((a) => {
      TotalTablaDineroEfectivo = TotalTablaDineroEfectivo + a.efectivo;
      TotalTablaDineroTDC = TotalTablaDineroTDC + a.tdc;
      TotalTablaDineroTransfer = TotalTablaDineroTransfer + a.transfer;
      TotalTablaDineroTotal = TotalTablaDineroTotal + a.total_general;

      return [
        a.numero,
        a.observaciones,
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          maximumFractionDigits: 2,
        }).format(a.efectivo),
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          maximumFractionDigits: 2,
        }).format(a.tdc),
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          maximumFractionDigits: 2,
        }).format(a.transfer),
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          maximumFractionDigits: 2,
        }).format(a.total_general),
      ];
    });
    const dinerosPDF = Object.values(dinero);
    const dinerosPDFLimpia = dinerosPDF.filter(function (el) {
      return el != null;
    });

    // Abonos Contado
    let TotalTablaAbonosContadoEfectivo = 0;
    let TotalTablaAbonosContadoTDC = 0;
    let TotalTablaAbonosContadoTransfer = 0;
    let TotalTablaAbonosContadoTotal = 0;

    const abonosContado = abonosTiendas.map((a) => {
      if (a.condPago == "Contado") {
        TotalTablaAbonosContadoEfectivo =
          TotalTablaAbonosContadoEfectivo + a.efectivo;
        TotalTablaAbonosContadoTDC = TotalTablaAbonosContadoTDC + a.tdc;
        TotalTablaAbonosContadoTransfer =
          TotalTablaAbonosContadoTransfer + a.transfer;
        TotalTablaAbonosContadoTotal =
          TotalTablaAbonosContadoTotal + a.total_general;

        return [
          a.consecutivo,
          a.clientes,
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(a.efectivo),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(a.tdc),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(a.transfer),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(a.total_general),
        ];
      }
    });
    const abonosPDFContado = Object.values(abonosContado);
    const abonosPDFContadoLimpia = abonosPDFContado.filter(function (el) {
      return el != null;
    });

    // Abonos TDC
    const abonosTDC = abonosTiendas
      .map((a) => {
        if (a.tdc > 0) {
          return {
            importe: a.tdc,
            terminal: a.terminal,
          };
        }
      })
      .filter(function (el) {
        return el != null;
      });

    const dataAbonosTDC = Object.values(abonosTDC);

    const dinerosTDC = dineros
      .map((a) => {
        if (a.tdc > 0) {
          return {
            importe: a.tdc,
            terminal: a.terminal,
          };
        }
      })
      .filter(function (el) {
        return el != null;
      });

    const dataDinerosTDC = Object.values(dinerosTDC);

    const totalTDC = dataAbonosTDC.concat(dataDinerosTDC);

    setAbonosTDCTiendas(totalTDC);

    let abonosTDCAgrupado = totalTDC.reduce(function (groups, item) {
      const val = item["terminal"];
      groups[val] = groups[val] || {
        terminal: item.terminal,
        importe: 0,
      };
      groups[val].importe += item.importe;
      groups[val].terminal = item.terminal;
      return groups;
    }, []);

    let abonosTDCAgrupado2 = Object.values(abonosTDCAgrupado);

    const abnTDCPDF = abonosTDCAgrupado2.map((a) => {
      return [
        a.terminal,
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          maximumFractionDigits: 2,
        }).format(a.importe),
      ];
    });
    const abonosTDCPDF = Object.values(abnTDCPDF);
    const abonosTDCPDFLimpia = abonosTDCPDF.filter(function (el) {
      return el != null;
    });

    // Abonos Transfer
    const abonosTransfer = abonosTiendas
      .map((a) => {
        if (a.transfer > 0) {
          return {
            importe: a.transfer,
            destino: a.destino,
          };
        }
      })
      .filter(function (el) {
        return el != null;
      });

    let dataAbonosTransfer = Object.values(abonosTransfer);

    const dinerosTransfer = dineros
      .map((a) => {
        if (a.transfer > 0) {
          return {
            importe: a.transfer,
            destino: a.destino,
          };
        }
      })
      .filter(function (el) {
        return el != null;
      });

    let dataDinerosTransfer = Object.values(dinerosTransfer);

    const totalTransfer = dataAbonosTransfer.concat(dataDinerosTransfer);
    // Agrupado Transfer
    let abonosTransferAgrupado = totalTransfer.reduce(function (groups, item) {
      const val = item["destino"];
      groups[val] = groups[val] || {
        destino: item.destino,
        importe: 0,
      };
      groups[val].importe += item.importe;
      groups[val].destino = item.destino;
      return groups;
    }, []);

    let abonosTransferAgrupado2 = Object.values(abonosTransferAgrupado);

    // setAbonosTransferTiendas(abonosTransferAgrupado2);

    const abnTransferPDF = abonosTransferAgrupado2.map((a) => {
      return [
        a.destino,
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          maximumFractionDigits: 2,
        }).format(a.importe),
      ];
    });
    const abonosTransferPDF = Object.values(abnTransferPDF);
    const abonosTransferPDFLimpia = abonosTransferPDF.filter(function (el) {
      return el != null;
    });

    // Desgloce TDC
    let TotalTablaDesgloceTDC = 0;
    let TotalTablaDesgloceTDCAbonos = 0;
    let TotalTablaDesgloceTDCDin = 0;
    const desgloceTDCAbonos = abonosTiendas
      .map((a) => {
        if (a.tdc > 0) {
          TotalTablaDesgloceTDCAbonos = TotalTablaDesgloceTDCAbonos + a.tdc;
          return [
            a.consecutivo,
            a.clientes,
            a.terminal,
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 2,
            }).format(a.tdc),
          ];
        }
      })
      .filter(function (el) {
        return el != null;
      });
    const desgloceTDCPDF = Object.values(desgloceTDCAbonos);

    const desgloceTDCDin = dineros
      .map((a) => {
        if (a.tdc > 0) {
          TotalTablaDesgloceTDCDin = TotalTablaDesgloceTDCDin + a.tdc;
          return [
            a.numero,
            a.observaciones,
            a.terminal,
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 2,
            }).format(a.tdc),
          ];
        }
      })
      .filter(function (el) {
        return el != null;
      });
    const desgloceTDCDinPDF = Object.values(desgloceTDCDin);

    const totalDesgloceTDC = desgloceTDCPDF.concat(desgloceTDCDinPDF);

    const desgloceTDCPDFLimpia = totalDesgloceTDC.filter(function (el) {
      return el != null;
    });

    // Desgloce Transfer
    let TotalTablaDesgloceTransfer = 0;
    let TotalTablaDesgloceTransferAbonos = 0;
    let TotalTablaDesgloceTransferDin = 0;
    const desgloceTransferAbonos = abonosTiendas
      .map((a) => {
        if (a.transfer > 0) {
          TotalTablaDesgloceTransferAbonos =
            TotalTablaDesgloceTransferAbonos + a.transfer;
          return [
            a.consecutivo,
            a.clientes,
            a.destino,
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 2,
            }).format(a.transfer),
          ];
        }
      })
      .filter(function (el) {
        return el != null;
      });
    const desgloceTransferPDF = Object.values(desgloceTransferAbonos);

    const desgloceTransferDin = dineros
      .map((a) => {
        if (a.transfer > 0) {
          TotalTablaDesgloceTransferDin =
            TotalTablaDesgloceTransferDin + a.transfer;
          return [
            a.numero,
            a.observaciones,
            a.destino,
            a.fechaTransfer,
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 2,
            }).format(a.transfer),
          ];
        }
      })
      .filter(function (el) {
        return el != null;
      });
    const desgloceTransferDinPDF = Object.values(desgloceTransferDin);

    const totalDesgloceTransfer = desgloceTransferPDF.concat(
      desgloceTransferDinPDF
    );

    const desgloceTransferPDFLimpia = totalDesgloceTransfer.filter(function (
      el
    ) {
      return el != null;
    });

    // Gastos
    let TotalTablaGastos = 0;

    const gastosTiendas = gastos.map((a) => {
      TotalTablaGastos = TotalTablaGastos + a.total_general;

      return [
        a.numero,
        a.concepto,
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          maximumFractionDigits: 2,
        }).format(a.total_general),
      ];
    });
    const gastosPDFContado = Object.values(gastosTiendas);
    const gastosPDFContadoLimpia = gastosPDFContado.filter(function (el) {
      return el != null;
    });

    // Totales
    let TotalEfectivo =
      TotalTablaAbonosCreditoEfectivo +
      TotalTablaAbonosContadoEfectivo +
      TotalTablaDineroEfectivo -
      TotalTablaGastos;
    let TotalTDC =
      TotalTablaAbonosCreditoTDC +
      TotalTablaAbonosContadoTDC +
      TotalTablaDineroTDC;
    let TotalTransfer =
      TotalTablaAbonosCreditoTransfer +
      TotalTablaAbonosContadoTransfer +
      TotalTablaDineroTransfer;
    let TotalTotal =
      TotalTablaAbonosCreditoTotal +
      TotalTablaAbonosContadoTotal +
      TotalTablaDineroTotal;

    // let TotalQuedaEfectivo = TotalEfectivo  - TotalTablaGastos;

    const doc = new jsPDF({ compressPdf: true });
    var img = new Image();
    // img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    // doc.addImage(img, "png", 230, 30, 45, 15);
    // var img2 = new Image();
    // img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    // doc.addImage(img2, "png", 140, 15, 25, 25);
    doc.text(
      `Corte de Tienda ${selectedAreaName} - ${selectedFechaInicio}`,
      15,
      10
    );

    // Abonos Contado
    doc.text(`Ventas Contado`, 15, 18);
    doc.autoTable({
      head: [["Numero", "Cliente", "Efectivo", "TDC", "Transfer", "Total"]],
      body: abonosPDFContadoLimpia,
      startY: 20,
      foot: [
        [
          "",
          "Totales",
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(TotalTablaAbonosContadoEfectivo),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(TotalTablaAbonosContadoTDC),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(TotalTablaAbonosContadoTransfer),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(TotalTablaAbonosContadoTotal),
        ],
      ],
    });

    // Ventas Credito
    doc.text(`Ventas Credito`, 15, doc.autoTable.previous.finalY + 10);
    doc.autoTable({
      head: [["Numero", "Cliente", "Cantidad", "Total"]],
      body: ventasPDFCreditoLimpia,
      startY: doc.autoTable.previous.finalY + 13,
      foot: [
        [
          "",
          "Totales",
          new Intl.NumberFormat("en-US").format(TotalPiezasVentasCredito),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(TotalTablaVentasCredito),
        ],
      ],
    });

    // Abonos Credito
    doc.text(`Pagos Credito`, 15, doc.autoTable.previous.finalY + 10);
    doc.autoTable({
      head: [["Numero", "Cliente", "Efectivo", "TDC", "Transfer", "Total"]],
      body: abonosPDFCreditoLimpia,
      startY: doc.autoTable.previous.finalY + 13,
      foot: [
        [
          "",
          "Totales",
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(TotalTablaAbonosCreditoEfectivo),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(TotalTablaAbonosCreditoTDC),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(TotalTablaAbonosCreditoTransfer),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(TotalTablaAbonosCreditoTotal),
        ],
      ],
    });

    // Dinero
    doc.text(`Entrada Dinero`, 15, doc.autoTable.previous.finalY + 10);
    doc.autoTable({
      head: [
        ["Numero", "Observaciones", "Efectivo", "TDC", "Transfer", "Total"],
      ],
      body: dinerosPDFLimpia,
      startY: doc.autoTable.previous.finalY + 13,
      foot: [
        [
          "",
          "Totales",
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(TotalTablaDineroEfectivo),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(TotalTablaDineroTDC),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(TotalTablaDineroTransfer),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(TotalTablaDineroTotal),
        ],
      ],
    });

    // Devoluciones Contado
    doc.text(`Devoluciones`, 15, doc.autoTable.previous.finalY + 10);
    doc.autoTable({
      head: [["Numero", "Cantidad", "Total"]],
      body: devolucionesPDFLimpia,
      startY: doc.autoTable.previous.finalY + 13,
      foot: [
        [
          "Totales",
          new Intl.NumberFormat("en-US").format(TotalPiezasDevoluciones),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(TotalTablaDevoluciones),
        ],
      ],
    });

    // Gastos
    doc.text(`Gastos`, 15, doc.autoTable.previous.finalY + 10);
    doc.autoTable({
      head: [["Numero", "Concepto", "Total"]],
      body: gastosPDFContadoLimpia,
      startY: doc.autoTable.previous.finalY + 13,
      foot: [
        [
          "",
          "Totales",
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(TotalTablaGastos),
        ],
      ],
    });

    // Resumen
    doc.text(`Resumen`, 15, doc.autoTable.previous.finalY + 10);
    doc.autoTable({
      head: [
        [
          "Efectivo",
          "TDC",
          "Transfer",
          "Total Total",
          // "Efectivo - Gastos",
        ],
      ],
      body: [
        [
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(TotalEfectivo),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(TotalTDC),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(TotalTransfer),
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(TotalEfectivo + TotalTDC + TotalTransfer),
          // new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalQuedaEfectivo),
        ],
      ],
      startY: doc.autoTable.previous.finalY + 13,
    });

    // Desgloce TDC
    doc.text(`Desgloce TDC`, 15, doc.autoTable.previous.finalY + 10);
    doc.autoTable({
      head: [["Numero", "Cliente", "Terminal", "Importe"]],
      body: desgloceTDCPDFLimpia,
      startY: doc.autoTable.previous.finalY + 13,
    });

    // Resumen TDC
    doc.text(`Resumen TDC`, 15, doc.autoTable.previous.finalY + 10);
    doc.autoTable({
      head: [["Terminal", "Importe"]],
      body: abonosTDCPDFLimpia,
      startY: doc.autoTable.previous.finalY + 13,
    });

    // Desgloce Transfer
    doc.text(`Desgloce Transfer`, 15, doc.autoTable.previous.finalY + 10);
    doc.autoTable({
      head: [["Numero", "Cliente", "Destino", "FechaTransfer", "Importe"]],
      body: desgloceTransferPDFLimpia,
      startY: doc.autoTable.previous.finalY + 13,
    });

    // Resumen Transfer
    doc.text(`Resumen Transfer`, 15, doc.autoTable.previous.finalY + 10);
    doc.autoTable({
      head: [["Destino", "Importe"]],
      body: abonosTransferPDFLimpia,
      startY: doc.autoTable.previous.finalY + 13,
    });

    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: `Corte de Tienda ${selectedAreaName}`,
          email: "danysalame18@gmail.com",
          fileName: "Corte.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el Corte de la Tienda ${selectedAreaName}.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        Swal.fire("Good job!", "Enviado con exito", "success");
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  
  return (
    <>
      <Header />
      <br />
      <br />
      {user.corteTiendas ? (
        <div className="card container col-4">
          <Row>
            <Col md={8}>
              <Button
                size="sm"
                href="/MenuTiendas"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
          </Row>

          <h3 align="center">Corte Diario</h3>
          <Row>
            <Col md={6}>
              <Label className="mr-sm-2">Almacen</Label>
              <Input
                type="select"
                value={selectedArea}
                required
                onChange={(e) => {
                  jalaInfoArea(e.target.value);
                  setSelectedArea(e.target.value);
                }}
              >
                <option value="0">Selecciona</option>
                {areas
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((a) => {
                    if (user.areas == AREAS_GENERAL) {
                      if (
                        a._id == AREAS_ELI ||
                        a._id == AREAS_MAGALI ||
                        a._id == AREAS_ROLANDO
                      ) {
                        return <option value={a._id}>{a.name}</option>;
                      }
                    } else if (a._id == user.areas) {
                      return <option value={a._id}>{a.name}</option>;
                    }
                  })}
              </Input>
            </Col>
            {selectedArea != "" ? (
              <Col md={6}>
                <Label className="mr-sm-2">Fecha</Label>
                <Input
                  type="date"
                  value={selectedFechaInicio}
                  required
                  onChange={(e) => {
                    jalaInfo(e.target.value);
                    setSelectedFechaInicio(e.target.value);
                  }}
                />
              </Col>
            ) : (
              <Col md={6}>
                <Label className="mr-sm-2">Fecha</Label>
                <Input type="date" value={selectedFechaInicio} disabled />
              </Col>
            )}
          </Row>
          <br />
          <Row>
            <Col sm={4}>
              {validaBoton ? (
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFCorte}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
              ) : (
                <Button size="sm" className="btn" color="danger" disabled>
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
              )}
            </Col>
            <Col sm={4}>
              {validaBoton ? (
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTicketCorte}
                >
                  Ticket <i class="far fa-file-pdf"></i>
                </Button>
              ) : (
                <Button size="sm" className="btn" color="danger" disabled>
                  Ticket <i class="far fa-file-pdf"></i>
                </Button>
              )}
            </Col>
            <Col sm={4}>
              {validaBoton ? (
                <Button
                  size="sm"
                  className="btn"
                  color="info"
                  onClick={(e) => {
                    EmailCorte();
                  }}
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
              ) : (
                <Button size="sm" className="btn" color="info" disabled>
                  eMail <i class="fas fa-at"></i>
                </Button>
              )}
            </Col>
          </Row>

          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>Enviar Corte</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={emailCorteDiario}
                required
                onChange={(e) => {
                  setEmailCorteDiario(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={EmailCorte}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>

          <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
              <h4>
                {" "}
                Este proceso puede tardar varios segundos.
                <br />
                Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
              </div>
            </ModalBody>
          </Modal>

          <Modal size="sm" isOpen={modalNotas} toggle={toggleNotas}>
            <ModalHeader toggle={toggleNotas}>
              <h4>Notas con Problemas</h4>
            </ModalHeader>
            <ModalBody>
              <p>Las siguientes notas no se pudieron registrar</p>
              <p>Por favor verifique y de ser necesario cancelarlas</p>
              {notas.map((a) => {
                return <p>{a.numero}</p>;
              })}
            </ModalBody>
          </Modal>
        </div>
      ) : undefined}
    </>
  );
}

export default CorteTiendas;
