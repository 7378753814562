import React, { useContext } from "react";
import { AuthContext } from '../../contexts/AuthContext'
import { Col, Row, Button } from "reactstrap";
import Footer from "../../layout/Footer/Footer";
import Header from "../../layout/Header/Header";

function BotonesAdmin() {
  const { user } = useContext(AuthContext);
  return (
    <>
          <Header />
          {user.configAdmin ?(
      <div className="container">
        <br />
        <br />
        <Row >
        <Col md={4} align="center">
          {user.bancos ?(
            <Button href="/Bancos" className="botonesMenu" color="success">
              <i class="fas fa-university fa-7x"></i>
              <br />
              <br />
              Bancos
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-university fa-7x"></i>
          <br />
          <br />
          Bancos
        </Button> }
          </Col>

          <Col md={4} align="center">
          {user.menu_ventas ?(
            <Button href="/ListadoVentas" className="botonesMenu" color="success">
              <i class="fas fa-money-bill-wave fa-7x"></i>
              <br />
              <br />
              Ventas
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-money-bill-wave fa-7x"></i>
          <br />
          <br />
          Ventas
        </Button> }
          </Col>
          

          <Col md={4} align="center">
          {user.menuAnticipos ?(
            <Button href="/ListadoAnticipos" className="botonesMenu" color="success">
              <i class="fas fa-hand-holding-usd fa-7x"></i>
              <br />
              <br />
              Anticipos
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-hand-holding-usd fa-7x"></i>
          <br />
          <br />
          Anticipos
        </Button> }
          </Col>
          </Row>
          <br />
          <Row>
          <Col md={4} align="center">
          {user.abonos ?(
            <Button href="/ListadoAbonos" className="botonesMenu" color="success">
              <i class="fas fa-dollar-sign fa-7x"></i>
              <br />
              <br />
              Abonos
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-dollar-sign fa-7x"></i>
          <br />
          <br />
          Abonos
        </Button> }
          </Col>

          <Col md={4} align="center">
          {user.notasCargo ?(
            <Button href="/ListadoNotasCargo" className="botonesMenu" color="success">
              <i class="fas fa-money-check-alt fa-7x"></i>
              <br />
              <br />
              Notas de Cargo
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-money-check-alt fa-7x"></i>
          <br />
          <br />
          Notas de Cargo
        </Button> }
          </Col>
          <Col md={4} align="center">
          {user.menu_devolucionesAdmin ?(
            <Button href="/ListadoDevolucionesAdmin" className="botonesMenu" color="success">
              <i class="fas fa-undo-alt fa-7x"></i>
              <br />
              <br />
              Devoluciones
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-undo-alt fa-7x"></i>
          <br />
          <br />
          Devoluciones
        </Button> }
          </Col>

          </Row>
        <br />
          <Row>
          <Col md={4} align="center">
          {user.edoCtaClientes ?(
            <Button href="/EstadoCuenta" className="botonesMenu" color="success">
              <i class="fas fa-file-invoice-dollar fa-7x"></i>
              <br />
              <br />
              Estado Cuenta
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-file-invoice-dollar fa-7x"></i>
          <br />
          <br />
          Estado Cuenta
        </Button> }
          </Col>

          <Col md={4} align="center">
          {user.notasCargo ?(
            <Button href="/ListadoComisiones" className="botonesMenu" color="success">
              <i class="fas fa-percentage fa-7x"></i>

              <br />
              <br />
              Comisiones
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-percentage fa-7x"></i>

          <br />
          <br />
          Comisiones
        </Button> }
          </Col>

          <Col md={4} align="center">
          {user.gastosTiendas ?(
            <Button href="/ListadoTraspasosDinero" className="botonesMenu" color="success">
              <i class="fas fa-dollar-sign fa-7x"></i>
              <br />
              <br />
              Traspaso Dinero
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-dollar-sign fa-7x"></i>
          <br />
          <br />
          Traspaso Dinero
        </Button> }
          </Col>
          </Row>
          <br/>

      </div>
      ): undefined } 
      
    </>
  );
}

export default BotonesAdmin;
