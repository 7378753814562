import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import Header from "../../layout/Header/Header";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Spinner,
  Form,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import { TableHeader, Pagination, Search } from "../../DataTable";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import qrcode from "qrcode-js";
import moment from "moment";

function ReporteResumenAbonosTiendas() {
  const { user } = useContext(AuthContext);
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_ABONOS = process.env.REACT_APP_URL_ABONOS;
  const URL_ABONOS_TIENDAS = process.env.REACT_APP_URL_ABONOS_TIENDAS;
  const URL_GASTOS_GENERAL = process.env.REACT_APP_URL_GASTOS_GENERAL;

  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  const [abonosTiendas, setAbonosTiendas] = useState([]);
  const [abonosTDCTiendas, setAbonosTDCTiendas] = useState([]);
  const [abonosTransferTiendas, setAbonosTransferTiendas] = useState([]);
  const [TEF, setTEF] = useState(0);
  const [TTrans, setTTrans] = useState(0);
  const [TTDC, setTTDC] = useState(0);
  const [selectedFechaInicio, setSelectedFechaInicio] = useState(hoy);
  const [selectedFechaFin, setSelectedFechaFin] = useState(hoy);


  const ITEMS_PER_PAGE = 50;

  useMemo(() => {
      axios
      .get(
        `${URL_ABONOS_TIENDAS}AbonosVentasDiarioGeneral/${selectedFechaInicio}/${selectedFechaFin}`,
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then((response) => {
        let allAbonosTiendas = response.data;
        let arrayAbonosTiendas = allAbonosTiendas
          .map((a) => {
            if(a.concepto == "Abono"){
              return {
                    fecha: a.fecha,
                    is_active: a.is_active,
                    importe: a.total,
                    efectivo: a.efectivo,
                    tdc: a.tdc,
                    terminal: a.terminal,
                    transfer: a.transfer,
                    destino: a.destino,
                    areas: a.areas[0]._id,
                    areasName: a.areas[0].name,
              }}
          })
          .filter(function (el) {
            return el != null;
          });

        let dataAbonosTiendas = Object.values(arrayAbonosTiendas);

        let agrupadoAbonosTiendas = dataAbonosTiendas.reduce(function (groups, item) {
          const val = item["areas"];
          groups[val] = groups[val] || {
            areas: item.areas,
            efectivo: 0,
            tdc: 0,
            transfer: 0,
          };
          groups[val].efectivo += item.efectivo;
          groups[val].tdc += item.tdc;
          groups[val].terminal += item.terminal;
          groups[val].transfer += item.transfer;
          groups[val].destino += item.destino;
          groups[val].areas = item.areas;
          groups[val].areasName = item.areasName;
          return groups;
        }, []);

        let dataAbonosTiendas2 = Object.values(agrupadoAbonosTiendas);

        setAbonosTiendas(dataAbonosTiendas2);


        let agrupadoAbonosTDCTiendas = dataAbonosTiendas.reduce(function (groups, item) {
          const val = item["areas"] + item["terminal"];
          groups[val] = groups[val] || {
            areas: item.areas,
            terminal: item.terminal,
            efectivo: 0,
            tdc: 0,
            transfer: 0,
          };
          groups[val].efectivo += item.efectivo;
          groups[val].tdc += item.tdc;
          groups[val].terminal = item.terminal;
          groups[val].transfer += item.transfer;
          groups[val].destino = item.destino;
          groups[val].areas = item.areas;
          groups[val].areasName = item.areasName;
          return groups;
        }, []);

        let dataAbonosTDCTiendas2 = Object.values(agrupadoAbonosTDCTiendas);

        setAbonosTDCTiendas(dataAbonosTDCTiendas2);

        let agrupadoAbonosTransferTiendas = dataAbonosTiendas.reduce(function (groups, item) {
          const val = item["areas"] + item["destino"];
          groups[val] = groups[val] || {
            areas: item.areas,
            destino: item.destino,
            efectivo: 0,
            tdc: 0,
            transfer: 0,
          };
          groups[val].efectivo += item.efectivo;
          groups[val].tdc += item.tdc;
          groups[val].terminal = item.terminal;
          groups[val].transfer += item.transfer;
          groups[val].destino = item.destino;
          groups[val].areas = item.areas;
          groups[val].areasName = item.areasName;
          return groups;
        }, []);

        let dataAbonosTransferTiendas2 = Object.values(agrupadoAbonosTransferTiendas);

        setAbonosTransferTiendas(dataAbonosTransferTiendas2);

      })

  }, [selectedFechaInicio, selectedFechaFin]);


  let totalATEfectivo = 0
  let totalATTDC = 0
  let totalATTransfer = 0
  let totalTDCTerminal = 0
  let totalTransferDestino = 0

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_reportes ? (
        <div className="card container col-6">
          <Row>
            <Col md={8}>
              <Button
                size="sm"
                href="/MenuReportesAbonos"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
          </Row>
          <h3 align="center">Resumen Abonos Tiendas</h3>
          <Row>
            <Col md={4}>
              <Label className="mr-sm-2">Fecha Inicio</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaInicio}
                required
                onChange={(e) => {
                  setSelectedFechaInicio(e.target.value);
                }}
              />
            </Col>
            <Col md={4}>
              <Label className="mr-sm-2">Fecha Fin</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaFin}
                required
                onChange={(e) => {
                  setSelectedFechaFin(e.target.value);
                }}
              />
            </Col>
          </Row>
          <br />

          <h3>Abonos Tiendas</h3>
          <Table size="sm" striped borderless className="table-responsive-xl">
            <tbody>
              <tr>
                <th>Tienda</th>
                <th>Efectivo</th>
                <th>TDC</th>
                <th>Transfer</th>
                <th>Total</th>
              </tr>
              {abonosTiendas.map((a) => {
                totalATEfectivo = totalATEfectivo + a.efectivo
                totalATTDC = totalATTDC + a.tdc
                totalATTransfer = totalATTransfer + a.transfer
                return (
                  <tr>
                    <td>{a.areasName}</td>
                    <td>{new Intl.NumberFormat("en-US", { style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.efectivo)}</td>
                    <td>{new Intl.NumberFormat("en-US", { style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.tdc)}</td>
                    <td>{new Intl.NumberFormat("en-US", { style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.transfer)}</td>
                    <td>{new Intl.NumberFormat("en-US", { style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.efectivo + a.tdc + a.transfer)}</td>
                  </tr>
                );
              })}
              <tr>
              <td className="negrita" align="right">Total</td>
              <td className="negrita">{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalATEfectivo + TEF)}</td>
              <td className="negrita">{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalATTDC + TTDC)}</td>
              <td className="negrita">{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalATTransfer + TTrans)}</td>
              <td className="negrita">{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalATEfectivo + totalATTDC + totalATTransfer+ TEF + TTDC + TTrans)}</td>
                
              </tr>
              
            </tbody>
          </Table>

              <br />
          <h3>Resumen TDC</h3>
          <Table size="sm" striped borderless className="table-responsive-xl">
            <tbody>
              <tr>
                <th>Tienda</th>
                <th>Terminal</th>
                <th>Total</th>
              </tr>
              {abonosTDCTiendas.map((a) => {
                if(a.tdc > 0){
                totalTDCTerminal = totalTDCTerminal + a.tdc
                return (
                  <tr>
                    <td>{a.areasName}</td>
                    <td>{a.terminal}</td>
                    <td>{new Intl.NumberFormat("en-US", { style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.tdc)}</td>
                  </tr>
                )
                }
              })}
              <tr>
              <td></td>
              <td className="negrita" align="right">Total</td>
              <td className="negrita">{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalTDCTerminal)}</td>
                
              </tr>
              
            </tbody>
          </Table>


          <h3>Resumen Transfer</h3>
          <Table size="sm" striped borderless className="table-responsive-xl">
            <tbody>
              <tr>
                <th>Tienda</th>
                <th>Destino</th>
                <th>Total</th>
              </tr>
              {abonosTransferTiendas.map((a) => {
                if(a.transfer > 0){
                totalTransferDestino = totalTransferDestino + a.transfer
                return (
                  <tr>
                    <td>{a.areasName}</td>
                    <td>{a.destino}</td>
                    <td>{new Intl.NumberFormat("en-US", { style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.transfer)}</td>
                  </tr>
                )
                }
              })}
              <tr>
              <td></td>
              <td className="negrita" align="right">Total</td>
              <td className="negrita">{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalTransferDestino)}</td>
                
              </tr>
              
            </tbody>
          </Table>

          
        </div>
      ) : undefined}
      <br />

    </>
  );
}

export default ReporteResumenAbonosTiendas;
