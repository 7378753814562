import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import Baja from "../../Baja";
import { TableHeader, Pagination, Search } from "../../../DataTable"
import useFullPageLoader from "../../../hooks/useFullPageLoader"
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

function ListoSurtirCliente() {
  const { user } = useContext(AuthContext);
  const URL_ARTICULOS_PEDIDO = process.env.REACT_APP_URL_ARTICULOS_PEDIDO;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;


  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState("");
  const [value, setValue] = useState("");

  const [articulosPedidos, setArticulosPedidos] = useState([]);
  const [modalPedidos, setModalPedidos] = useState(false);
  const togglePedidos = () => setModalPedidos(!modalPedidos);


    //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 100


  useMemo(() => {
    
    if(selectedCliente != ""){
    axios
      .get(`${URL_ARTICULOS_PEDIDO}ListoSurtirCliente/${selectedCliente}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulos = response.data;
        setComments(allArticulos);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  }, [selectedCliente]);

  function excel() {
    const dataExcel = comments.map((a) => {
  
        return {
          Articulo: a.articuloNombre,
          Almacen: a.area,
          Inventario: a.cantInv,
          Pedido: a.cantPed,
          PendSurtir: a.pendSurtir,
          PiezasPorCaja: a.piezasPorCaja,
          Cajas: a.cajas,
        };
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListoSurtir";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListoSurtir",
        sheetFilter: [
          "Articulo",
          "Almacen",
          "Inventario",
          "Pedido",
          "PendSurtir",
          "PiezasPorCaja",
          "Cajas",
        ],
        sheetHeader: [
          "Articulo",
          "Almacen",
          "Inventario",
          "Pedido",
          "PendSurtir",
          "PiezasPorCaja",
          "Cajas",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  const headers = [
    { name: "Articulo", field: "articuloNombre", sortable: true },
    { name: "Almacen", field: "area", sortable: true },
    { name: "Inventario", field: "cantInv", sortable: true },
    { name: "Total Pedido", field: "cantPed", sortable: true },
    { name: "Pend Surtir", field: "pendSurtir", sortable: true },
    { name: "Piezas por Caja", field: "piezasPorCaja", sortable: false },
    { name: "Pend Surtir Cajas", field: "cajas", sortable: true },
];



  const commentsData = useMemo(() => {
    let computedComments = comments
  
    if (search) {
        computedComments = computedComments.filter(
            comment =>
                comment.articuloNombre.toLowerCase().includes(search.toLowerCase()) 
        );
    }


    setTotalItems(computedComments.length);

//Sorting comments
if (
  sorting.field &&
  sorting.field != "cantInv" &&
  sorting.field != "cantPed" &&
  sorting.field != "cajas" &&
  sorting.field != "pendSurtir"
) {
  const reversed = sorting.order === "asc" ? 1 : -1;
  computedComments = computedComments.sort(
    (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
  );
}

if (
  sorting.field &&
  sorting.order === "asc" &&
  (sorting.field == "cantInv" ||
    sorting.field == "cantPed" ||
    sorting.field == "cajas" ||
    sorting.field == "pendSurtir")
) {
  computedComments = computedComments.sort((a, b) =>
    parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
  );
}

if (
  sorting.field &&
  sorting.order === "desc" &&
  (sorting.field == "cantInv" ||
    sorting.field == "cantPed" ||
    sorting.field == "cajas" ||
    sorting.field == "pendSurtir")
) {
  computedComments = computedComments.sort((a, b) =>
    parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
  );
}

    //Current Page slice
    return computedComments.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
}, [comments, currentPage, search, sorting]);

function jalaPedidos(id){
  setArticulosPedidos([])
  axios
  .get(`${URL_ARTICULOS_PEDIDO}Articulo/${id}`, {
    headers: {
      Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
    },
  })
  .then((response) => {
    let allMovs = response.data
    setArticulosPedidos(allMovs);
  })
  .catch((err) => {
    console.log(err);
  })


    togglePedidos();
  }

  const options = clientes.map((option) => {
    const junta = option.nombre_comercial + " " + option.codigo;
    const firstLetter = option.nombre_comercial[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
      junta,
    };
  });

  const handleSearchClientes = async (searchText) => {
    try {
      const response = await axios.get(`${URL_CLIENTES}Search/${searchText}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("app_token")}`, // Agregar el token al header
        },
      });
      // Verificar que response.data sea un arreglo
      setClientes(Array.isArray(response.data) ? response.data : []);
    } catch (error) {
      console.error("Error buscando clientes", error);
    }
  };


  let totalCantidad = 0
  let totalSurtido = 0
  let totalPendiente = 0
  let totalCajas = 0

let totalCantidadInventario = 0
let totalPedido = 0
let pendiente = 0

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_ventas ? (
        <div className="card col-12">
          <Row>
            <Col md={10}>
              <Button
                size="sm"
                href="/MenuVentas"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
            <Col sm={2}>
                 
                    <Button
                      size="sm"
                      className="btn"
                      color="primary"
                      onClick={excel}
                    >
                      Excel <i class="far fa-file-excel"></i>
                    </Button>
                </Col>
         
          </Row>
         
      
          <h3 align="center">Listo Surtir por Cliente</h3>
          
                    <br />
                  <Row>
                    <Col md={3}>
                    <Label className="mr-sm-2">Cliente</Label>
                    <Autocomplete
                    size="small"
                    value={value}
                    onInputChange={(event, newInputValue) => {
                      handleSearchClientes(newInputValue);  // Hacer una nueva búsqueda en cada cambio
                    }}
                    onChange={(event, selectedCliente) => {
                      if (selectedCliente) {
                        setValue(selectedCliente);
                        setSelectedCliente(selectedCliente._id);
                      }
                    }}
                    options={options.sort(
                      (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                    )}
                    groupBy={(option) => option.firstLetter}
                    getOptionLabel={(option) => option.junta}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Escribe las primeras letras"
                        variant="outlined"
                      />
                    )}
                    renderOption={(option) => (
                      <React.Fragment>
                        {option.codigo} {option.nombre_comercial}
                      </React.Fragment>
                    )}
                  />
                  </Col>
                  </Row>

<br />


                    {/* Tabla Movimientos */}
                     <Pagination
                                total={totalItems}
                                itemsPerPage={ITEMS_PER_PAGE}
                                currentPage={currentPage}
                                onPageChange={page => setCurrentPage(page)}
                            />

          <Table size="sm" striped borderless className="table-responsive-xl">
                <TableHeader
                            headers={headers}
                            onSorting={(field, order) =>
                                setSorting({ field, order })
                            }
                        />
                      
            <tbody>
              {commentsData.map((a) => {
                totalCantidadInventario = totalCantidadInventario + parseFloat(a.cantInv);
                totalPedido = totalPedido + parseFloat(a.cantPed);
                totalPendiente = totalPendiente + parseFloat(a.pendSurtir);
                totalCajas = totalCajas + parseFloat(a.cajas);
                  return (
                    <tr>
                      <td>{a.articuloNombre}</td>
                      <td>{a.area}</td>
                      <td>{new Intl.NumberFormat("en-US").format(a.cantInv)}</td>
                      <td>{new Intl.NumberFormat("en-US").format(a.cantPed)}</td>
                      <td>{new Intl.NumberFormat("en-US").format(a.pendSurtir)}</td>
                      <td>{new Intl.NumberFormat("en-US").format(a.piezasPorCaja)}</td>
                      <td>{new Intl.NumberFormat("en-US").format(a.cajas.toFixed(2))}</td>
                      <td>
              <Button
                          color="info"
                          id="Editar"
                          size="sm"
                          onClick={(e) => jalaPedidos(a.articulos)}
                        >
                          <i class="fas fa-search"></i>
                        </Button>
              </td>
                    </tr>
                  )
              })}


              <tr>
             <td></td>
                <td className="negrita">TOTALES</td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US").format(totalCantidadInventario)}
                </td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US").format(totalPedido)}
                </td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US").format(totalPendiente)}
                </td>
             <td></td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US").format(totalCajas)}
                </td>
             <td></td>

              </tr>
            </tbody>
          </Table>
          <div className="col-md-6">
                            <Pagination
                                total={totalItems}
                                itemsPerPage={ITEMS_PER_PAGE}
                                currentPage={currentPage}
                                onPageChange={page => setCurrentPage(page)}
                            />
                        </div>
        </div>
      ) : undefined}


<Modal size="xxl" isOpen={modalPedidos} toggle={togglePedidos}>
        <ModalHeader toggle={togglePedidos}>
          <h4>Analisis Pedidos</h4>
        </ModalHeader>
        <ModalBody>
        <Table size="sm" striped borderless className="table-responsive-xl">
                                    
            <tbody>
              <tr>
              <th>Pedido</th>
              <th>Cliente</th>
              <th>Almacen</th>
              <th>Activo</th>
              <th>Apartado</th>
              <th>Aut Surtir</th>
              <th>Cantidad</th>
              <th>Surtido</th>
              <th>Pend Surtir</th>
              </tr>
              {articulosPedidos.map((a) => {
                if(a.is_active == "Si" && a.autorizadoApartado == "Si" && a.pendiente_surtir > 0){
                totalCantidad = totalCantidad + parseFloat(a.cantidad);
                totalSurtido = totalSurtido + parseFloat(a.surtido);
                pendiente = pendiente + parseFloat(a.pendiente_surtir);
                  return (
                    <tr>
                      <td>{a.pedidos[0].idPedido}</td>
                      <td>{a.pedidos[0].clientes[0].nombre_comercial}</td>
                      <td>{a.pedidos[0].areas[0].name}</td>
                      <td>{a.is_active}</td>
                      <td>{a.autorizadoApartado}</td>
                      <td>{a.autorizadoSurtido}</td>
                      <td>{new Intl.NumberFormat("en-US").format(a.cantidad)}</td>
                      <td>{new Intl.NumberFormat("en-US").format(a.surtido)}</td>
                      <td>{new Intl.NumberFormat("en-US").format(a.pendiente_surtir)}</td>
                    </tr>
                  )}
              })}


              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className="negrita">TOTALES</td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US").format(totalCantidad)}
                </td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US").format(totalSurtido)}
                </td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US").format(pendiente)}
                </td>


              </tr>
            </tbody>
          </Table>
        </ModalBody>
      </Modal>

      {loader}
    </>
  );
}

export default ListoSurtirCliente;
