import React, { useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { Col, Row, Button } from "reactstrap";
import Footer from "../../layout/Footer/Footer";
import Header from "../../layout/Header/Header";

function BotonesCompras() {
  const { user } = useContext(AuthContext);
  return (
    <>
      <Header />
      {user.menu_cadenas ?(
      <div className="container">
        <br />
        <br />
        <Row>
        <Col md={4} align="center">
            {user.menu_cadenas ? (
              <Button
                href="/ProveedoresMex"
                className="botonesMenu"
                color="success"
              >
                <i class="fas fa-dolly fa-7x"></i>
                <br />
                <br />
                Proveedores
              </Button>
            ) : (
              <Button disabled className="botonesMenu" color="success">
                <i class="fas fa-dolly fa-7x"></i>
                <br />
                <br />
                Proveedores
              </Button>
            )}
          </Col>

          <Col md={4} align="center">
            {user.menu_cadenas ? (
            <Button
              href="/ListadoInsumos"
              className="botonesMenu"
              color="success"
            >
              <i class="fas fa-store fa-7x"></i>
              <br />
              <br />
              Insumos
            </Button>
            ) : (
                <Button disabled className="botonesMenu" color="success">
                  <i class="fas fa-store fa-7x"></i>
                  <br />
                  <br />
                  Insumos
                </Button>
              )}
          </Col>

          <Col md={4} align="center">
          {user.menu_cadenas ?(
            <Button href="/ListadoOrdenesCompraHab" className="botonesMenu" color="success">
              <i class="fas fa-shopping-basket fa-7x"></i>
              <br />
              <br />
              Ordenes de Compra
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-shopping-basket fa-7x"></i>
          <br />
          <br />
          Ordenes de Compra
        </Button> }
          </Col>
          </Row>
          <br />
          <Row>
          <Col md={4} align="center">
          {user.menu_cadenas ?(
            <Button href="/ListadoComprasHab" className="botonesMenu" color="success">
              <i class="fas fa-cart-plus fa-7x"></i>
              <br />
              <br />
              Compras
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-cart-plus fa-7x"></i>
          <br />
          <br />
          Compras
        </Button> }
          </Col>

          <Col md={4} align="center">
          {user.menu_cadenas ?(
            <Button href="/ListadoInventariosInsumos" className="botonesMenu" color="success">
              <i class="fas fa-tags fa-7x"></i>
              <br />
              <br />
              Inventario Insumos
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-tags fa-7x"></i>
          <br />
          <br />
          Inventario Insumos
        </Button> }
          </Col>
          <Col md={4} align="center">
              {user.menu_cadenas ? (
                <Button
                  href="/ListadoCadenas"
                  className="botonesMenu"
                  color="success"
                >
                  <i class="fas fa-user-tie fa-7x"></i>
                  <br />
                  <br />
                  Cadenas
                </Button>
              ) : (
                <Button disabled className="botonesMenu" color="success">
                  <i class="fas fa-user-tie fa-7x"></i>
                  <br />
                  <br />
                  Cadenas
                </Button>
              )}
            </Col>
        </Row>
        <br />
        <Row>
        <Col md={4} align="center">
          {user.menu_cadenas ?(
            <Button href="/ListadoPedidosCadenas" className="botonesMenu" color="success">
              <i class="fas fa-shopping-basket fa-7x"></i>
              <br />
              <br />
              Pedidos
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-shopping-basket fa-7x"></i>
          <br />
          <br />
          Pedidos
        </Button> }
          </Col>

          <Col md={4} align="center">
          {user.menu_cadenas ?(
            <Button href="/EstadoCuentaHab" className="botonesMenu" color="success">
              <i class="fas fa-file-invoice-dollar fa-7x"></i>
              <br />
              <br />
              Estado Cuenta
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-file-invoice-dollar fa-7x"></i>
          <br />
          <br />
          Estado Cuenta
        </Button> }
          </Col>

          <Col md={4} align="center">
          {user.menu_cadenas ?(
            <Button href="/ListadoAbonosProveedoresHab" className="botonesMenu" color="success">
              <i class="fas fa-dollar-sign fa-7x"></i>
              <br />
              <br />
              Abonos
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-dollar-sign fa-7x"></i>
          <br />
          <br />
          Abonos
        </Button> }
          </Col>

        </Row>
      </div>
       ): undefined }  
    </>
  );
}

export default BotonesCompras;
