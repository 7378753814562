import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Spinner,
  Form,
  FormGroup,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import moment from "moment";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { v4 as uuidv4 } from "uuid";
import CurrencyInput from "react-currency-input-field";
import { formatValue } from "react-currency-input-field";

function AbonosProveedoresCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_PROVEEDORES = process.env.REACT_APP_URL_PROVEEDORES;
  const URL_ABONOS_PROVEEDORES = process.env.REACT_APP_URL_ABONOS_PROVEEDORES;
  const URL_COMPRAS = process.env.REACT_APP_URL_COMPRAS;
  const URL_BANCOS = process.env.REACT_APP_URL_BANCOS;

  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  const [text, setText] = useState(false);
  const [proveedores, setProveedores] = useState([]);
  const [selectedProveedor, setSelectedProveedor] = useState("");
  const [selectedProveedorNombre, setSelectedProveedorNombre] = useState("");
  const [fecha, setFecha] = useState(hoy);


  const [value, setValue] = useState("");
  const [validaBoton, setValidaBoton] = useState(true);
  const [total, setTotal] = useState(0);
  const [importe, setImporte] = useState(0);
  const [observaciones, setObservaciones] = useState("NA");

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  useMemo(() => {
    axios
      .get(`${URL_PROVEEDORES}Tipo/China`, { 
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allProveedores = response.data;
        setProveedores(allProveedores);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectedProveedor]);


  const savePago = (event) => {
    event.preventDefault();
    setValidaBoton(false);
    axios
      .all([
        axios.post(
          URL_ABONOS_PROVEEDORES,
          {
            fecha,
            importe,
            proveedores: selectedProveedor,
            observaciones
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        ),
      ])
      .then(() => {
        axios.post(
          URL_LOGS,
          {
            tipo: "Crear Pago",
            detalle: `Fecha: ${fecha} / Proveedor: ${selectedProveedor} / Importe: ${importe} `,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        );
        Swal.fire("Good job!", "Creado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
        console.log(error);
        setValidaBoton(true);
      });
  };

  
  const options = proveedores.map((option) => {
    const junta = option.nombre_comercial;
    const firstLetter = option.nombre_comercial[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
      junta,
    };
  });

  return (
    <>
      <Header />
      <br />
      <br />
      {user.pagoProveedores ? (
        <div className="card container col-sm-8">
          <h3 align="center">Captura un Pago</h3>
          <Form>
            <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
              <Row>
                <Col md={4}>
                  <Label className="mr-sm-2">Fecha</Label>
                  <Input
                    type="date"
                    value={fecha}
                    required
                    onChange={(e) => {
                      setFecha(e.target.value);
                    }}
                  />
                </Col>
                <Col md={4}>
                  <Label className="mr-sm-2">Proveedor</Label>
                  <Autocomplete
                    size="small"
                    value={value}
                    onChange={(event, selectedProveedor) => {
                      if (selectedProveedor) {
                        setValue(selectedProveedor);
                        setSelectedProveedor(selectedProveedor._id);
                        setSelectedProveedorNombre(
                          selectedProveedor.nombre_comercial
                        );
                      }
                    }}
                    options={options.sort(
                      (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                    )}
                    groupBy={(option) => option.firstLetter}
                    getOptionLabel={(option) => option.junta}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Selecciona"
                        variant="outlined"
                      />
                    )}
                    renderOption={(option) => (
                      <React.Fragment>
                        {option.codigo} {option.nombre_comercial}
                      </React.Fragment>
                    )}
                  />
                </Col>

                <Col sm={4}>
                    <Label for="exampleEmail" className="mr-sm-2">
                      Importe
                    </Label>
                    <Input
                      type="number"
                      min="0"
                      step="any"
                      value={importe}
                        onChange={(e) => setImporte(e.target.value)}
                    />
                  </Col>
                  </Row>
                  <Row>
                  <Col sm={4}>
                    <Label for="exampleEmail" className="mr-sm-2">
                      Observaciones
                    </Label>
                    <Input
                      type="text"
                      value={observaciones}
                        onChange={(e) => setObservaciones(e.target.value)}
                    />
                  </Col>
               
              </Row>
            </FormGroup>
            <br />
            <Row>
              <Button className="btn btn-success" onClick={savePago}>
                {" "}
                Registrar
              </Button>

              <Button
                href="/ListadoAbonosProveedores"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Row>
          </Form>
          <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
             <h4> Este proceso puede tardar varios segundos.
              <br />
              Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
              </div>
            </ModalBody>
          </Modal>
        </div>
      ) : undefined}
      
    </>
  );
}

export default AbonosProveedoresCreate;
