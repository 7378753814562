import React, { useState, useEffect, useContext, useRef, useLayoutEffect, useMemo } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import axios from "axios";
import Header from "../../layout/Header/Header";

import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Form,
} from "reactstrap";
import moment from 'moment';
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";



function TablaVentasMensualComparativaCliente() {
  let hoy = new Date()
  let inicio = new Date()
  inicio.setDate(1);
  let fechaEspecifica = new Date('2024-01-01');
  const year = fechaEspecifica.getFullYear();
  const years = Array.from(new Array(5), (val, index) => index + year);

  let endDate   = moment(hoy).format("YYYY-MM-DD"); 
  let startDate   = moment(inicio).format("YYYY-MM-DD");

  const { user } = useContext(AuthContext);
  const URL_GRAFICAS = process.env.REACT_APP_URL_GRAFICAS;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;

  const [graficas, setGraficas] = useState([]);

  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState("");
  const [value, setValue] = useState("");

  const [selectedFechaInicio, setSelectedFechaInicio] = useState(startDate);
  const [selectedFechaFin, setSelectedFechaFin] = useState(endDate);

  const [cargando, setCargando] = useState(false);
  const [ano, setAno] = useState("");

  const Toast = Swal.mixin({
    toast: true,
    position: 'center',
    showConfirmButton: false,
    // timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })


  useMemo(()=>{
if(selectedCliente){
    axios
    .get(`${URL_GRAFICAS}/ventaMensualComparativaCliente/${ano}/${selectedCliente}`, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((response) => {
      let allGraficas = response.data;
      setGraficas(allGraficas);
      setCargando(false)
      Toast.close()
    })
    .catch((err) => {
      console.log(err);
    });}

    if(cargando){
      Toast.fire({
        icon: 'success',
        title: 'Danos unos segundos....'
      })
    }

    axios
    .get(URL_CLIENTES, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((response) => {
      let allClientes = response.data;
      setClientes(allClientes);
    })
    .catch((err) => {
      console.log(err);
    });

  },[ano, cargando, selectedCliente])

      const options = clientes.map((option) => {
      const junta = option.nombre_comercial + " " + option.codigo;
      const firstLetter = option.nombre_comercial[0].toUpperCase();
      return {
        firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
        ...option,
        junta,
      };
    });
    
    let totImpAnt = 0
    let totImpAct = 0
    let porcTotal = 0


  return (
    <>
    <Header />
    <br />
    <br />
    {/* {user.menu_reportes ? ( */}
      <div className="card container col-12">
        <h3 align="center">Venta Mensual</h3>
    <div className="container col-12">
    <Row>
  

          <Col md={2}>
                <Label>Año</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={ano}
                  required
                  onChange={(e) => {
                    setAno(e.target.value);
                    setCargando(true)
                  }}
                >
                  <option value="0">Selecciona un Año</option>
                  {years.map((a) => {
                    return <option value={a}>{a}</option>;
                  })}
                </Input>
              </Col>

          <Col md={2}>
                    <Label className="mr-sm-2">Cliente</Label>
                    <Autocomplete
                      size="small"
                      value={value}
                      onChange={(event, selectedCliente) => {
                        if (selectedCliente) {
                          setValue(selectedCliente)
                          setSelectedCliente(selectedCliente._id)
                          setCargando(true)
                        }
                      }}
                      options={options.sort(
                        (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                      )}
                      groupBy={(option) => option.firstLetter}
                      getOptionLabel={(option) => option.junta}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Selecciona"
                          variant="outlined"
                        />
                      )}
                      renderOption={(option) => (
                        <React.Fragment>
                          {option.codigo} {option.nombre_comercial}
                        </React.Fragment>
                      )}
                    />
                  </Col>
        </Row>
        </div>
        <br />
        <Table size="sm" striped borderless className="table-responsive-xl">
        <thead>
          <tr>
            <th>Mes</th>
            <th>Año {ano ? (ano-1): (ano)} </th>
            <th>Año {ano}</th>
            <th>Diferencia</th>
          </tr>
          </thead>
          <tbody>
          {graficas.map((a)=>{
            totImpAnt = totImpAnt + a.ImpAnterior
            totImpAct = totImpAct + a.ImpActual
            let porcentaje = a.ImpAnterior > 0 ? ((a.ImpActual - a.ImpAnterior)/a.ImpAnterior)*100 : 100;
            porcTotal = ((totImpAct - totImpAnt)/totImpAnt)*100
            return(
              <tr>
                <td>{a.Mes}</td>
                <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.ImpAnterior)}</td>
                <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.ImpActual)}</td>
                <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.ImpActual - a.ImpAnterior)}</td>
              </tr>
            )
          })}

<tr className="negrita">
              <td>TOTAL</td>
              <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totImpAnt)}</td>
              <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totImpAct)}</td>
              <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totImpAnt - totImpAct)}</td>
              <td>{(porcTotal).toFixed(2)}%</td>
            </tr>
            </tbody>
        </Table>
            </div>
    {/*  ) : undefined} */}
  </>
  );
}
export default TablaVentasMensualComparativaCliente;
