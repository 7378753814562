import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import Header from "../../layout/Header/Header";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Spinner,
  Form,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import { TableHeader, Pagination, Search } from "../../DataTable";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import qrcode from "qrcode-js";
import moment from "moment";

function ReporteResumenVentasTiendas() {
  const { user } = useContext(AuthContext);
  const URL_VENTAS_TIENDAS = process.env.REACT_APP_URL_VENTAS_TIENDAS;


  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  const [ventasTiendas, setVentasTiendas] = useState([]);
  const [TEF, setTEF] = useState(0);
  const [selectedFechaInicio, setSelectedFechaInicio] = useState(hoy);
  const [selectedFechaFin, setSelectedFechaFin] = useState(hoy);




  useMemo(() => {
      axios
      .get(
        `${URL_VENTAS_TIENDAS}VentasDiarioGeneral/${selectedFechaInicio}/${selectedFechaFin}`,
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then((response) => {
        let allVentasTiendas = response.data;

        let arrayVentasTiendas = allVentasTiendas
          .map((a) => {
              return {
                    fecha: a.fecha,
                    is_active: a.is_active,
                    importe: a.total_general,
                    areas: a.areas[0]._id,
                    areasName: a.areas[0].name,
              };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataVentasTiendas = Object.values(arrayVentasTiendas);

        let agrupadoVentasTiendas = dataVentasTiendas.reduce(function (groups, item) {
          const val = item["areas"];
          groups[val] = groups[val] || {
            areas: item.areas,
            importe: 0,
          };
          groups[val].importe += item.importe;
          groups[val].areas = item.areas;
          groups[val].areasName = item.areasName;
          return groups;
        }, []);

        let dataVentasTiendas2 = Object.values(agrupadoVentasTiendas);

        setVentasTiendas(dataVentasTiendas2);

      })

  }, [selectedFechaInicio, selectedFechaFin]);


  let totalATImporte = 0

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_reportes ? (
        <div className="card container col-6">
          <Row>
            <Col md={8}>
              <Button
                size="sm"
                href="/MenuReportesVentas"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
          </Row>
          <h3 align="center">Resumen Ventas Tiendas</h3>
          <Row>
            <Col md={4}>
              <Label className="mr-sm-2">Fecha Inicio</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaInicio}
                required
                onChange={(e) => {
                  setSelectedFechaInicio(e.target.value);
                }}
              />
            </Col>
            <Col md={4}>
              <Label className="mr-sm-2">Fecha Fin</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaFin}
                required
                onChange={(e) => {
                  setSelectedFechaFin(e.target.value);
                }}
              />
            </Col>
          </Row>
          <br />

          <Table size="sm" striped borderless className="table-responsive-xl">
            <tbody>
              <tr>
                <th>Tienda</th>
                <th>Total</th>
              </tr>
              {ventasTiendas.map((a) => {
                totalATImporte = totalATImporte + a.importe
                return (
                  <tr>
                    <td>{a.areasName}</td>
                    <td>{new Intl.NumberFormat("en-US", { style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.importe)}</td>
                  </tr>
                );
              })}
              <tr>
              <td className="negrita" align="right">Total</td>
              <td className="negrita">{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalATImporte + TEF)}</td>
           
              </tr>
              
            </tbody>
          </Table>


          
        </div>
      ) : undefined}
      <br />

    </>
  );
}

export default ReporteResumenVentasTiendas;
