import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { Redirect } from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  NavLink,
  NavbarText,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab, faFacebook, fas } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCoffee,
  faCog,
  faSpinner,
  faQuoteLeft,
  faSquare,
  faCheckSquare,
  faHome,
  faEnvelope,
  faPhoneAlt,
} from "@fortawesome/free-solid-svg-icons";
import Password from "../Login/Password";
import axios from "axios";

library.add(
  fab,
  faCoffee,
  faCog,
  faSpinner,
  faQuoteLeft,
  faSquare,
  faCheckSquare,
  faFacebook,
  faHome,
  faEnvelope,
  faPhoneAlt
);

const Header = (props) => {
  const AREAS_GENERAL = process.env.REACT_APP_AREAS_GENERAL;
  const AREAS_MATRIZ = process.env.REACT_APP_AREAS_MATRIZ;
  const AREAS_PANTACO = process.env.REACT_APP_AREAS_PANTACO;
  const AREAS_BODEGA = process.env.REACT_APP_AREAS_BODEGA;
  const AREAS_ELI = process.env.REACT_APP_AREAS_ELI;
  const AREAS_ROLANDO = process.env.REACT_APP_AREAS_ROLANDO;
  const AREAS_MAGALI = process.env.REACT_APP_AREAS_MAGALI;
  const URL_WHATSAPP = process.env.REACT_APP_URL_WHATSAPP;

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleLogout = () => setDropdownOpen((prevState) => !prevState);

  const [dropdownOpenModelos, setDropdownOpenModelos] = useState(false);
  const toggleModelos = () => setDropdownOpenModelos((prevState) => !prevState);

  const [dropdownOpenCompras, setDropdownOpenCompras] = useState(false);
  const toggleCompras = () => setDropdownOpenCompras((prevState) => !prevState);

  const [dropdownOpenVentas, setDropdownOpenVentas] = useState(false);
  const toggleVentas = () => setDropdownOpenVentas((prevState) => !prevState);

  const [dropdownOpenCadenas, setDropdownOpenCadenas] = useState(false);
  const toggleCadenas = () => setDropdownOpenCadenas((prevState) => !prevState);

  const [dropdownOpenFinanzas, setDropdownOpenFinanzas] = useState(false);
  const toggleFinanzas = () =>
    setDropdownOpenFinanzas((prevState) => !prevState);

  const [dropdownOpenTiendas, setDropdownOpenTiendas] = useState(false);
  const toggleTiendas = () => setDropdownOpenTiendas((prevState) => !prevState);

  const [dropdownOpenInventarios, setDropdownOpenInventarios] = useState(false);
  const toggleInventarios = () =>
    setDropdownOpenInventarios((prevState) => !prevState);

  const [dropdownOpenGastos, setDropdownOpenGastos] = useState(false);
  const toggleGastos = () => setDropdownOpenGastos((prevState) => !prevState);

  const [dropdownOpenEmpleados, setDropdownOpenEmpleados] = useState(false);
  const toggleEmpleados = () =>
    setDropdownOpenEmpleados((prevState) => !prevState);

  const [dropdownOpenAdmin, setDropdownOpenAdmin] = useState(false);
  const toggleAdmin = () => setDropdownOpenAdmin((prevState) => !prevState);

  const [dropdownOpenCXP, setDropdownOpenCXP] = useState(false);
  const toggleCXP = () => setDropdownOpenCXP((prevState) => !prevState);

  const [dropdownOpenReportes, setDropdownOpenReportes] = useState(false);
  const toggleReportes = () =>
    setDropdownOpenReportes((prevState) => !prevState);

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const { isAuth, user } = useContext(AuthContext);
  const [modalPassword, setModalPassword] = useState(false);
  const togglePassword = () => setModalPassword(!modalPassword);

  const [status, setStatus] = useState("Esperando Estado...");

  useEffect(() => {
    axios
    .get(`${URL_WHATSAPP}Status`, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((response) => {
      let allStatus = response.data.state;
      setStatus(allStatus);
    })
    .catch((err) => {
      console.log(err);
    });
  })

  return (
    <>
      {isAuth ? (
        <header className="sticky-top">
          {!user.menu_edoCta_Cliente && !user.menu_cobradores ? (
            <Navbar color="dark" dark expand="lg">
              {/* <a href="https://giovannigali.sistemify.com/"> */}
              <a href="/Inicio">
                <img
                  src={process.env.PUBLIC_URL + "/LogoSistemify2RS.png"}
                  height="50 px"
                  alt="logo"
                />
              </a>
              <NavbarToggler onClick={toggle} />
              <Collapse isOpen={isOpen} navbar>
                <Nav className="mr-auto" navbar>
                  {
                    // user.vendedor == "Si" ? undefined:
                    user.areas != AREAS_ELI &&
                    user.areas != AREAS_ROLANDO &&
                    user.areas != AREAS_MAGALI ? (
                      <>
                        {user.menu_catalogos &&
                        (AREAS_MATRIZ == user.areas ||
                          AREAS_PANTACO == user.areas ||
                          AREAS_GENERAL == user.areas) ? (
                          <NavItem>
                            <NavLink className="titulosNav">
                              <Dropdown
                                isOpen={dropdownOpenModelos}
                                toggle={toggleModelos}
                              >
                                <DropdownToggle color="link">
                                  CATALOGOS
                                </DropdownToggle>
                                <DropdownMenu>
                                  {/* <div class="dropdown-divider"></div> */}
                                  <DropdownItem href="/ListadoArticulos">
                                    Modelos
                                  </DropdownItem>
                                  <DropdownItem href="/ListadoArticulosFoto">
                                    Fotos Modelos
                                  </DropdownItem>
                                  <DropdownItem href="/Lineas">
                                    Lineas
                                  </DropdownItem>
                                  <DropdownItem href="/Marcas">
                                    Marcas
                                  </DropdownItem>
                                  <DropdownItem href="/Familias">
                                    Familias
                                  </DropdownItem>
                                  <DropdownItem href="/Colecciones">
                                    Colecciones
                                  </DropdownItem>
                                  <div class="dropdown-divider"></div>
                                  <DropdownItem href="/Proveedores">
                                    Proveedores
                                  </DropdownItem>
                                  <div class="dropdown-divider"></div>
                                  <DropdownItem href="/Clientes">
                                    Clientes
                                  </DropdownItem>
                                  {/* <DropdownItem href="/DepartamentosClientes">
                                    Departamentos Clientes
                                  </DropdownItem> */}
                                  <DropdownItem href="/Paqueterias">
                                    Paqueterias
                                  </DropdownItem>
                                  <DropdownItem href="/Grupos">
                                    Grupos Clientes
                                  </DropdownItem>
                                  <div class="dropdown-divider"></div>
                                  <DropdownItem href="/ConceptosGastos">
                                    Conceptos Gastos
                                  </DropdownItem>
                                  <DropdownItem href="/ConceptosGastosEmbarques">
                                    Gastos Embarques
                                  </DropdownItem>
                                  <div class="dropdown-divider"></div>
                                  <DropdownItem href="/Bancos">
                                    Bancos
                                  </DropdownItem>
                                  {/* <div class="dropdown-divider"></div>
                                  <DropdownItem href="/MenuConfiguraciones">
                                    Configuracion
                                  </DropdownItem> */}
                                  <div class="dropdown-divider"></div>
                                  <DropdownItem href="/Areas">
                                    Areas
                                  </DropdownItem>
                                <div class="dropdown-divider"></div>
                                  <DropdownItem href="/ProveedoresMex">
                                    Proveedores Insumos
                                  </DropdownItem>
                                  <DropdownItem href="/ListadoInsumos">
                                    Insumos
                                  </DropdownItem>
                                  <DropdownItem href="/ListadoCadenas">
                                    Cadenas
                                  </DropdownItem>
                                  {/* <div class="dropdown-divider"></div> */}
                                </DropdownMenu>
                              </Dropdown>
                            </NavLink>
                          </NavItem>
                        ) : undefined}

                        {user.menu_compras &&
                        (AREAS_MATRIZ == user.areas ||
                          AREAS_PANTACO == user.areas ||
                          AREAS_GENERAL == user.areas) ? (
                          <NavItem>
                            <NavLink className="titulosNav">
                              <Dropdown
                                isOpen={dropdownOpenCompras}
                                toggle={toggleCompras}
                              >
                                <DropdownToggle color="link">
                                  COMPRAS
                                </DropdownToggle>
                                <DropdownMenu>
                                  <DropdownItem href="/ListadoOrdenesCompra">
                                    Ordenes de Produccion
                                  </DropdownItem>
                                  <DropdownItem href="/ListadoEmbarques">
                                    Embarques
                                  </DropdownItem>
                                  <DropdownItem href="/ListadoCompras">
                                    Entradas
                                  </DropdownItem>
                                  <DropdownItem href="/ListadoComprasCXP">
                                    Notas CxP
                                  </DropdownItem>
                                  <DropdownItem href="/ListadoPendRecibir">
                                    Pendiente Recibir
                                  </DropdownItem>
                                  <DropdownItem href="/ListadoPendEmbarcar">
                                    Pendiente Embarcar
                                  </DropdownItem>
                                  {/* <DropdownItem href="/ListadoControlCalidad">Control Calidad</DropdownItem> */}
                                </DropdownMenu>
                              </Dropdown>
                            </NavLink>
                          </NavItem>
                        ) : undefined}

                        {user.menu_ventas &&
                        (AREAS_MATRIZ == user.areas ||
                          AREAS_PANTACO == user.areas ||
                          AREAS_GENERAL == user.areas) ? (
                          <NavItem>
                            <NavLink className="titulosNav">
                              <Dropdown
                                isOpen={dropdownOpenVentas}
                                toggle={toggleVentas}
                              >
                                <DropdownToggle color="link">
                                  VENTAS
                                </DropdownToggle>
                                <DropdownMenu>
                                  <DropdownItem href="/ListadoPedidos">
                                    Pedidos
                                  </DropdownItem>
                                  <DropdownItem href="/PedidosExpoCreate">
                                    Pedidos Expo
                                  </DropdownItem>
                                  <DropdownItem href="/ListadoPendienteSurtir">
                                    Surtir
                                  </DropdownItem>
                                  <DropdownItem href="/ListadoDevoluciones">
                                    Devoluciones
                                  </DropdownItem>
                                  <DropdownItem href="/ListadoVentas">
                                    Ventas
                                  </DropdownItem>
                                  <DropdownItem href="/AnalisisArticulosPedidos">
                                    Analisis Pedidos
                                  </DropdownItem>
                                  <DropdownItem href="/ListoSurtir">
                                    Listo Surtir
                                  </DropdownItem>
                                  <DropdownItem href="/ListoSurtirCliente">
                                    Listo Surtir Cliente
                                  </DropdownItem>
                                  <div class="dropdown-divider"></div>
                                  <DropdownItem href="/ReporteUltimoPrecio">
                                    Ultimo Precio Cliente
                                  </DropdownItem>
                                  <DropdownItem href="/ReporteUltimoPrecioArticulo">
                                    Ultimo Precio Articulo
                                  </DropdownItem>
                                </DropdownMenu>
                              </Dropdown>
                            </NavLink>
                          </NavItem>
                        ) : undefined}

                        {user.menu_ventas &&
                        (AREAS_MATRIZ == user.areas ||
                          AREAS_PANTACO == user.areas ||
                          AREAS_GENERAL == user.areas) ? (
                          <NavItem>
                            <NavLink className="titulosNav">
                              <Dropdown
                                isOpen={dropdownOpenCadenas}
                                toggle={toggleCadenas}
                              >
                                <DropdownToggle color="link">
                                  CADENAS
                                </DropdownToggle>
                                <DropdownMenu>
                                  <DropdownItem href="/ListadoOrdenesCompraHab">
                                    Ordenes Compra
                                  </DropdownItem>
                                  <DropdownItem href="/ListadoComprasHab">
                                    Compras
                                  </DropdownItem>
                                  <DropdownItem href="/ListadoInventariosInsumos">
                                    Inventario Insumos
                                  </DropdownItem>
                                  <DropdownItem href="/ListadoPedidosCadenas">
                                    Pedidos Cadenas
                                  </DropdownItem>
                                  <DropdownItem href="/EstadoCuentaHab">
                                    Estado Cuenta
                                  </DropdownItem>
                                  <DropdownItem href="/ListadoAbonosProveedoresHab">
                                    Abonos
                                  </DropdownItem>
                                </DropdownMenu>
                              </Dropdown>
                            </NavLink>
                          </NavItem>
                        ) : undefined}

                        {user.menu_admin &&
                        (AREAS_MATRIZ == user.areas ||
                          AREAS_PANTACO == user.areas ||
                          AREAS_GENERAL == user.areas) ? (
                          <NavItem>
                            <NavLink className="titulosNav">
                              <Dropdown
                                isOpen={dropdownOpenFinanzas}
                                toggle={toggleFinanzas}
                              >
                                <DropdownToggle color="link">
                                  FINANZAS
                                </DropdownToggle>
                                <DropdownMenu>
                                  <DropdownItem href="/EstadoCuenta">
                                    Estados de Cuenta Clientes
                                  </DropdownItem>
                                  <DropdownItem href="/ListadoAnticipos">
                                    Anticipos
                                  </DropdownItem>
                                  <DropdownItem href="/ListadoAbonos">
                                    Abonos
                                  </DropdownItem>
                                  <DropdownItem href="/ListadoNotasCargo">
                                    Notas de Cargo
                                  </DropdownItem>
                                  <DropdownItem href="/ListadoDevolucionesAdmin">
                                    Aplicar Devoluciones
                                  </DropdownItem>
                                  <DropdownItem href="/ListadoAbonosProveedores">
                                    Pagos Proveedores
                                  </DropdownItem>
                                  <DropdownItem href="/EstadoCuentaCXP">
                                    Estados de Cuenta Proveedores
                                  </DropdownItem>
                                  <DropdownItem href="/ListadoComisiones">
                                    Comisiones
                                  </DropdownItem>
                                  {/* <DropdownItem href="/ListadoComisiones">Comisiones</DropdownItem>
                            <DropdownItem href="/ListadoTraspasosDinero">Traspasos Dinero</DropdownItem> */}
                                </DropdownMenu>
                              </Dropdown>
                            </NavLink>
                          </NavItem>
                        ) : undefined}

                        {user.menu_inventarios &&
                        (AREAS_MATRIZ == user.areas ||
                          AREAS_PANTACO == user.areas ||
                          AREAS_BODEGA == user.areas ||
                          AREAS_GENERAL == user.areas) ? (
                          <NavItem>
                            <NavLink className="titulosNav">
                              <Dropdown
                                isOpen={dropdownOpenInventarios}
                                toggle={toggleInventarios}
                              >
                                <DropdownToggle color="link">
                                  INVENTARIOS
                                </DropdownToggle>
                                <DropdownMenu>
                                  {/* <div class="dropdown-divider"></div> */}
                                  <DropdownItem href="/ListadoInventarios">
                                    Inventarios
                                  </DropdownItem>
                                  <DropdownItem href="/ListadoInventariosGeneral">
                                    Inventarios General
                                  </DropdownItem>
                                  {/* <DropdownItem href="/ListadoInventarioPendRecibir">
                                    Inventario Pendiente Recibir
                                  </DropdownItem> */}
                                  <DropdownItem href="/ListadoDisponibleGENERAL">
                                    Disponible General
                                  </DropdownItem>
                                  <DropdownItem href="/ListadoDisponible">
                                    Disponible Matriz
                                  </DropdownItem>
                                  {/* <DropdownItem href="/ListadoDisponibleVendedor">
                                    Disponible Vendedor
                                  </DropdownItem> */}
                                  {/* <DropdownItem href="/ListadoDisponibleBODEGA">
                                    Disponible Bodega Ricardo
                                  </DropdownItem> 
                                  <DropdownItem href="/ListadoDisponiblePantaco">
                                    Disponible Pantaco
                                  </DropdownItem>
                                  <DropdownItem href="/ListadoDisponiblePantaco83">
                                    Disponible Pantaco 83
                                  </DropdownItem>*/}
                                  <DropdownItem href="/ListadoDisponiblePantacos">
                                    Disponible Pantacos
                                  </DropdownItem>
                                  <DropdownItem href="/ListadoExistenciaDisponiblePantaco">
                                    Existencia Pantaco
                                  </DropdownItem>
                                  <DropdownItem href="/ListadoExistenciaDisponiblePantaco83">
                                    Existencia Pantaco 83
                                  </DropdownItem>
                                  <DropdownItem href="/ListadoExistenciaDisponibleTIM81">
                                    Existencia TIM 81
                                  </DropdownItem>
                                  <DropdownItem href="/ListadoExistenciaDisponibleBodega">
                                    Existencia Bodega Ricardo
                                  </DropdownItem>
                                  <div class="dropdown-divider"></div>
                                  <DropdownItem href="/MovimientosRicardo">
                                    Movimientos Ricardo
                                  </DropdownItem>
                                  <DropdownItem href="/ListadoTracking">
                                    Tracking
                                  </DropdownItem>
                                  <DropdownItem href="/ListadoKardex">
                                    Kardex
                                  </DropdownItem>
                                  <div class="dropdown-divider"></div>
                                  <DropdownItem href="/MenuTraspasos">
                                    Traspasos
                                  </DropdownItem>
                                  <div class="dropdown-divider"></div>
                                  {/* <DropdownItem href="/ListadoPendienteTraspasar">Pendiente Traspasar</DropdownItem>
                            <DropdownItem href="/ListadoTraspasosSalida">Traspasos Salida</DropdownItem>
                            <DropdownItem href="/ListadoTraspasosEntrada">Traspasos Entrada</DropdownItem> */}
                                  <DropdownItem href="/ListadoMuestrasSalida">
                                    Muestras Salida
                                  </DropdownItem>
                                  <DropdownItem href="/ListadoMuestrasEntrada">
                                    Muestras Entrada
                                  </DropdownItem>
                                  <div class="dropdown-divider"></div>
                                  <DropdownItem href="/ListadoAjustesInventario">
                                    Ajuste Inventario
                                  </DropdownItem>
                                  {/* <div class="dropdown-divider"></div> */}
                                </DropdownMenu>
                              </Dropdown>
                            </NavLink>
                          </NavItem>
                        ) : undefined}

                        {user.menu_admin &&
                        (AREAS_MATRIZ == user.areas ||
                          AREAS_PANTACO == user.areas ||
                          AREAS_GENERAL == user.areas) ? (
                          <NavItem>
                            <NavLink className="titulosNav">
                              <Dropdown
                                isOpen={dropdownOpenGastos}
                                toggle={toggleGastos}
                              >
                                <DropdownToggle color="link">
                                  GASTOS
                                </DropdownToggle>
                                <DropdownMenu>
                                  <DropdownItem href="/Gastos">
                                    Gastos
                                  </DropdownItem>
                                </DropdownMenu>
                              </Dropdown>
                            </NavLink>
                          </NavItem>
                        ) : undefined}

                        {user.menu_empleados &&
                        (AREAS_MATRIZ == user.areas ||
                          AREAS_PANTACO == user.areas ||
                          AREAS_GENERAL == user.areas) ? (
                          <NavItem>
                            <NavLink className="titulosNav">
                              <Dropdown
                                isOpen={dropdownOpenEmpleados}
                                toggle={toggleEmpleados}
                              >
                                <DropdownToggle color="link">
                                  USUARIOS
                                </DropdownToggle>
                                <DropdownMenu>
                                  <DropdownItem href="/Empleados">
                                    Usuarios
                                  </DropdownItem>
                                  <DropdownItem href="/Puestos">
                                    Puestos
                                  </DropdownItem>
                                  <DropdownItem href="/MenuUsuarios">
                                    Permisos
                                  </DropdownItem>
                                  {/* <DropdownItem href="/Nominas">Nominas</DropdownItem>
                      <DropdownItem href="/FaltasColaboradores">Faltas Empleados</DropdownItem> */}
                                </DropdownMenu>
                              </Dropdown>
                            </NavLink>
                          </NavItem>
                        ) : undefined}

                        {/* {user.pagoProveedores &&
                      (AREAS_MATRIZ == user.areas ||
                        AREAS_PANTACO == user.areas ||
                        AREAS_GENERAL == user.areas) ? (
                          <NavItem>
                          <NavLink className="titulosNav" >
                            <Dropdown isOpen={dropdownOpenCXP} toggle={toggleCXP}>
                            <DropdownToggle color="link">
                            CXP
                          </DropdownToggle>
                          <DropdownMenu>
                          </DropdownMenu>
                          </Dropdown>
                          </NavLink>
                          </NavItem>
                            ) : undefined} */}

                        {/* {user.configuracion &&
                      (AREAS_MATRIZ == user.areas ||
                        AREAS_PANTACO == user.areas ||
                        AREAS_GENERAL == user.areas) ? (
                          <NavItem>
                          <NavLink className="titulosNav" href="/MenuConfiguraciones">
                          <DropdownToggle color="link">
                           CONFIG
                          </DropdownToggle>
                          </NavLink>
                        </NavItem>
                      ) : undefined} */}

                  {user.menu_admin &&
                        (AREAS_MATRIZ == user.areas ||
                          AREAS_PANTACO == user.areas ||
                          AREAS_GENERAL == user.areas) ? (
                          <NavItem>
                            <NavLink href="/WhatsApp">
                              <DropdownToggle color="link" style={{ color: status === 2 ? 'green' : 'red' }} >
                                WHATSAPP
                              </DropdownToggle>
                            </NavLink>
                          </NavItem>
                        ) : undefined}

                        {user.menu_reportes &&
                        (AREAS_MATRIZ == user.areas ||
                          AREAS_PANTACO == user.areas ||
                          AREAS_GENERAL == user.areas) ? (
                          <NavItem>
                            <NavLink
                              className="titulosNav"
                              href="/MenuReportes"
                            >
                              <DropdownToggle color="link">
                                REPORTES
                              </DropdownToggle>
                            </NavLink>
                          </NavItem>
                        ) : undefined}

                        {/* {user.menu_usuarios &&
                      (AREAS_MATRIZ == user.areas ||
                        AREAS_PANTACO == user.areas ||
                        AREAS_GENERAL == user.areas) ? (
                          <NavItem>
                          <NavLink className="titulosNav" href="/MenuUsuarios">
                          <DropdownToggle color="link">
                           USUARIOS
                          </DropdownToggle>
                          </NavLink>
                        </NavItem>
                      ) : undefined} */}

                        {user.menu_logs &&
                        (AREAS_MATRIZ == user.areas ||
                          AREAS_PANTACO == user.areas ||
                          AREAS_GENERAL == user.areas) ? (
                          <NavItem>
                            <NavLink className="titulosNav" href="/MenuLogs">
                              <DropdownToggle color="link">LOGS</DropdownToggle>
                            </NavLink>
                          </NavItem>
                        ) : undefined}

                        {user.menuTiendas &&
                        AREAS_MATRIZ != user.areas &&
                        AREAS_PANTACO != user.areas ? (
                          <NavItem>
                            <NavLink className="titulosNav" href="/MenuTiendas">
                              <DropdownToggle color="link">
                                TIENDAS
                              </DropdownToggle>
                            </NavLink>
                          </NavItem>
                        ) : undefined}
                      </>
                    ) : (
                      <>
                        {user.menuTiendas ? (
                          <NavItem>
                            <NavLink className="titulosNav" href="/MenuTiendas">
                              <DropdownToggle color="link">
                                TIENDAS
                              </DropdownToggle>
                            </NavLink>
                          </NavItem>
                        ) : undefined}
                      </>
                    )
                  }
                </Nav>
                <NavbarText>
                  <NavLink className="titulosNav">
                    <Dropdown
                      direction="left"
                      isOpen={dropdownOpen}
                      toggle={toggleLogout}
                    >
                      <DropdownToggle color="link">
                        <i class="fas fa-user-cog fa-2x"></i>
                      </DropdownToggle>
                      <DropdownMenu right>
                        <DropdownItem href="/logout">Logout</DropdownItem>
                        <DropdownItem onClick={togglePassword}>
                          Password
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </NavLink>
                </NavbarText>
                <Modal size="sm" isOpen={modalPassword} toggle={togglePassword}>
                  <ModalHeader toggle={togglePassword}>
                    <h4>Cambio de contraseña</h4>
                  </ModalHeader>
                  <ModalBody>
                    <Password />
                  </ModalBody>
                </Modal>
              </Collapse>
            </Navbar>
          ) : (
            <Navbar color="dark" dark expand="md">
              <a href="/Inicio">
                <img
                  src={process.env.PUBLIC_URL + "/LogoSistemify2RS.png"}
                  height="50 px"
                  alt="logo"
                />
              </a>
              <NavbarToggler onClick={toggle} />
              <Collapse isOpen={isOpen} navbar>
                <NavbarText>
                  <NavLink className="titulosNav" href="/logout"></NavLink>
                </NavbarText>
              </Collapse>
            </Navbar>
          )}
        </header>
      ) : undefined}
    </>
  );
};

export default Header;
