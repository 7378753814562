import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import Header from "../../layout/Header/Header";
import {
  Table,
  Input,
  Col,
  Row
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import axios from "axios";
import moment from 'moment';


function Locker() {
  const { user } = useContext(AuthContext);
  const URL_LOCKER = process.env.REACT_APP_URL_LOCKER;
  const URL_ARTICULOS_OC_PEND_EMBARCAR = process.env.REACT_APP_URL_ARTICULOS_OC_PEND_EMBARCAR;
  const URL_ARTICULOS_EMBARQUES = process.env.REACT_APP_URL_ARTICULOS_EMBARQUES;
  const URL_DASHBOARDS = process.env.REACT_APP_URL_DASHBOARDS;

// Crear una nueva instancia de la fecha de hoy
let hoy = new Date();

let diaDeLaSemana = hoy.getDay(); // Obtiene el día de la semana (0 es domingo, 1 es lunes, etc.)

// Si hoy es domingo (0), queremos retroceder 6 días para obtener el lunes anterior
let diferenciaDias = diaDeLaSemana === 0 ? 6 : diaDeLaSemana - 1;

let lunesDeEstaSemana = new Date(hoy);
lunesDeEstaSemana.setDate(hoy.getDate() - diferenciaDias);


let endDate   = moment(hoy).format("YYYY-MM-DD"); 
let startDate   = moment(lunesDeEstaSemana).format("YYYY-MM-DD");
let semana   = moment(lunesDeEstaSemana).format("DD-MMM-YYYY");

let anoActual = startDate.slice(0, 4)


  const [infoAcumuladoVendedores, setInfoAcumuladoVendedores] = useState([]);
  const [infoAcumuladoAreas, setInfoAcumuladoAreas] = useState([]);
  const [TotalDineroAno, setTotalDineroAno] = useState(0);
  const [TotalPiezasAno, setTotalPiezasAno] = useState(0);

  const [infoSemanaVendedores, setInfoSemanaVendedores] = useState([]);
  const [infoSemanaAreas, setInfoSemanaAreas] = useState([]);
  const [TotalDineroSemana, setTotalDineroSemana] = useState(0);
  const [TotalPiezasSemana, setTotalPiezasSemana] = useState(0);

  const [inventario, setInventario] = useState([]);
  const [inventarioTotalPiezas, setInventarioTotalPiezas] = useState(0);
  const [inventarioTotalDinero, setInventarioTotalDinero] = useState(0);

  const [pendEmbarcar, setPendEmbarcar] = useState([]);
  const [pendEmbarcarTotal, setPendEmbarcarTotal] = useState(0);
  const [pendRecibir, setPendRecibir] = useState([]);
  const [pendRecibirTotal, setPendRecibirTotal] = useState(0);

  const [totalEnTiempoGeneral, setTotalEnTiempoGeneral] = useState(0);
  const [totalSaldoGeneral, setTotalSaldoGeneral] = useState(0);
  const [totalTreintaGeneral, setTotalTreintaGeneral] = useState(0);
  const [totalSesentaGeneral, setTotalSesentaGeneral] = useState(0);
  const [totalNoventaGeneral, setTotalNoventaGeneral] = useState(0);
  const [totalCientoVeinteGeneral, setTotalCientoVeinteGeneral] = useState(0);
  const [totalCientoOchentaGeneral, setTotalCientoOchentaGeneral] = useState(0);
  const [totalMasGeneral, setTotalMasGeneral] = useState(0);

  const [cobranzaVendedores, setCobranzaVendedores] = useState([]);
  const [cobranzaVendedoresTotal, setCobranzaVendedoresTotal] = useState([]);

  const [selectedFechaInicio, setSelectedFechaInicio] = useState(startDate);
  const [selectedFechaFin, setSelectedFechaFin] = useState(endDate);


  
  useEffect(() => {
    axios
      .get(`${URL_LOCKER}VentasSemana/${selectedFechaInicio}/${selectedFechaFin}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allInfo = response.data;

        let TEfe = allInfo.map((c) => parseFloat(c.TotalDinero));
        let TE = TEfe.reduce((t, total, index) => t + total, 0);
        setTotalDineroSemana(TE)

        let TPZ = allInfo.map((c) => parseFloat(c.TotalPiezas));
        let TP = TPZ.reduce((t, total, index) => t + total, 0);
        setTotalPiezasSemana(TP)

        let agrupadoVendedor = allInfo.reduce(function (groups, item) {
          const val = item["idVendedor"];
          groups[val] = groups[val] || {
            idVendedor: item.idVendedor,
            TotalDinero: 0,
            TotalPiezas: 0,
            devolucionesDinero: 0,
            devolucionesPiezas: 0,
          };
          groups[val].TotalDinero += item.TotalDinero;
          groups[val].TotalPiezas += item.TotalPiezas;
          groups[val].DevolucionesDinero += item.DevolucionesDinero;
          groups[val].DevolucionesPiezas += item.DevolucionesPiezas;
          groups[val].Vendedor = item.Vendedor;
          groups[val].idVendedor = item.idVendedor;
          return groups;
        }, []);

        let otraVendedor = Object.values(agrupadoVendedor)

        setInfoSemanaVendedores(otraVendedor);

        let agrupadoArea = allInfo.reduce(function (groups, item) {
          const val = item["idArea"];
          groups[val] = groups[val] || {
            idArea: item.idArea,
            TotalDinero: 0,
            TotalPiezas: 0,
            devolucionesDinero: 0,
            devolucionesPiezas: 0,
          };
          groups[val].TotalDinero += item.TotalDinero;
          groups[val].TotalPiezas += item.TotalPiezas;
          groups[val].DevolucionesDinero += item.DevolucionesDinero;
          groups[val].DevolucionesPiezas += item.DevolucionesPiezas;
          groups[val].Area = item.Area;
          groups[val].idArea = item.idArea;
          return groups;
        }, []);

        let otraArea = Object.values(agrupadoArea)

        setInfoSemanaAreas(otraArea);


      })
      .catch((err) => {
        console.log(err);
      });

      // Acumulado
      axios
      .get(`${URL_LOCKER}VentasAcumulado/${selectedFechaInicio}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allInfo = response.data;


        let agrupadoVendedor = allInfo.reduce(function (groups, item) {
          const val = item["idVendedor"];
          groups[val] = groups[val] || {
            idVendedor: item.idVendedor,
            TotalDinero: 0,
            TotalPiezas: 0,
            devolucionesDinero: 0,
            devolucionesPiezas: 0,
          };
          groups[val].TotalDinero += item.TotalDinero;
          groups[val].TotalPiezas += item.TotalPiezas;
          groups[val].DevolucionesDinero += item.DevolucionesDinero;
          groups[val].DevolucionesPiezas += item.DevolucionesPiezas;
          groups[val].Vendedor = item.Vendedor;
          groups[val].idVendedor = item.idVendedor;
          return groups;
        }, []);

        let otraVendedor = Object.values(agrupadoVendedor)

        setInfoAcumuladoVendedores(otraVendedor);


        let TEfe = allInfo.map((c) => parseFloat(c.TotalDinero));
        let TE = TEfe.reduce((t, total, index) => t + total, 0);
        setTotalDineroAno(TE)

        let TPZ = allInfo.map((c) => parseFloat(c.TotalPiezas));
        let TP = TPZ.reduce((t, total, index) => t + total, 0);
        setTotalPiezasAno(TP)

        let agrupadoArea = allInfo.reduce(function (groups, item) {
          const val = item["idArea"];
          groups[val] = groups[val] || {
            idArea: item.idArea,
            TotalDinero: 0,
            TotalPiezas: 0,
            devolucionesDinero: 0,
            devolucionesPiezas: 0,
          };
          groups[val].TotalDinero += item.TotalDinero;
          groups[val].TotalPiezas += item.TotalPiezas;
          groups[val].DevolucionesDinero += item.DevolucionesDinero;
          groups[val].DevolucionesPiezas += item.DevolucionesPiezas;
          groups[val].Area = item.Area;
          groups[val].idArea = item.idArea;
          return groups;
        }, []);

        let otraArea = Object.values(agrupadoArea)

        setInfoAcumuladoAreas(otraArea);

      })
      .catch((err) => {
        console.log(err);
      });

       // Inventario
       axios
       .get(`${URL_LOCKER}Inventario`, {
         headers: {
           Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
         },
       })
       .then((response) => {
         let allInfo = response.data;
         setInventario(allInfo);

         let TEfe = allInfo.map((c) => parseFloat(c.TotalDinero));
         let TE = TEfe.reduce((t, total, index) => t + total, 0);
         setInventarioTotalDinero(TE)
 
         let TPZ = allInfo.map((c) => parseFloat(c.TotalPiezas));
         let TP = TPZ.reduce((t, total, index) => t + total, 0);
         setInventarioTotalPiezas(TP)

        })
        .catch((err) => {
          console.log(err);
        });


        axios
        .get(URL_ARTICULOS_OC_PEND_EMBARCAR, {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((response) => {
          let allPendEmbarcar = response.data;
  
          setPendEmbarcar(allPendEmbarcar);

          let TPZ = allPendEmbarcar.map((c) => parseFloat(c.pendiente_embarcar));
          let TP = TPZ.reduce((t, total, index) => t + total, 0);
          setPendEmbarcarTotal(TP)
        })
        .catch((err) => {
          console.log(err);
        });
  
        axios
        .get(`${URL_ARTICULOS_EMBARQUES}PendRecibir`, {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((response) => {
          let allPendRecibir = response.data;
          setPendRecibir(allPendRecibir);

          let TPZ = allPendRecibir.map((c) => parseFloat(c.pendiente_recibir));
          let TP = TPZ.reduce((t, total, index) => t + total, 0);
          setPendRecibirTotal(TP)
        })
        .catch((err) => {
          console.log(err);
        });

        axios
        .get(`${URL_DASHBOARDS}AntiguedadSaldosVencido`, {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((response) => {
          let allVentas = response.data;
          // Array para pagination
          let arrayTabla = allVentas
          .sort((a, b) => (a.nombreCliente > b.nombreCliente ? 1 : -1))
            .map((a) => {
              return {
                saldo: a.saldo,
                enTiempo: a.enTiempo,
                treinta: a.treinta,
                sesenta: a.sesenta,
                noventa: a.noventa,
                cientoveinte: a.cientoveinte,
                cientoochenta: a.cientoochenta,
                otro: a.otro,
              }
                
            })
            .filter(function (el) {
              return el != null;
            });
  
          let data = Object.values(arrayTabla);
  
          


    let ven = data.map((c) => parseFloat(c.enTiempo));
      let Tven = ven.reduce((t, importe, index) => t + importe, 0);
      setTotalEnTiempoGeneral(Tven);

      let t = data.map((c) => parseFloat(c.treinta));
      let Tt = t.reduce((t, importe, index) => t + importe, 0);
      setTotalTreintaGeneral(Tt);

      let s = data.map((c) => parseFloat(c.sesenta));
      let Ts = s.reduce((t, importe, index) => t + importe, 0);
      setTotalSesentaGeneral(Ts);

      let n = data.map((c) => parseFloat(c.noventa));
      let Tn = n.reduce((t, importe, index) => t + importe, 0);
      setTotalNoventaGeneral(Tn);

      let v = data.map((c) => parseFloat(c.cientoveinte));
      let Tv = v.reduce((t, importe, index) => t + importe, 0);
      setTotalCientoVeinteGeneral(Tv);

      let o = data.map((c) => parseFloat(c.cientoochenta));
      let To = o.reduce((t, importe, index) => t + importe, 0);
      setTotalCientoOchentaGeneral(To);

      let m = data.map((c) => parseFloat(c.otro));
      let Tm = m.reduce((t, importe, index) => t + importe, 0);
      setTotalMasGeneral(Tm);
  
      setTotalSaldoGeneral(Tven + Tt + Ts + Tn + Tv + To + Tm);
  
          //
        })
        .catch((err) => {
          console.log(err);
        });

               // CobranzaVendedor
       axios
       .get(`${URL_LOCKER}CobranzaVendedor`, {
         headers: {
           Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
         },
       })
       .then((response) => {
         let allInfo = response.data;
         setCobranzaVendedores(allInfo);

         let TEfe = allInfo.map((c) => parseFloat(c.Saldo));
         let TE = TEfe.reduce((t, total, index) => t + total, 0);
         setCobranzaVendedoresTotal(TE)
 

        })
        .catch((err) => {
          console.log(err);
        });
        
  }, []);


  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_reportes ? (
        <>
        <div className="card container col-sm-12">
        <div className="card container col-sm-6">
          <h3 align="center">Dashboard</h3>

        <h4>Resumen General</h4>

          <Table>
            
          <thead>
                 
                  <tr>
                    <th></th>
                    <th>Piezas</th>
                    <th>Importe</th>
                </tr>
                 </thead>
                 <tbody>
                  <tr>
                    <th>Acumulado {anoActual}</th>
                    <td>{new Intl.NumberFormat("en-US").format(TotalPiezasAno)}</td>
                    <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalDineroAno)}</td>
                  </tr>

                  <tr>
                    <th>Semana del {semana}</th>
                    <td>{new Intl.NumberFormat("en-US").format(TotalPiezasSemana)}</td>
                    <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalDineroSemana)}</td>
                  </tr>
                 </tbody>
                </Table>
          
        </div>
        <hr />
        <h4>Resumen por Vendedor</h4>
        <Row>
        <div className="card container col-sm-5">
        <Table>
                 <thead>
                  <tr>
                    <th>Vendedor Acumulado {anoActual}</th>
                  </tr>
                  <tr>
                    <th>Vendedor</th>
                    <th>Piezas</th>
                    <th>Importe</th>
                  </tr>
                 </thead>
                 <tbody>
                 {infoAcumuladoVendedores.map((a) => (
                   <tr key={a.idVendedor}>
                     <td>{a.Vendedor}</td>
                     <td>{new Intl.NumberFormat("en-US").format(a.TotalPiezas)}</td>
                     <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.TotalDinero)}</td>
                   </tr>
                 ))}
                 </tbody>
                 
                </Table>
          
        </div>
        <div className="card container col-sm-5">
        <Table>
                 <thead>
                  <tr>
                    <th>Vendedor Semana {semana}</th>
                  </tr>
                  <tr>
                    <th>Vendedor</th>
                    <th>Piezas</th>
                    <th>Importe</th>
                  </tr>
                 </thead>
                 <tbody>
                 {infoSemanaVendedores.map((a) => (
                   <tr key={a.idVendedor}>
                     <td>{a.Vendedor}</td>
                     <td>{new Intl.NumberFormat("en-US").format(a.TotalPiezas)}</td>
                     <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.TotalDinero)}</td>
                   </tr>
                 ))}
                 </tbody>
                 
                </Table>
          
        </div>
        </Row>
        <hr />
        <h4>Resumen por Almacen</h4>
        <Row>
        <div className="card container col-sm-5">
        <Table>
                 <thead>
                  <tr>
                    <th>Almacen Acumulado {anoActual}</th>
                  </tr>
                  <tr>
                    <th>Almacen</th>
                    <th>Piezas</th>
                    <th>Importe</th>
                  </tr>
                 </thead>
                 <tbody>
                 {infoAcumuladoAreas.map((a) => (
                   <tr key={a.idArea}>
                     <td>{a.Area}</td>
                     <td>{new Intl.NumberFormat("en-US").format(a.TotalPiezas)}</td>
                     <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.TotalDinero)}</td>
                   </tr>
                 ))}
                 </tbody>
                 
                </Table>
          
        </div>
        <div className="card container col-sm-5">
        <Table>
                 <thead>
                  <tr>
                    <th>Almacen Semana {semana}</th>
                  </tr>
                  <tr>
                    <th>Almacen</th>
                    <th>Piezas</th>
                    <th>Importe</th>
                  </tr>
                 </thead>
                 <tbody>
                 {infoSemanaAreas.map((a) => (
                   <tr key={a.idArea}>
                     <td>{a.Area}</td>
                     <td>{new Intl.NumberFormat("en-US").format(a.TotalPiezas)}</td>
                     <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.TotalDinero)}</td>
                   </tr>
                 ))}
                 </tbody>
                 
                </Table>
          
        </div>
        </Row>
<hr />
        <h4>Resumen de Cobranza</h4>
        <Row>
        <div className="card container col-sm-10">
        <Table>
                 <thead>
                  <tr>
                    <th>Total General</th>
                    <th>En Tiempo</th>
                    <th>Vencido 30</th>
                    <th>Vencido 60</th>
                    <th>Vencido 90</th>
                    <th>Vencido 120</th>
                    <th>Vencido 180</th>
                    <th>Mas de 180</th>
                  </tr>
                  </thead>
                 <tbody>
                 <tr>
                <td > {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalSaldoGeneral)} </td>
                <td > {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalEnTiempoGeneral)}</td>
                <td > {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalTreintaGeneral)} </td>
                <td > {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalSesentaGeneral)} </td>
                <td > {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalNoventaGeneral)} </td>
                <td > {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalCientoVeinteGeneral)} </td>
                <td > {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalCientoOchentaGeneral)} </td>
                <td > {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalMasGeneral)} </td>
              </tr>

              <tr>
                <td > {new Intl.NumberFormat("en-US", {style: "percent", maximumFractionDigits: 2 }).format(totalSaldoGeneral / totalSaldoGeneral)} </td>
                <td > {new Intl.NumberFormat("en-US", {style: "percent", maximumFractionDigits: 2 }).format(totalEnTiempoGeneral / totalSaldoGeneral)}</td>
                <td > {new Intl.NumberFormat("en-US", {style: "percent", maximumFractionDigits: 2 }).format(totalTreintaGeneral / totalSaldoGeneral)} </td>
                <td > {new Intl.NumberFormat("en-US", {style: "percent", maximumFractionDigits: 2 }).format(totalSesentaGeneral / totalSaldoGeneral)} </td>
                <td > {new Intl.NumberFormat("en-US", {style: "percent", maximumFractionDigits: 2 }).format(totalNoventaGeneral / totalSaldoGeneral)} </td>
                <td > {new Intl.NumberFormat("en-US", {style: "percent", maximumFractionDigits: 2 }).format(totalCientoVeinteGeneral / totalSaldoGeneral)} </td>
                <td > {new Intl.NumberFormat("en-US", {style: "percent", maximumFractionDigits: 2 }).format(totalCientoOchentaGeneral / totalSaldoGeneral)} </td>
                <td > {new Intl.NumberFormat("en-US", {style: "percent", maximumFractionDigits: 2 }).format(totalMasGeneral / totalSaldoGeneral)} </td>
              </tr>
                 </tbody>
                 
                 </Table>
          
        </div>
        </Row>
        <Row>
        <div className="card container col-sm-4">
        <Table>
                 <thead>
                  <tr>
                    <th>Cobranza por Vendedor</th>
                  </tr>
                  <tr>
                    <th>Vendedor</th>
                    <th>Importe</th>
                  </tr>
                 </thead>
                 <tbody>
                 {cobranzaVendedores.map((a) => (
                   <tr key={a.idVendedor}>
                     <td>{a.Vendedor}</td>
                     <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.Saldo)}</td>
                   </tr>
                 ))}
                 <tr className="negrita">
                  <td>Total</td>
                  <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(cobranzaVendedoresTotal)}</td>

                 </tr>
                 </tbody>
                 
                </Table>
          
        </div>
        </Row>
<hr />
        <h4>Resumen de Inventario</h4>
        <Row>
        <div className="card container col-sm-4">
        <Table>
                 <thead>
                  <tr>
                    <th>Inventario</th>
                  </tr>
                  <tr>
                    <th>Almacen</th>
                    <th>Piezas</th>
                    <th>Importe</th>
                  </tr>
                 </thead>
                 <tbody>
                 {inventario.map((a) => (
                   <tr key={a.idArea}>
                     <td>{a.Area}</td>
                     <td>{new Intl.NumberFormat("en-US").format(a.TotalPiezas)}</td>
                     <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.TotalDinero)}</td>
                   </tr>
                 ))}

                    <tr className="negrita">
                     <td>Total</td>
                     <td>{new Intl.NumberFormat("en-US").format(inventarioTotalPiezas)}</td>
                     <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(inventarioTotalDinero)}</td>
                   </tr>
                 </tbody>
                 
                </Table>
          
        </div>

        <div className="card container col-sm-4">
          <Table>
                 <thead>
                 <tr>
                  <th>Pendiente Embarcar</th>
                  <th>Pendiente Recibir</th>
                 </tr>
                  </thead>
                 <tbody>
                 <tr>
                   <td>{new Intl.NumberFormat("en-US").format(pendEmbarcarTotal)}</td>
                   <td>{new Intl.NumberFormat("en-US").format(pendRecibirTotal)}</td>
                 </tr>
                 </tbody>
          </Table>
          </div>

        {/* <div className="card container col-sm-4">
        <Table>
                 <thead>
                  <tr>
                    <th>Pendiente Embarcar</th>
                  </tr>
                  <tr>
                    <th>Articulo</th>
                    <th>Piezas</th>
                  </tr>
                 </thead>
                 <tbody>
                 {pendEmbarcar.map((a) => (
                   <tr >
                     <td>{a.codigo} {a.nombre}</td>
                     <td>{new Intl.NumberFormat("en-US").format(a.pendiente_embarcar)}</td>
                   </tr>
                 ))}
                 </tbody>
                 
                </Table>
          
        </div>

        <div className="card container col-sm-4">
        <Table>
                 <thead>
                  <tr>
                    <th>Pendiente Recibir</th>
                  </tr>
                  <tr>
                    <th>Articulo</th>
                    <th>Piezas</th>
                  </tr>
                 </thead>
                 <tbody>
                 {pendRecibir.map((a) => (
                   <tr >
                     <td>{a.codigo}</td>
                     <td>{new Intl.NumberFormat("en-US").format(a.pendiente_recibir)}</td>
                   </tr>
                 ))}
                 </tbody>
                 
                </Table>
          
        </div> */}
        </Row>
   </div>
        </>
      ) : undefined}
      
    </>
  );
}

export default Locker;
