import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Spinner,
  Form,
  FormGroup,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import moment from "moment";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import qrcode from "qrcode-js";
import { v4 as uuidv4 } from "uuid";

function DinerosTiendasCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_DINEROS_TIENDAS = process.env.REACT_APP_URL_DINEROS_TIENDAS;

  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  const [text, setText] = useState(false);
  const [fecha, setFecha] = useState(hoy);
  const [efectivo, setEfectivo] = useState(0);
  const [tdc, setTDC] = useState(0);
  const [transfer, setTransfer] = useState(0);
  const [fechaTransfer, setFechaTransfer] = useState("NA");
  const [destino, setDestino] = useState("NA");
  const [terminal, setTerminal] = useState("NA");
  const [validaBoton, setValidaBoton] = useState(true);
  const [total, setTotal] = useState(0);
  const [observaciones, setObservaciones] = useState("");

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  useMemo(()=>{
    let temp = parseFloat(efectivo) + parseFloat(tdc) + parseFloat(transfer)
    setTotal(temp)
  },[efectivo, tdc, transfer, total])

  function savePago() {
    setValidaBoton(false);
    Swal.fire({
      title: "Estas seguro?",
      text: "Se registrará la entrada",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Enviar!",
    }).then((result) => {
      if (result.isConfirmed) {
        toggleProgreso();
            axios
              .post(
                URL_DINEROS_TIENDAS,
                {
                  fecha,
                  total,
                  areas: user.areas,
                  efectivo,
                  tdc,
                  transfer,
                  fechaTransfer,
                  cortesia:0,
                  nomina:0,
                  destino,
                  terminal,
                  observaciones,
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              )
              .then((data) => {
                axios
                .post(
                  URL_LOGS,
                  {
                    tipo:'Crear Entrada Dinero Tienda',
                    detalle: `Fecha: ${fecha} / Total: ${total} `,
                    user: user.id
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                    },
                  }
                )
              
                  Swal.fire("Good job!", "Creado con exito", "success");
                  setTimeout(() => {
                    window.location.reload();
                  }, 2000);
                
              })
              .catch((error) => {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                  footer: `${error.response.data}`,
                });
                console.log(error);
              });
         
      }
    });
  }



  return (
    <>
      <Header />
      <br />
      <br />
      {user.abonosTiendas ? (
        
      <div className="container">
      <div className="card container col-sm-8">
          <h3 align="center">Captura una Entrada de Dinero</h3>
          <Form>
            <Row>
            <Col md={3}>
                    <Label>Fecha</Label>
                    <Input
                      type="date"
                      value={fecha}
                      required
                      onChange={(e) => {
                        setFecha(e.target.value);
                      }}
                    />
                  </Col>
            </Row>
           <Row>
           <Col md={3}>
                  <Label>Efectivo</Label>
                  <Input
                    type="number"
                    min="0"
                    step="any"
                    value={efectivo}
                    required
                    onChange={(e) => {
                      setEfectivo(e.target.value);
                    }}
                  />
                </Col>
           <Col md={3}>
                    <Label>TDC</Label>
                    <Input
                      type="number"
                      min="0"
                      step="any"
                      value={tdc}
                      required
                      onChange={(e) => {
                        setTDC(e.target.value);
                      }}
                    />
                  </Col>

                  {tdc > 0 ? (
                    <Col md={3}>
                      <Label>Terminal</Label>
                      <Input
                        type="select"
                        value={terminal}
                        required
                        onChange={(e) => {
                          setTerminal(e.target.value);
                        }}
                      >
                        <option value="NA">Selecciona</option>
                        <option value="Tarjeta BBVA Tretmex">
                          Tarjeta BBVA Tretmex
                        </option>
                        <option value="Tarjeta BBVA Isratex">
                          Tarjeta BBVA Isratex
                        </option>
                        <option value="Tarjeta BBVA Tripoli">
                          Tarjeta BBVA Tripoli
                        </option>
                        <option value="Tarjeta HSBC Isratex">
                          Tarjeta HSBC Isratex
                        </option>
                        <option value="Tarjeta MIFEL Tretmex">
                          Tarjeta MIFEL Tretmex
                        </option>
                        <option value="Tarjeta MIFEL Tripoli">
                          Tarjeta MIFEL Tripoli
                        </option>
                        <option value="Tarjeta MIFEL Isratex">
                          Tarjeta MIFEL Isratex
                        </option>
                        <option value="MARKETING AMERICA">MARKETING AMERICA</option>
                      </Input>
                    </Col>
                  ) : (
                    <Col md={3}>
                      <Label>Terminal</Label>
                      <Input type="text" value={terminal} disabled />
                    </Col>
                  )}

              </Row>
                <Row>
              <Col md={3}>
                    <Label>Transfer</Label>
                    <Input
                      type="number"
                      min="0"
                      step="any"
                      value={transfer}
                      required
                      onChange={(e) => {
                        setTransfer(e.target.value);
                      }}
                    />
                  </Col>
                  {transfer > 0 ? (
                    <>
                    <Col md={3}>
                      <Label>Destino</Label>
                      <Input
                        type="select"
                        value={destino}
                        required
                        onChange={(e) => {
                          setDestino(e.target.value);
                        }}
                      >
                        <option value="NA">Selecciona</option>
                              <option value="BBVA Tretmex">BBVA Tretmex</option>
                              <option value="BBVA Isratex">BBVA Isratex</option>
                              <option value="BBVA Tripoli">BBVA Tripoli</option>
                              <option value="HSBC Isratex">HSBC Isratex</option>
                              <option value="MIFEL Tretmex">MIFEL Tretmex</option>
                              <option value="MIFEL Tripoli">MIFEL Tripoli</option>
                              <option value="KUSPIT TELAS KARUN SA DE CV">KUSPIT TELAS KARUN SA DE CV</option>
                              <option value="BBVA BUSNOCONDO SA DE CV">BBVA BUSNOCONDO SA DE CV</option>
                              <option value="BBVA MULTIVARIADO SA DE CV">BBVA MULTIVARIADO SA DE CV</option>
                              <option value="BBVA EMPOLETTI SA DE CV">BBVA EMPOLETTI SA DE CV</option>
                      </Input>
                    </Col>
                    <Col md={3}>
                    <Label>Fecha Transfer</Label>
                    <Input
                      type="date"
                      value={fechaTransfer}
                      required
                      onChange={(e) => {
                        setFechaTransfer(e.target.value);
                      }}
                    />
                  </Col>
                    </>
                  ) : (
                    <>
                    <Col md={3}>
                      <Label>Destino</Label>
                      <Input type="text" value={destino} disabled />
                    </Col>
                    <Col md={3}>
                    <Label>Fecha Transfer</Label>
                    <Input
                      type="date"
                      value={fechaTransfer}
                      required
                      disabled
                    />
                  </Col>
                  </>
                  )}
           </Row>
           <Row>
           <Col md={6}>
                    <Label>Observaciones</Label>
                    <Input
                      type="text"
                      value={observaciones}
                      required
                      onChange={(e) => {
                        setObservaciones(e.target.value);
                      }}
                    />
                  </Col>
           <Col md={3}>
                  <Label>Total</Label>
                  <Input
                    type="number"
                    min="0"
                    step="any"
                    value={total}
                    required
                    disabled
                  />
                </Col>
           </Row>
            <br />
            <Row>
              <Button className="btn btn-success" onClick={savePago}>
                {" "}
                Registrar
              </Button>

              <Button
                href="/DinerosTiendas"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Row>
          </Form>
          <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
             <h4> Este proceso puede tardar varios segundos.
              <br />
              Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
              </div>
            </ModalBody>
          </Modal>

        </div>
        </div>
      ) : undefined}
      
    </>
  );
}

export default DinerosTiendasCreate;
